import React from "react";
import { Dropdown, Space, Button } from "antd";
import type { MenuProps } from "antd";
import { Link } from "react-router-dom";
import { useUserStore } from "../store/userStore";

const UserDropdownMenu = () => {
  const { logoutUser } = useUserStore();
  const { is_superuser } = useUserStore();

  const handleLogout = async () => {
    logoutUser();
  };
  const items = [
    {
      label: <Link to="/mypage">내 정보 관리</Link>,
      key: "1",
    },
    {
      label: (
        <button className="red" onClick={handleLogout}>
          로그아웃
        </button>
      ),
      key: "2",
    },
  ];

  const menuProps = {
    items,
  };

  return (
    <>
      {is_superuser ? (
        <Link to="/admin" className="utility">
          <img src="/images/icon/admin.png" alt="이모티콘" />
        </Link>
      ) : (
        <Dropdown
          menu={menuProps}
          className="utilityDropdown"
          overlayClassName="utilityOverlay"
        >
          <Button className="utility">
            <Space>
              <img src="/images/icon/user.png" alt="이모티콘" />
            </Space>
          </Button>
        </Dropdown>
      )}
    </>
  );
};

export default UserDropdownMenu;
