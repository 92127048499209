import { headers, headersNoAuth } from "../config/env";
import Axios from "../config/axios";
import { useCustomerStore } from "../store/customerStore";
import { useMetadataStore } from "../store/metadataStore";
import { MetadataIdStore } from "../utils/type";

export async function fetchMetadata() {
  const id = useCustomerStore.getState().id!;
  return await Axios.get(`/metadata/${id}`, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

export async function updateMetadata(data: MetadataIdStore) {
  const id = useMetadataStore.getState().id!;
  data.id = id;
  return await Axios.put("/metadata", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
