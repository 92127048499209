import React, { useState, useEffect } from "react";
import { Select, Pagination, Modal } from "antd";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import Channel from "../../components/metadata/Channel";
import Item from "../../components/metadata/Item";
import ContactDivision from "../../components/metadata/ContactDivision";
import { useMetadataStore, INITIAL_STATE } from "../../store/metadataStore";
import { usePageStore } from "../../store/pageStore";
import {
  Metadatas,
  MetaAddbject,
  ItemsListItem,
  ContactListItem,
} from "../../utils/type";
import showModal from "../../components/message/Modal";
import { updateMetadata, fetchMetadata } from "../../api/metadata";
import { AxiosError } from "axios";
import { useNavigate } from "react-router";
import { handleErrorResponse } from "../../utils/common";

export default function Metadata() {
  // data
  const navigate = useNavigate();
  const {
    marketing,
    category,
    product,
    language,
    brand,
    contact_method,
    delivery_progress,
    contact_account,
    creator_type,
    supporter_recommand,
    contact_type,
    notice_type,
    qna_type,
    team,
    country,
    snsID,
    dummy,
    getMetadata,
    setMetadata,
  } = useMetadataStore();
  const { isPageMoving, setPageStore } = usePageStore();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("marketing");
  const [title, setTitle] = useState("마케팅 채널");
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedItem, setSelectedItem] = useState<string[] | null>([]);
  const [itemSelectedItem, setItemSelectedItem] = useState<
    ItemsListItem[] | null
  >([]);
  const [contactSelectedItem, setContactSelectedItem] = useState<
    ContactListItem[] | null
  >([]);
  const [submitItem, setSubmitItem] = useState<
    ItemsListItem[] | ContactListItem[]
  >([]);
  const [metadata, setLocalMetadata] = useState<MetaAddbject>(INITIAL_STATE);
  const metadataOptions = [
    {
      value: "marketing",
      label: "마케팅 채널",
    },
    {
      value: "category",
      label: "카테고리",
    },
    {
      value: "product",
      label: "제품",
    },
    {
      value: "language",
      label: "언어",
    },
    {
      value: "brand",
      label: "브랜드",
    },
    {
      value: "contact_method",
      label: "컨택 방식",
    },
    {
      value: "delivery_progress",
      label: "배송 상태",
    },
    {
      value: "contact_account",
      label: "컨택계정명",
    },
    {
      value: "creator_type",
      label: "크리에이터 구분",
    },
    {
      value: "supporter_recommand",
      label: "서포터추천",
    },
    {
      value: "contact_type",
      label: "컨택 구분",
    },
    {
      value: "notice_type",
      label: "공지사항 구분",
    },
    {
      value: "qna_type",
      label: "Q&A 구분",
    },
    {
      value: "team",
      label: "팀",
    },
    {
      value: "country",
      label: "국가",
    },
  ];
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  // mounted
  useEffect(() => {
    setLocalMetadata(getMetadata());
    const data = metadata[selectedValue as keyof Metadatas];
    if (data && typeof data !== "number") {
      setSelectedItem(data);
    }
  }, [
    marketing,
    category,
    product,
    language,
    brand,
    contact_method,
    delivery_progress,
    contact_account,
    creator_type,
    supporter_recommand,
    contact_type,
    notice_type,
    qna_type,
    team,
    country,
    snsID,
  ]);
  useEffect(() => {
    setSelectedItem(marketing);
  }, []);
  // method
  const isItemsListItemArray = (
    data: ItemsListItem[] | ContactListItem[]
  ): data is ItemsListItem[] => {
    return (data as ItemsListItem[])[0]?.hasOwnProperty("id") ?? false;
  };
  const hasEmptyFieldInList = (
    datas: (ItemsListItem | ContactListItem)[]
  ): boolean => {
    return datas.some((data) =>
      Object.values(data).some((value) => value === "" || value === null)
    );
  };
  const changeTableData = (datas: ItemsListItem[] | ContactListItem[]) => {
    setSubmitItem(datas);

    if (isItemsListItemArray(datas)) {
    } else {
    }
  };
  const changeNormalData = (datas: string[]) => {
    const selectedKey = selectedValue as keyof Metadatas;
    if (Array.isArray(dummy)) {
      setLocalMetadata((prevData) => ({
        ...prevData,
        [selectedKey]: datas,
      }));
    }
  };
  const generateTableContent = (value: string) => {
    switch (value) {
      case "product":
        return (
          <Item
            title={title}
            propsData={itemSelectedItem}
            changeData={changeTableData}
            isUpdate={isUpdate}
            changeStatus={changeStatus}
          />
        );
      case "contact_type":
        return (
          <ContactDivision
            title={title}
            propsData={contactSelectedItem}
            changeData={changeTableData}
            isUpdate={isUpdate}
            changeStatus={changeStatus}
          />
        );
      default:
        return (
          <Channel
            title={title}
            propsData={selectedItem}
            isUpdate={isUpdate}
            changeStatus={changeStatus}
            changeData={changeNormalData}
          />
        );
    }
  };
  const changeStatus = (status: boolean) => {
    setIsUpdate(status);
    setPageStore({ isPageMoving: status });
  };
  const handleChange = (value: string) => {
    setTitle(getLabelByValue(value) ?? "");
    setIsUpdate(false);
    setPageStore({ isPageMoving: false });
    setSelectedValue(value);
    if (value === "product") {
      const data = metadata[value];
      setItemSelectedItem(data);
    } else if (value === "contact_type") {
      const data = metadata[value];
      setContactSelectedItem(data);
    } else {
      const data = metadata[value as keyof Metadatas];
      if (data && typeof data !== "number") {
        setSelectedItem(data);
      } else {
        setSelectedItem([]);
      }
    }
  };
  const handleItemModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const getLabelByValue = (value: string) => {
    const option = metadataOptions.find((option) => option.value === value);
    return option ? option.label : null;
  };
  const submit = async () => {
    if (isButtonDisabled) {
      return;
    }
    if (selectedValue === "product" || selectedValue === "contact_type") {
      if (submitItem.length < 1) {
        return;
      }
      if (hasEmptyFieldInList(submitItem)) {
        return showModal({
          type: "error",
          messages: [
            "모든 입력칸이 입력되어 있지 않습니다.",
            "모두 입력하거나, 비어있는 칸은 삭제해주세요.",
          ],
        });
      }
    } else {
      if (dummy && dummy.length < 1) {
        return;
      }
      if (dummy && dummy.length < 1 && !Array.isArray(dummy)) {
        return;
      }
      if (dummy && dummy.some((item) => item === "")) {
        return showModal({
          type: "error",
          messages: [
            "모든 입력칸이 입력되어 있지 않습니다.",
            "모두 입력하거나, 비어있는 칸은 삭제해주세요.",
          ],
        });
      }
    }
    try {
      setIsButtonDisabled(true);
      let isOk = false;
      await new Promise<void>((resolve) => {
        showModal({
          type: "confirm",
          messages: ["변경된 메타데이터를 저장하시겠습니까?"],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        setIsButtonDisabled(false);
        return;
      }
      let data: MetaAddbject = metadata;
      if (selectedValue === "product" || selectedValue === "contact_type") {
        if (isItemsListItemArray(submitItem)) {
          data["product"] = submitItem;
        } else {
          data["contact_type"] = submitItem;
        }
      } else {
        const selectedKey = selectedValue as keyof Metadatas;
        if (Array.isArray(dummy)) {
          data[selectedKey] = dummy;
        }
      }
      let res = await updateMetadata(data);
      let res2 = await fetchMetadata();
      setMetadata(res2.data.data);
      setIsUpdate(false);
      setPageStore({ isPageMoving: false });
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    } finally {
      setIsButtonDisabled(false);
    }
  };
  const handleBackButton = async () => {
    if (isUpdate === true) {
      let isOk = false;
      await new Promise<void>((resolve) => {
        showModal({
          type: "alert",
          messages: ["입력을 중단하고 입력전 상태로 원상복구 하겠습니까?"],
          confirmOptions: {
            okText: "복구",
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        return;
      } else {
        if (selectedValue === "product") {
          setItemSelectedItem([...product]);
        } else if (selectedValue === "contact_type") {
          setContactSelectedItem([...contact_type]);
        } else {
          const data = getMetadata()[selectedValue as keyof Metadatas];
          if (data && typeof data !== "number") {
            setSelectedItem([...data]);
          } else {
            setSelectedItem([]);
          }
        }
        setIsUpdate(false);
        setPageStore({ isPageMoving: false });
      }
    } else {
      return navigate(-1);
    }
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>
            메타데이터 관리 &gt; {title}{" "}
            {title !== "크리에이터 구분" &&
              title !== "서포터추천" &&
              title !== "컨택 구분" &&
              title !== "공지사항 구분" &&
              title !== "Q&A 구분" &&
              title !== "팀" &&
              title !== "국가" &&
              "관리"}
          </h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>

        <article className="register">
          <div className="sorting">
            <div className="column">
              <p>메타데이터 종류를 선택해주세요:</p>
              <Select
                defaultValue="마케팅 채널"
                style={{
                  width: 196,
                }}
                onChange={handleChange}
                options={metadataOptions}
              />
            </div>
          </div>
          {generateTableContent(selectedValue)}
          <div className="buttonWrap">
            {isUpdate && (
              <>
                <button
                  className="point"
                  onClick={submit}
                  disabled={isButtonDisabled}
                >
                  저장
                </button>
                <button className="back" onClick={handleBackButton}>
                  취소
                </button>
              </>
            )}
          </div>
        </article>
      </section>
    </>
  );
}
