import React, { useState, useEffect } from "react";
import { Checkbox, Dropdown, Space, Button, Menu } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
interface Props {
  handleApply: (list: string[]) => void;
  list: string[];
  changeFirst?: number;
}

export default function FilterModal({ handleApply, list, changeFirst }: Props) {
  // data
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [menuList, setMenuList] = useState<string[]>([]);
  const [isFirst, setIsFirst] = useState<boolean>(false);
  // mounted
  useEffect(() => {
    setMenuList(list);
    if (isFirst !== true) {
      setSelectedItems(list);
    }
    if (list.length > 1) {
      setIsFirst(true);
    }
  }, [list]);
  useEffect(() => {
    setIsFirst(false);
  }, [changeFirst]);
  // method
  const handleMenuClick: MenuClickEventHandler = (e) => {
    // 아이템이 클릭되었을 때 드롭다운이 닫히지 않도록 함
    e.domEvent.stopPropagation();
  };
  const handleSelectAll = () => {
    setSelectedItems(selectedItems.length === menuList.length ? [] : menuList);
  };
  const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedItems(checkedValues as string[]);
  };
  const handleApplyClick = () => {
    setVisible(false);
    handleApply(selectedItems);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="selectAll">
        <Checkbox
          onChange={handleSelectAll}
          indeterminate={false}
          checked={selectedItems.length === menuList.length}
        >
          전체 선택
        </Checkbox>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="selectItems" className="dropdown">
        <Checkbox.Group
          onChange={handleCheckboxChange}
          value={selectedItems}
          className="multiChk"
        >
          {menuList.map((data, index) => (
            <Checkbox key={index} value={data}>
              {data}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Menu.Item>
      <Menu.Item>
        <Button type="primary" onClick={handleApplyClick}>
          적용하기
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      onVisibleChange={(flag) => setVisible(flag)}
      visible={visible}
      overlayClassName="filterOverlay"
    >
      <Button className="utility">
        <Space>
          <img src="/images/icon/filter.png" alt="이모티콘" />
        </Space>
      </Button>
    </Dropdown>
  );
}
