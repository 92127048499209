/* eslint-disable */
import React from "react";
import "./App.css";
import router from "./routes";
import { RouterProvider } from "react-router-dom";
import "./styles/common.scss";

function App() {
  return <RouterProvider router={router} />;
}

export default App;
