import { Link } from "react-router-dom";
export default function ChnagePasswordDone() {
  return (
    <div className="loginBox done">
      <section>
        <div className="logo">
          <img src="/images/logo.png" alt="logo" />
        </div>
        <h2>비밀번호 변경이 완료되었습니다.</h2>
        <article className="form">
          <Link to="/" className="point large">
            로그인 페이지로 이동
          </Link>
        </article>
      </section>
    </div>
  );
}
