import React, { useState, useEffect } from "react";
import { fetchSearchUser } from "../../api/index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Pagination, Tabs, Select } from "antd";
import type { PaginationProps } from "antd";
import {
  FilterCheck,
  Filter_group,
  ParamsProps,
  SearchHitory,
  SortInfo,
} from "../../utils/type";
import dayjs from "dayjs";
import { comma, filterNonEmptyValues, processParams } from "../../utils/common";
import FilterModal from "../pop/FilterModal";

interface Props {
  id: string | null;
  forceUpdate: number;
}

export default function SearchHistory({ id, forceUpdate }: Props) {
  // data
  const resetData = {
    channel: [],
    category: [],
    language: [],
    type: [],
    country: [],
  };
  const navigate = useNavigate();
  const [list, setList] = useState<SearchHitory[]>([]);
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchWord, setSearchWord] = useState("");
  const [sortInfo, setSortInfo] = useState<SortInfo[]>([]);
  const [filterList, setFilterList] = useState<Filter_group>(resetData);
  const [filterCheckList, setFilterCheckList] =
    useState<FilterCheck>(resetData);
  const [searchforceUpdate, setForceUpdate] = useState(0);
  // mounted
  useEffect(() => {
    setForceUpdate((prevNumber) => prevNumber + 1);
    let params = {};
    getList(params);
  }, [forceUpdate]);
  useEffect(() => {
    setSortInfo([]);
    setFilterList(resetData);
    setFilterCheckList(resetData);
  }, [searchforceUpdate]);
  useEffect(() => {
    getFilter();
  }, [filterCheckList]);
  // method
  const getIconForCustomerName = (orderType: string) => {
    const customerNameSortInfo = sortInfo.find(
      (item) => item.orderType === orderType
    );

    if (customerNameSortInfo) {
      return customerNameSortInfo.sortOrder;
    }
    return "desc";
  };
  const generateOrderString = (orderList: SortInfo[]) => {
    const result = orderList
      .map((order) => {
        const sortOrderSymbol = order.sortOrder === "desc" ? "-" : "";
        return sortOrderSymbol + order.orderType;
      })
      .join(",");

    return result;
  };
  const toggleSortOrder = (currentSortOrder: "asc" | "desc"): "asc" | "desc" =>
    currentSortOrder === "asc" ? "desc" : "asc";
  const handleOrderType = async (orderType: string) => {
    const targetSortInfo = sortInfo.find(
      (info) => info.orderType === orderType
    );

    const newSortInfo = targetSortInfo
      ? sortInfo.map((info) =>
          info.orderType === orderType
            ? { orderType, sortOrder: toggleSortOrder(info.sortOrder) }
            : info
        )
      : [{ orderType, sortOrder: "asc" as "asc" | "desc" }];

    setSortInfo(newSortInfo);
    let params = {
      page,
      searchWord,
      orderType: generateOrderString(newSortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getList(params);
  };
  const getList = async (paramsData: ParamsProps) => {
    const params = processParams(paramsData);
    if (id !== null) {
      let res = await fetchSearchUser(id, params);
      if (res.data.status === 200) {
        setList(res.data.data);
        setTotal(res.data.total);
        setPage(res.data.page);
        setFilterList(res.data.filter_group);
      }
    }
  };
  const handlePagination: PaginationProps["onChange"] = async (
    pageNum,
    pageSize
  ) => {
    const params = {
      page: pageNum,
      searchWord,
      orderType: generateOrderString(sortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getList(params);
  };
  const handleSearch = async () => {
    setForceUpdate((prevNumber) => prevNumber + 1);
    let params = {
      page: 1,
      searchWord,
    };
    await getList(params);
  };
  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setForceUpdate((prevNumber) => prevNumber + 1);
      let params = {
        page: 1,
        searchWord,
      };
      await getList(params);
    }
  };
  const handleBackButton = () => {
    navigate(-1);
  };
  const handleApply = (list: string[], type: string) => {
    setFilterCheckList((prevUserData) => ({
      ...prevUserData,
      [type]: list,
    }));
  };
  const getFilter = async () => {
    let params = {
      page: 1,
      searchWord: searchWord,
      orderType: generateOrderString(sortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getList(params);
  };
  return (
    <>
      <div className="searchBox">
        <div>
          <button onClick={handleSearch}>
            <img src="/images/icon/search.png" alt="검색 이모티콘" />
          </button>
          <input
            type="text"
            placeholder="검색어를 입력해주세요."
            name="searchWord"
            onChange={(e) => setSearchWord(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      {list && list.length > 0 ? (
        <div className="tableBox">
          <table>
            <colgroup>
              <col style={{ width: "7%" }} />
              <col style={{ width: "14%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "11%" }} />
              <col style={{ width: "13%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>번호</th>
                <th>
                  <div className="flexB filtering">크리에이터ID</div>
                </th>
                <th>
                  <div className="flexB filtering">
                    채널
                    <div className="flex">
                      <button onClick={(e) => handleOrderType("channel")}>
                        {getIconForCustomerName("channel") === "asc" ? (
                          <img src="/images/icon/up.png" alt="이모티콘" />
                        ) : (
                          <img src="/images/icon/down.png" alt="이모티콘" />
                        )}
                      </button>
                      <FilterModal
                        handleApply={(data) => handleApply(data, "channel")}
                        list={filterList.channel ?? []}
                        changeFirst={searchforceUpdate}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flexB filtering">
                    카테고리
                    <div className="flex">
                      <button onClick={(e) => handleOrderType("category")}>
                        {getIconForCustomerName("category") === "asc" ? (
                          <img src="/images/icon/up.png" alt="이모티콘" />
                        ) : (
                          <img src="/images/icon/down.png" alt="이모티콘" />
                        )}
                      </button>
                      <FilterModal
                        handleApply={(data) => handleApply(data, "category")}
                        list={filterList.category ?? []}
                        changeFirst={searchforceUpdate}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flexB filtering">
                    언어
                    <div className="flex">
                      <button onClick={(e) => handleOrderType("language")}>
                        {getIconForCustomerName("language") === "asc" ? (
                          <img src="/images/icon/up.png" alt="이모티콘" />
                        ) : (
                          <img src="/images/icon/down.png" alt="이모티콘" />
                        )}
                      </button>
                      <FilterModal
                        handleApply={(data) => handleApply(data, "language")}
                        list={filterList.language ?? []}
                        changeFirst={searchforceUpdate}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flexB filtering">
                    국가
                    <div className="flex">
                      <button onClick={(e) => handleOrderType("country")}>
                        {getIconForCustomerName("country") === "asc" ? (
                          <img src="/images/icon/up.png" alt="이모티콘" />
                        ) : (
                          <img src="/images/icon/down.png" alt="이모티콘" />
                        )}
                      </button>
                      <FilterModal
                        handleApply={(data) => handleApply(data, "country")}
                        list={filterList.country ?? []}
                        changeFirst={searchforceUpdate}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flexB filtering">
                    구분
                    <div className="flex">
                      <button onClick={(e) => handleOrderType("type")}>
                        {getIconForCustomerName("type") === "asc" ? (
                          <img src="/images/icon/up.png" alt="이모티콘" />
                        ) : (
                          <img src="/images/icon/down.png" alt="이모티콘" />
                        )}
                      </button>
                      <FilterModal
                        handleApply={(data) => handleApply(data, "type")}
                        list={filterList.type ?? []}
                        changeFirst={searchforceUpdate}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flexB filtering">
                    팔로워 수
                    <div className="flex">
                      <button onClick={(e) => handleOrderType("followerNo")}>
                        {getIconForCustomerName("followerNo") === "asc" ? (
                          <img src="/images/icon/up.png" alt="이모티콘" />
                        ) : (
                          <img src="/images/icon/down.png" alt="이모티콘" />
                        )}
                      </button>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flexB filtering">
                    등록 날짜
                    <div className="flex">
                      <button onClick={(e) => handleOrderType("createdAt")}>
                        {getIconForCustomerName("createdAt") === "asc" ? (
                          <img src="/images/icon/up.png" alt="이모티콘" />
                        ) : (
                          <img src="/images/icon/down.png" alt="이모티콘" />
                        )}
                      </button>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {list.map((data, index) => (
                <tr key={index}>
                  <td>{total - ((page - 1) * pageSize + index)}</td>
                  <td>
                    <Link to={`/creator/list/member/info?id=${data.uuid}`}>
                      {data.creatorID}
                    </Link>
                  </td>
                  <td>{data.channel}</td>
                  <td>{data.category}</td>
                  <td>{data.language}</td>
                  <td>{data.country}</td>
                  <td>{data.type}</td>
                  <td>{comma(data.followerNo)}</td>
                  <td>
                    {data.createdAt
                      ? dayjs(data.createdAt).format("YYYY-MM-DD")
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div></div>
      )}
      <div className="pagination">
        <Pagination
          current={page}
          total={total}
          pageSize={pageSize}
          onChange={handlePagination}
        />
      </div>
      <div className="buttonWrap">
        <button className="back" onClick={handleBackButton}>
          뒤로
        </button>
      </div>
    </>
  );
}
