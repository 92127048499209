import { headers, headersNoAuth } from "../config/env";
import Axios from "../config/axios";
import {
  BulkDeleteProps,
  LoginUser,
  ParamsProps,
  UserForAdmin,
  verifyData,
} from "../utils/type";
import { useCustomerStore } from "../store/customerStore";
import { createSearchParams } from "../utils/requestUtils";

export function login(data: LoginUser) {
  return Axios.post("/login", data, {
    headers: headersNoAuth,
  });
}

export async function fetchProfile() {
  return await Axios.get(`/account/profile`, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

export async function createUser(data: UserForAdmin) {
  data.customer = useCustomerStore.getState().id!;
  return await Axios.post("/user/register", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
// // // 회원 정보 수정
export async function updateUser(data: UserForAdmin) {
  return await Axios.put("/user/update", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

// // // 회원 정보 삭제
export async function deleteUsers({
  ids,
  select_all,
  filters,
}: BulkDeleteProps) {
  return await Axios.put(
    `/user/delete`,
    { customer_id: useCustomerStore.getState().id!, ids, select_all, filters },
    {
      headers: headers(localStorage.getItem("accesstoken")!),
    }
  );
}

// // // 회원리스트
export async function fetchUserListWithcustomer(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/user/list`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchUserListExcel(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  const searchParams = createSearchParams(params);
  return await Axios.get(`/user/list/excel`, {
    params: searchParams,
    headers: headers(localStorage.getItem("accesstoken")!),
    responseType: "blob",
  });
}
export async function fetchUserListAll(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/user/all`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchUserDetail(id: string) {
  return await Axios.get(`/user/${id}`, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
// // // 서치 이력
export async function fetchSearchUser(id: string, params: ParamsProps) {
  return await Axios.get(`/creator/user/${id}`, {
    params: params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
// // // 컨택 이력
export async function fetchContactUser(id: string, params: ParamsProps) {
  return await Axios.get(`/contact/user/${id}`, {
    params: params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
// // // 제품배송 이력
export async function fetchDeliveryUser(id: string, params: ParamsProps) {
  return await Axios.get(`/delivery/user/${id}`, {
    params: params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
// // // 콘텐츠 이력
export async function fetchContetsUser(id: string, params: ParamsProps) {
  return await Axios.get(`/contents/user/${id}`, {
    params: params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
// // // 활동 로그
export async function fetchUserLog(id: string, params: ParamsProps) {
  return await Axios.get(`/user/log/${id}`, {
    params: params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

// // 이메일 인증번호 발송
export async function createAuthByEmail(data: verifyData) {
  return await Axios.post("/email", data, {
    headers: headersNoAuth,
  });
}

// // 이메일 인증번호 인증
export async function verifyAuth(data: verifyData) {
  return await Axios.post("/email/verify", data, {
    headers: headersNoAuth,
  });
}
// 비밀번호 변경(미로그인)
export async function changePassword(data: verifyData) {
  return await Axios.put("/reset/pwd", data, {
    headers: headersNoAuth,
  });
}
