import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { UserForAdmin, UserState, UserStore } from "../utils/type";

const INITIAL_STATE: UserState = {
  is_login: false,
  accountId: "",
  address: "",
  auth: "",
  birth: "",
  brand: "",
  country: "",
  createdAt: null,
  customer: null,
  email: "",
  id: null,
  is_superuser: false,
  language: "",
  team: "",
  last_login: null,
  updatedAt: null,
  username: "",
  zipcode: "",
};

export const useUserStore = create<UserStore>()(
  devtools(
    persist(
      (set, get) => ({
        // data
        is_login: INITIAL_STATE.is_login,
        accountId: INITIAL_STATE.accountId,
        address: INITIAL_STATE.address,
        auth: INITIAL_STATE.auth,
        birth: INITIAL_STATE.birth,
        brand: INITIAL_STATE.brand,
        country: INITIAL_STATE.country,
        createdAt: INITIAL_STATE.createdAt,
        customer: INITIAL_STATE.customer,
        email: INITIAL_STATE.email,
        id: INITIAL_STATE.id,
        is_superuser: INITIAL_STATE.is_superuser,
        language: INITIAL_STATE.language,
        team: INITIAL_STATE.team,
        last_login: INITIAL_STATE.last_login,
        updatedAt: INITIAL_STATE.updatedAt,
        username: INITIAL_STATE.username,
        zipcode: INITIAL_STATE.zipcode,
        uuid: INITIAL_STATE.uuid,
        url: INITIAL_STATE.url,
        password: INITIAL_STATE.password,
        confirmPassword: INITIAL_STATE.confirmPassword,
        phone: INITIAL_STATE.phone,
        note: INITIAL_STATE.note,
        // method
        setUser(item: UserState) {
          set(() => item);
        },
        getUser(): UserState {
          const state = get();
          return {
            is_login: state.is_login,
            accountId: state.accountId,
            address: state.address,
            auth: state.auth,
            birth: state.birth,
            brand: state.brand,
            country: state.country,
            createdAt: state.createdAt,
            customer: state.customer,
            email: state.email,
            id: state.id,
            is_superuser: state.is_superuser,
            language: state.language,
            team: state.team,
            last_login: state.last_login,
            updatedAt: state.updatedAt,
            username: state.username,
            zipcode: state.zipcode,
            uuid: state.uuid,
            url: state.url,
            password: state.password,
            confirmPassword: state.confirmPassword,
            phone: state.phone,
            note: state.note,
          };
        },
        logoutUser() {
          set(() => INITIAL_STATE);
          localStorage.removeItem("user");
          localStorage.removeItem("accesstoken");
        },
      }),
      { name: "user", skipHydration: false }
    )
  )
);
