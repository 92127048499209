import { AxiosResponse } from "axios";

export type ApiCallType = (...args: any[]) => Promise<AxiosResponse<Blob>>;

const handleDownload = async (apiCall: ApiCallType, ...args: any[]) => {
  try {
    const res = await apiCall(...args);
    // Blob 데이터를 기반으로 파일 다운로드
    const url = window.URL.createObjectURL(
      new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    console.log(res);
    const contentDisposition = res.headers["content-disposition"];
    console.log(contentDisposition);
    let fileName = "CRYSBE_FILE"; // 기본 파일 이름
    if (contentDisposition) {
      const matches = contentDisposition.match(
        /filename\*?=(?:UTF-8''|")?([^"]+)/
      );
      if (matches && matches[1]) {
        fileName = decodeURIComponent(matches[1]);
      }
    }
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // 파일명 설정
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("File download error:", error);
  }
};

export default handleDownload;
