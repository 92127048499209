import { headers, headersNoAuth } from "../config/env";
import Axios from "../config/axios";
import { ParamsProps, ReqBoardInfo, ReqReplyInfo } from "../utils/type";
import { useCustomerStore } from "../store/customerStore";

export async function createBoard(data: ReqBoardInfo) {
  data.customer = useCustomerStore.getState().id!;
  return await Axios.post("/board", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchBoardList(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/board/list`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function deleteBoards(id: string) {
  return await Axios.delete(`/board/${id}`, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchBoardDetail(id: string) {
  return await Axios.get(`/board/${id}`, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function updateBoard(data: ReqBoardInfo) {
  data.customer = useCustomerStore.getState().id!;
  return await Axios.put("/board", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function createBoardReply(data: ReqReplyInfo) {
  return await Axios.post("/board/reply", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchBoardReplyList(id: string) {
  return await Axios.get(`/board/${id}/reply/list`, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function deleteBoardReplys(id: string) {
  return await Axios.delete(`/board/reply/${id}`, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function updateBoardReplys(data: ReqReplyInfo) {
  return await Axios.put("/board/reply", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
