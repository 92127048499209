import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import showModal from "../message/Modal";
import { useMetadataStore } from "../../store/metadataStore";
import { ExcelItemProps, ItemsListItem } from "../../utils/type";
import ItemModal from "../pop/ItemModal";

interface Props {
  title: string | null;
  propsData: ItemsListItem[] | null;
  changeData: (datas: ItemsListItem[]) => void;
  isUpdate?: boolean;
  changeStatus: (status: boolean) => void;
}

export default function Item({
  title,
  propsData,
  changeData,
  isUpdate,
  changeStatus,
}: Props) {
  // data
  const { setDummyDatas } = useMetadataStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedList, setCheckedList] = useState<boolean[]>([false, false]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [checkAll, setCheckAll] = useState(false);
  const [list, setList] = useState([
    {
      id: "",
      name: "",
      brand: "",
    },
  ]);
  // mounted
  useEffect(() => {
    if (propsData) {
      setList(propsData);
      setCheckedList(Array(propsData.length).fill(false));
      setDummyDatas([]);
    }
  }, [propsData]);
  useEffect(() => {
    changeData(list);
  }, [list]);

  // method
  const onCheckBoxChange = (e: CheckboxChangeEvent, idx: number) => {
    const updatedCheckedList = [...checkedList];
    updatedCheckedList[idx] = e.target.checked;
    setCheckedList(updatedCheckedList);
    const allChecked = updatedCheckedList.every((item) => item);
    setCheckAll(allChecked);
  };
  const onCheckBoxAllChange = (e: CheckboxChangeEvent) => {
    const updatedCheckedList = checkedList.map(() => e.target.checked);
    setCheckAll(e.target.checked);
    setCheckedList(updatedCheckedList);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    setList((prevUserData) => {
      const newList: ItemsListItem[] = [...prevUserData];
      newList[index][name as keyof ItemsListItem] = value;
      return newList;
    });
  };
  const handleDelete = () => {
    if (!checkedList.some((item) => item)) {
      return showModal({
        type: "error",
        messages: ["하나 이상 선택해주세요."],
      });
    }
    let indexs = getTrueIndices(checkedList);
    setList((prevUserData) => {
      const newArray = [...prevUserData].filter(
        (item, index) => !indexs.includes(index)
      );
      return newArray;
    });
    setCheckedList((prevUserData) => {
      const newArray = [...prevUserData].filter(
        (item, index) => !indexs.includes(index)
      );
      return newArray;
    });
  };
  const getTrueIndices = (list: boolean[]) => {
    const indices: number[] = [];
    list.forEach((value, index) => {
      if (value) {
        indices.push(index);
      }
    });
    return indices;
  };
  const addRow = () => {
    setList((prevUserData) => [
      ...prevUserData,
      {
        id: "",
        name: "",
        brand: "",
      },
    ]);
    setCheckedList((prevCheckData) => [...prevCheckData, false]);
  };
  const handleItemModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handlExcelUpload = (data: ExcelItemProps[]) => {
    setIsModalOpen(false);
    setList((prevUserData) => [...prevUserData, ...(data as ItemsListItem[])]);
  };
  const handleUpdate = () => {
    changeStatus(!isUpdate);
  };
  return (
    <>
      <div className="flexB edit">
        {isUpdate ? (
          <>
            <div className="flex">
              <button className="pointOut" onClick={handleDelete}>
                선택 삭제
              </button>
              <button className="add" onClick={addRow}>
                <img src="/images/icon/add.png" alt="이모티콘" />
                <p>행 추가</p>
              </button>
            </div>
            <button className="pointOut item" onClick={handleItemModal}>
              다수 등록
            </button>
          </>
        ) : (
          <div className="flex">
            <button className="point" onClick={handleUpdate}>
              수 정
            </button>
          </div>
        )}
      </div>
      <div className={isUpdate ? "tableBox small update" : "tableBox small"}>
        <table>
          <thead>
            <tr>
              {isUpdate && (
                <th>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckBoxAllChange}
                    checked={checkAll}
                  ></Checkbox>
                </th>
              )}
              <th>제품 번호</th>
              <th style={{ width: "100vw" }}>제품명</th>
              <th>제품 브랜드</th>
            </tr>
          </thead>
          <tbody>
            {list.map((data, index) => (
              <tr key={index}>
                {isUpdate && (
                  <td className="check">
                    <Checkbox
                      checked={checkedList[index]}
                      onChange={(e) => onCheckBoxChange(e, index)}
                    />
                  </td>
                )}
                <td>
                  <input
                    type="text"
                    name="id"
                    className={data.id === "" ? "error" : ""}
                    value={data.id}
                    onChange={(e) => onChange(e, index)}
                    disabled={!isUpdate}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="name"
                    className={data.name === "" ? "error" : ""}
                    value={data.name}
                    onChange={(e) => onChange(e, index)}
                    disabled={!isUpdate}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="brand"
                    className={data.brand === "" ? "error" : ""}
                    value={data.brand}
                    onChange={(e) => onChange(e, index)}
                    disabled={!isUpdate}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ItemModal
        isOpen={isModalOpen}
        closeModal={handleItemModal}
        updateData={handlExcelUpload}
      />
    </>
  );
}
