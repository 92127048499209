import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination, Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import type { PaginationProps } from "antd";
import {
  FilterCheck,
  Filter_group,
  ParamsProps,
  SortInfo,
  UserForCreator,
} from "../../utils/type";
import { fetchAllCreatorListForAdmin } from "../../api/creator";
import FilterModal from "../../components/pop/FilterModal";
import {
  filterNonEmptyValues,
  handleErrorResponse,
  processParams,
  roles,
} from "../../utils/common";
import { AxiosError } from "axios";
import showModal from "../../components/message/Modal";

export default function AllCreators() {
  // data
  const navigate = useNavigate();
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<boolean[]>([]);
  const [creatorList, setCreatorList] = useState<UserForCreator[]>([]);
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortInfo, setSortInfo] = useState<SortInfo[]>([]);
  const [searchWord, setSearchWord] = useState("");
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const resetData = {
    // category: [],
    // type: [],
    // country: [],
    channel: [],
    accountId: [],
    username: [],
    team: [],
    language: [],
    brand: [],
    auth: [],
  };
  const [filterList, setFilterList] = useState<Filter_group>(resetData);
  const [filterCheckList, setFilterCheckList] =
    useState<FilterCheck>(resetData);
  const [searchforceUpdate, setForceUpdate] = useState(0);
  const getIconForCustomerName = (orderType: string) => {
    const customerNameSortInfo = sortInfo.find(
      (item) => item.orderType === orderType
    );

    if (customerNameSortInfo) {
      return customerNameSortInfo.sortOrder;
    }
    return "desc";
  };
  const generateOrderString = (orderList: SortInfo[]) => {
    const result = orderList
      .map((order) => {
        const sortOrderSymbol = order.sortOrder === "desc" ? "-" : "";
        return sortOrderSymbol + order.orderType;
      })
      .join(",");

    return result;
  };
  const toggleSortOrder = (currentSortOrder: "asc" | "desc"): "asc" | "desc" =>
    currentSortOrder === "asc" ? "desc" : "asc";
  const handleOrderType = async (orderType: string) => {
    const targetSortInfo = sortInfo.find(
      (info) => info.orderType === orderType
    );

    const newSortInfo = targetSortInfo
      ? sortInfo.map((info) =>
          info.orderType === orderType
            ? { orderType, sortOrder: toggleSortOrder(info.sortOrder) }
            : info
        )
      : [{ orderType, sortOrder: "asc" as "asc" | "desc" }];

    setSortInfo(newSortInfo);
    let params = {
      page,
      searchWord,
      orderType: generateOrderString(newSortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getAllCreatorList(params);
  };
  // mounted
  useEffect(() => {
    setForceUpdate((prevNumber) => prevNumber + 1);
    let parmas = {};
    getAllCreatorList(parmas);
  }, []);
  useEffect(() => {
    setSortInfo([]);
    setFilterList(resetData);
    setFilterCheckList(resetData);
  }, [searchforceUpdate]);
  useEffect(() => {
    getFilter();
  }, [filterCheckList]);
  // method
  const getAllCreatorList = async (paramsData: ParamsProps) => {
    const params = processParams(paramsData);
    try {
      let res = await fetchAllCreatorListForAdmin(params);
      if (res.data.status === 200) {
        setCreatorList(res.data.data);
        setPage(res.data.page);
        setTotal(res.data.total);
        setFilterList(res.data.filter_group);
        setCheckedList(Array(res.data.data.length).fill(false));
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };
  const handlePagination: PaginationProps["onChange"] = async (
    pageNum,
    pageSize
  ) => {
    const params = {
      page: pageNum,
      searchWord,
      orderType: generateOrderString(sortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getAllCreatorList(params);
  };
  const handleSearch = async () => {
    setForceUpdate((prevNumber) => prevNumber + 1);
    let params = {
      page: 1,
      searchWord,
    };
    await getAllCreatorList(params);
  };
  const updateCreator = async () => {
    let indexs = getTrueIndices(checkedList);
    const ids: number[] = indexs.map(
      (index) => creatorList[index]?.id as number
    );
    const filters = filterNonEmptyValues(filterCheckList);
    let updateParams: {
      ids?: number[];
      filters?: FilterCheck;
      select_all?: boolean;
    } = {};
    if (isAllSelected) {
      updateParams.select_all = isAllSelected;
      if (Object.keys(filters).length > 0) {
        updateParams.filters = filters;
      }
    } else {
      updateParams.ids = ids;
    }
    if (ids.length > 0) {
      navigate("/admin/creator/update", {
        state: updateParams,
      });
    } else {
      return showModal({
        type: "error",
        messages: ["하나 이상 선택해주세요."],
      });
    }
  };
  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setForceUpdate((prevNumber) => prevNumber + 1);
      let params = {
        page: 1,
        searchWord,
      };
      await getAllCreatorList(params);
    }
  };
  const handleApply = (list: string[], type: string) => {
    setFilterCheckList((prevUserData) => ({
      ...prevUserData,
      [type]: list,
    }));
  };
  const getFilter = async () => {
    let params = {
      page: 1,
      searchWord: searchWord,
      orderType: generateOrderString(sortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getAllCreatorList(params);
  };
  const onCheckBoxChange = (e: CheckboxChangeEvent, idx: number) => {
    const updatedCheckedList = [...checkedList];
    updatedCheckedList[idx] = e.target.checked;
    setCheckedList(updatedCheckedList);
    const allChecked = updatedCheckedList.every((item) => item);
    setCheckAll(allChecked);
  };
  const onCheckBoxAllChange = (e: CheckboxChangeEvent) => {
    const updatedCheckedList = checkedList.map(() => e.target.checked);
    setCheckAll(e.target.checked);
    setCheckedList(updatedCheckedList);
  };
  const getTrueIndices = (list: boolean[]) => {
    const indices: number[] = [];
    list.forEach((value, index) => {
      if (value) {
        indices.push(index);
      }
    });
    return indices;
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>크리에이터 담당자 변경</h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <article className="manage">
          <div className="searchBox">
            <div>
              <button>
                <img
                  src="/images/icon/search.png"
                  alt="검색 이모티콘"
                  onClick={handleSearch}
                />
              </button>
              <input
                type="text"
                placeholder="검색어를 입력해주세요."
                name="searchWord"
                onChange={(e) => setSearchWord(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <button className="point" onClick={updateCreator}>
              담당자 변경
            </button>
          </div>
          {creatorList?.length > 0 && (
            <div className="totalCount">전체 행 개수: {total}개 </div>
          )}
          {checkAll && (
            <div className="allSelectedBox">
              {isAllSelected ? (
                <>
                  전체 항목 {total}개가 모두 선택되었습니다.
                  <button
                    className="primary"
                    onClick={() => setIsAllSelected(false)}
                  >
                    이 페이지에 있는 항목 {checkedList.length}개만 선택
                  </button>
                </>
              ) : (
                <>
                  이 페이지에 있는 항목 {checkedList.length}개가 모두
                  선택되었습니다.
                  <button
                    className="primary"
                    onClick={() => {
                      setIsAllSelected(true);
                    }}
                  >
                    전체 항목 {total}개 모두 선택
                  </button>
                </>
              )}
            </div>
          )}
          <div className="tableBox">
            <table>
              <colgroup>
                <col style={{ width: "3%" }} />
                <col style={{ width: "7%" }} />
                <col style={{ width: "14%" }} />
                <col style={{ width: "9%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "9%" }} />
                <col style={{ width: "9%" }} />
                <col style={{ width: "14%" }} />
                <col style={{ width: "11%" }} />
                <col style={{ width: "12%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th className="check">
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckBoxAllChange}
                      checked={checkAll}
                    ></Checkbox>
                  </th>
                  <th>번호</th>
                  <th>
                    <div className="flexB filtering">크리에이터ID</div>
                  </th>
                  <th>
                    <div className="flexB filtering">
                      채널
                      <div className="flex">
                        <button onClick={(e) => handleOrderType("channel")}>
                          {getIconForCustomerName("channel") === "asc" ? (
                            <img src="/images/icon/up.png" alt="이모티콘" />
                          ) : (
                            <img src="/images/icon/down.png" alt="이모티콘" />
                          )}
                        </button>
                        <FilterModal
                          handleApply={(data) => handleApply(data, "channel")}
                          list={filterList.channel ?? []}
                          changeFirst={searchforceUpdate}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="flexB filtering">
                      유저ID
                      <div className="flex">
                        <button onClick={(e) => handleOrderType("accountId")}>
                          {getIconForCustomerName("accountId") === "asc" ? (
                            <img src="/images/icon/up.png" alt="이모티콘" />
                          ) : (
                            <img src="/images/icon/down.png" alt="이모티콘" />
                          )}
                        </button>
                        <FilterModal
                          handleApply={(data) => handleApply(data, "accountId")}
                          list={filterList.accountId ?? []}
                          changeFirst={searchforceUpdate}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="flexB filtering">
                      담당자
                      <div className="flex">
                        <button onClick={(e) => handleOrderType("username")}>
                          {getIconForCustomerName("username") === "asc" ? (
                            <img src="/images/icon/up.png" alt="이모티콘" />
                          ) : (
                            <img src="/images/icon/down.png" alt="이모티콘" />
                          )}
                        </button>
                        <FilterModal
                          handleApply={(data) => handleApply(data, "username")}
                          list={filterList.username ?? []}
                          changeFirst={searchforceUpdate}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="flexB filtering">
                      팀
                      <div className="flex">
                        <button onClick={(e) => handleOrderType("team")}>
                          {getIconForCustomerName("team") === "asc" ? (
                            <img src="/images/icon/up.png" alt="이모티콘" />
                          ) : (
                            <img src="/images/icon/down.png" alt="이모티콘" />
                          )}
                        </button>
                        <FilterModal
                          handleApply={(data) => handleApply(data, "team")}
                          list={filterList.team ?? []}
                          changeFirst={searchforceUpdate}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="flexB filtering">
                      언어
                      <div className="flex">
                        <button onClick={(e) => handleOrderType("language")}>
                          {getIconForCustomerName("language") === "asc" ? (
                            <img src="/images/icon/up.png" alt="이모티콘" />
                          ) : (
                            <img src="/images/icon/down.png" alt="이모티콘" />
                          )}
                        </button>
                        <FilterModal
                          handleApply={(data) => handleApply(data, "language")}
                          list={filterList.language ?? []}
                          changeFirst={searchforceUpdate}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="flexB filtering">
                      브랜드
                      <div className="flex">
                        <button onClick={(e) => handleOrderType("brand")}>
                          {getIconForCustomerName("brand") === "asc" ? (
                            <img src="/images/icon/up.png" alt="이모티콘" />
                          ) : (
                            <img src="/images/icon/down.png" alt="이모티콘" />
                          )}
                        </button>
                        <FilterModal
                          handleApply={(data) => handleApply(data, "brand")}
                          list={filterList.brand ?? []}
                          changeFirst={searchforceUpdate}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="flexB filtering">
                      권한
                      <div className="flex">
                        <button onClick={(e) => handleOrderType("auth")}>
                          {getIconForCustomerName("auth") === "asc" ? (
                            <img src="/images/icon/up.png" alt="이모티콘" />
                          ) : (
                            <img src="/images/icon/down.png" alt="이모티콘" />
                          )}
                        </button>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {creatorList.map((data, index) => (
                  <tr key={index}>
                    <td className="check">
                      <Checkbox
                        checked={checkedList[index]}
                        onChange={(e) => onCheckBoxChange(e, index)}
                      />
                    </td>
                    <td>{total - ((page - 1) * pageSize + index)}</td>
                    <td>{data.creatorID}</td>
                    <td>{data.channel}</td>
                    <td>
                      <Link to={`/admin/user/info?id=${data.user?.uuid}`}>
                        {data.user?.accountId}
                      </Link>
                    </td>
                    <td>{data.user?.username}</td>
                    <td>{data.user?.team}</td>
                    <td>{data.user?.language}</td>
                    <td>{data.user?.brand}</td>
                    <td>
                      {
                        roles.find((role) => role.value === data.user?.auth)
                          ?.label
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <Pagination
              current={page}
              total={total}
              pageSize={pageSize}
              onChange={handlePagination}
            />
          </div>
        </article>
      </section>
    </>
  );
}
