import { headers } from "../config/env";
import Axios from "../config/axios";
import { useCustomerStore } from "../store/customerStore";
import { ParamsProps } from "../utils/type";
import { createSearchParams } from "../utils/requestUtils";

export async function fetchLogListAll(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/dashboard/log/list`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchLogList(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/dashboard/log/list/user`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchLogListExcel(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  const searchParams = createSearchParams(params);
  return await Axios.get(`/log/list/excel`, {
    params: searchParams,
    headers: headers(localStorage.getItem("accesstoken")!),
    responseType: "blob",
  });
}
export async function fetchDashboardSummary() {
  const params = { customer: useCustomerStore.getState().id! };
  return await Axios.get(`/dashboard/summary`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchDashboardPerformance(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/dashboard/performance/list`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchDashboardChart(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`dashboard/list/time`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
