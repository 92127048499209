import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import type { MenuProps } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import showModal from "./message/Modal";
import { usePageStore } from "../store/pageStore";
const items: MenuProps["items"] = [
  {
    label: (
      <p className="flex">
        <img src="/images/icon/users.png" alt="이모티콘" />
        유저 관리
      </p>
    ),
    key: "/admin/user",
  },
  {
    label: (
      <p className="flex">
        <img src="/images/icon/manager.png" alt="이모티콘" />
        크리에이터 담당자 변경
      </p>
    ),
    key: "/admin/creator/list",
  },
  {
    label: (
      <p className="flex">
        <img src="/images/icon/metadata.png" alt="이모티콘" />
        메타데이터 관리
      </p>
    ),
    key: "/admin/metadata",
  },
  {
    label: (
      <p className="flex">
        <img src="/images/icon/download.png" alt="이모티콘" />
        다운로드센터
      </p>
    ),
    key: "/admin/download",
  },
];
const App = () => {
  const location = useLocation();
  const [current, setCurrent] = useState("");
  const [openSub, setOpenSub] = useState([""]);
  const navigate = useNavigate();
  const { isPageMoving, setPageStore } = usePageStore();
  const onClick: MenuProps["onClick"] = async (e) => {
    let isOk = false;
    if (location.pathname === "/admin/metadata") {
      if (isPageMoving === true) {
        await new Promise<void>((resolve) => {
          showModal({
            type: "alert",
            messages: ["입력을 중단하고 입력전 상태로 원상복구 하겠습니까?"],
            confirmOptions: {
              okText: "복구",
              onOk() {
                isOk = true;
                resolve();
              },
              onCancel() {
                resolve();
              },
            },
          });
        });
        if (!isOk) {
          return;
        } else {
          setCurrent(e.key);
          setPageStore({ isPageMoving: false });
          return navigate(`${e.key}`);
        }
      } else {
        setCurrent(e.key);
        setPageStore({ isPageMoving: false });
        return navigate(`${e.key}`);
      }
    } else if (location.pathname === "/creator/register") {
      await new Promise<void>((resolve) => {
        showModal({
          type: "alert",
          messages: ["입력을 중단하시겠습니까?"],
          confirmOptions: {
            okText: "중단",
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        return;
      } else {
        setCurrent(e.key);
        return navigate(`${e.key}`);
      }
    } else {
      setCurrent(e.key);
      return navigate(`${e.key}`);
    }
  };
  useEffect(() => {
    if (
      location.pathname === "/admin/user" ||
      location.pathname === "/admin/user/info" ||
      location.pathname === "/admin/user/register"
    ) {
      setOpenSub(["user"]);
    } else if (
      location.pathname === "/contact/list/member" ||
      location.pathname === "/contact/list/member/info" ||
      location.pathname === "/contact/member/creator/list" ||
      location.pathname === "/contact/member/creator/register" ||
      location.pathname === "/contact/global/creator/list" ||
      location.pathname === "/contact/global/creator/register"
    ) {
      setOpenSub(["contact"]);
    } else if (
      location.pathname === "/delivery/list/member" ||
      location.pathname === "/delivery/list/member/info" ||
      location.pathname === "/delivery/member/creator/list" ||
      location.pathname === "/delivery/member/creator/register" ||
      location.pathname === "/delivery/global/creator/list" ||
      location.pathname === "/delivery/global/creator/register"
    ) {
      setOpenSub(["delivery"]);
    } else if (
      location.pathname === "/contents/list/member" ||
      location.pathname === "/contents/list/member/info" ||
      location.pathname === "/contents/member/creator/list" ||
      location.pathname === "/contents/member/creator/register" ||
      location.pathname === "/contents/global/creator/list" ||
      location.pathname === "/contents/global/creator/register"
    ) {
      setOpenSub(["contents"]);
    } else {
      setOpenSub([]);
    }
    if (location.pathname.includes("/admin/user")) {
      setCurrent("/admin/user");
    } else if (
      location.pathname.includes("/admin/creator/list") ||
      location.pathname.includes("/admin/creator/update")
    ) {
      setCurrent("/admin/creator/list");
    } else {
      setCurrent(location.pathname);
    }
  }, [location.pathname]);
  const onOpenChange = async (openKeys: string[]) => {
    setOpenSub(openKeys);
  };
  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      openKeys={openSub}
      onOpenChange={onOpenChange}
      items={items}
      mode="inline"
    />
  );
};
export default App;
