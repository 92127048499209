import React, { useState, useEffect } from "react";
import { fetchContetsUser } from "../../api/index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Pagination, Tabs, Select } from "antd";
import type { PaginationProps } from "antd";
import {
  ContentsHitory,
  FilterCheck,
  Filter_group,
  ParamsProps,
  SortInfo,
} from "../../utils/type";
import dayjs from "dayjs";
import { fetchContetsCreator } from "../../api/creator";
import { filterNonEmptyValues, processParams } from "../../utils/common";
import FilterModal from "../pop/FilterModal";
interface Props {
  id: string | null;
  creatorProps?: string;
  forceUpdate: number;
}

export default function ContentsHistory({
  id,
  creatorProps,
  forceUpdate,
}: Props) {
  // data
  const resetData = {
    brand: [],
    product: [],
    language: [],
  };
  const navigate = useNavigate();
  const [list, setList] = useState<ContentsHitory[]>([]);
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchWord, setSearchWord] = useState("");
  const [sortInfo, setSortInfo] = useState<SortInfo[]>([]);
  const [filterList, setFilterList] = useState<Filter_group>(resetData);
  const [filterCheckList, setFilterCheckList] =
    useState<FilterCheck>(resetData);
  const [searchforceUpdate, setForceUpdate] = useState(0);
  // mounted
  useEffect(() => {
    setSortInfo([]);
    setForceUpdate((prevNumber) => prevNumber + 1);
    let params = {};
    getList(params);
  }, [forceUpdate]);
  useEffect(() => {
    setSortInfo([]);
    setFilterList(resetData);
    setFilterCheckList(resetData);
  }, [searchforceUpdate]);
  useEffect(() => {
    getFilter();
  }, [filterCheckList]);
  // method
  const getIconForCustomerName = (orderType: string) => {
    const customerNameSortInfo = sortInfo.find(
      (item) => item.orderType === orderType
    );

    if (customerNameSortInfo) {
      return customerNameSortInfo.sortOrder;
    }
    return "desc";
  };
  const generateOrderString = (orderList: SortInfo[]) => {
    const result = orderList
      .map((order) => {
        const sortOrderSymbol = order.sortOrder === "desc" ? "-" : "";
        return sortOrderSymbol + order.orderType;
      })
      .join(",");

    return result;
  };
  const toggleSortOrder = (currentSortOrder: "asc" | "desc"): "asc" | "desc" =>
    currentSortOrder === "asc" ? "desc" : "asc";
  const handleOrderType = async (orderType: string) => {
    const targetSortInfo = sortInfo.find(
      (info) => info.orderType === orderType
    );

    const newSortInfo = targetSortInfo
      ? sortInfo.map((info) =>
          info.orderType === orderType
            ? { orderType, sortOrder: toggleSortOrder(info.sortOrder) }
            : info
        )
      : [{ orderType, sortOrder: "asc" as "asc" | "desc" }];

    setSortInfo(newSortInfo);
    let params = {
      page,
      searchWord,
      orderType: generateOrderString(newSortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getList(params);
  };
  const getList = async (paramsData: ParamsProps) => {
    const params = processParams(paramsData);
    if (id !== null) {
      let res;
      if (creatorProps !== "yes") {
        res = await fetchContetsUser(id, params);
      } else {
        res = await fetchContetsCreator(id, params);
      }
      if (res.data.status === 200) {
        setList(res.data.data);
        setTotal(res.data.total);
        setPage(res.data.page);
        setFilterList(res.data.filter_group);
      }
    }
  };
  const handlePagination: PaginationProps["onChange"] = async (
    pageNum,
    pageSize
  ) => {
    const params = {
      page: pageNum,
      searchWord,
      orderType: generateOrderString(sortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getList(params);
  };
  const handleSearch = async () => {
    setForceUpdate((prevNumber) => prevNumber + 1);
    let params = {
      page: 1,
      searchWord,
    };
    await getList(params);
  };
  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setForceUpdate((prevNumber) => prevNumber + 1);
      let params = {
        page: 1,
        searchWord,
      };
      await getList(params);
    }
  };
  const handleBackButton = () => {
    navigate(-1);
  };
  const handleApply = (list: string[], type: string) => {
    setFilterCheckList((prevUserData) => ({
      ...prevUserData,
      [type]: list,
    }));
  };
  const getFilter = async () => {
    let params = {
      page: 1,
      searchWord: searchWord,
      orderType: generateOrderString(sortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getList(params);
  };
  return (
    <>
      <div className="searchBox">
        <div>
          <button onClick={handleSearch}>
            <img src="/images/icon/search.png" alt="검색 이모티콘" />
          </button>
          <input
            type="text"
            placeholder="검색어를 입력해주세요."
            name="searchWord"
            onChange={(e) => setSearchWord(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      {list && list.length > 0 ? (
        <div className="tableBox">
          <table>
            <colgroup>
              <col style={{ width: "8%" }} />
              <col style={{ width: "7%" }} />
              <col style={{ width: "17%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "13%" }} />
              {creatorProps === "yes" && <col style={{ width: "16%" }} />}
            </colgroup>
            <thead>
              <tr>
                <th>번호</th>
                <th>하위 번호</th>
                <th>
                  <div className="flexB filtering">크리에이터ID</div>
                </th>
                <th>
                  <div className="flexB filtering">
                    브랜드
                    <div className="flex">
                      <button onClick={(e) => handleOrderType("brand")}>
                        {getIconForCustomerName("brand") === "asc" ? (
                          <img src="/images/icon/up.png" alt="이모티콘" />
                        ) : (
                          <img src="/images/icon/down.png" alt="이모티콘" />
                        )}
                      </button>
                      <FilterModal
                        handleApply={(data) => handleApply(data, "brand")}
                        list={filterList.brand ?? []}
                        changeFirst={searchforceUpdate}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flexB filtering">
                    제품명
                    <div className="flex">
                      <button onClick={(e) => handleOrderType("product")}>
                        {getIconForCustomerName("product") === "asc" ? (
                          <img src="/images/icon/up.png" alt="이모티콘" />
                        ) : (
                          <img src="/images/icon/down.png" alt="이모티콘" />
                        )}
                      </button>
                      <FilterModal
                        handleApply={(data) => handleApply(data, "product")}
                        list={filterList.product ?? []}
                        changeFirst={searchforceUpdate}
                      />
                    </div>
                  </div>
                </th>
                <th className="url">URL</th>
                <th>
                  <div className="flexB filtering">
                    언어
                    <div className="flex">
                      <button onClick={(e) => handleOrderType("language")}>
                        {getIconForCustomerName("language") === "asc" ? (
                          <img src="/images/icon/up.png" alt="이모티콘" />
                        ) : (
                          <img src="/images/icon/down.png" alt="이모티콘" />
                        )}
                      </button>
                      <FilterModal
                        handleApply={(data) => handleApply(data, "language")}
                        list={filterList.language ?? []}
                        changeFirst={searchforceUpdate}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flexB filtering">
                    확인 날짜
                    <div className="flex">
                      <button onClick={(e) => handleOrderType("createdAt")}>
                        {getIconForCustomerName("createdAt") === "asc" ? (
                          <img src="/images/icon/up.png" alt="이모티콘" />
                        ) : (
                          <img src="/images/icon/down.png" alt="이모티콘" />
                        )}
                      </button>
                    </div>
                  </div>
                </th>
                {creatorProps === "yes" && <th>담당자</th>}
              </tr>
            </thead>
            <tbody>
              {list.map((data, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/contents/list/member/info?id=${data.id}`}>
                      <td>{total - ((page - 1) * pageSize + index)}</td>
                    </Link>
                  </td>
                  <td>{data.subId}</td>
                  <td>{data.creator?.creatorID}</td>
                  <td>{data.brand}</td>
                  <td className="double">
                    <span>{data.product}</span>
                  </td>
                  <td className="url">
                    <a href={`${data.url}`} target="_blank" rel="noreferrer">
                      링크
                    </a>
                  </td>
                  <td>{data.language}</td>
                  <td>
                    {data.createdAt
                      ? dayjs(data.createdAt).format("YYYY-MM-DD")
                      : ""}
                  </td>
                  {creatorProps === "yes" && <td>{data.user?.username}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div></div>
      )}
      <div className="pagination">
        <Pagination
          showSizeChanger={false}
          current={page}
          total={total}
          pageSize={pageSize}
          onChange={handlePagination}
        />
      </div>
      <div className="buttonWrap">
        <button className="back" onClick={handleBackButton}>
          뒤로
        </button>
      </div>
    </>
  );
}
