import { create } from "zustand";
import { pageStore, basePageStore } from "../utils/type";
export const INITIAL_STATE: basePageStore = {
  isPageMoving: false,
};
export const usePageStore = create<pageStore>((set, get) => ({
  // data
  isPageMoving: INITIAL_STATE.isPageMoving,
  // method
  setPageStore(item: basePageStore) {
    set(() => item);
  },
}));
