import React, { useState, useEffect } from "react";
import { Menu, Modal } from "antd";
import type { MenuProps } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import showModal from "./message/Modal";
import { usePageStore } from "../store/pageStore";
const items: MenuProps["items"] = [
  {
    label: (
      <p>
        <img src="/images/icon/dashboard.png" alt="이모티콘" />
        대시보드 홈
      </p>
    ),
    key: "/dashboard",
  },
  {
    label: (
      <p className="flex">
        <img src="/images/icon/creator.png" alt="이모티콘" />
        크리에이터 관리
      </p>
    ),
    key: "creator",
    children: [
      {
        label: <p>나의 크리에이터 조회</p>,
        key: "/creator/list/member",
      },
      {
        label: <p>전체 크리에이터 조회</p>,
        key: "/creator/list/global",
      },
      {
        label: <p>크리에이터 등록</p>,
        key: "/creator/register",
      },
      {
        label: <p>크리에이터 다수 등록</p>,
        key: "/creator/bulk",
      },
    ],
  },
  {
    label: (
      <p className="flex">
        <img src="/images/icon/contact.png" alt="이모티콘" />
        컨택 관리
      </p>
    ),
    key: "contact",
    children: [
      {
        label: <p>나의 컨택 이력 관리</p>,
        key: "/contact/list/member",
      },
      {
        label: <p>나의 크리에이터 컨택 등록</p>,
        key: "/contact/member/creator/list",
      },
      {
        label: <p>전체 크리에이터 컨택 등록</p>,
        key: "/contact/global/creator/list",
      },
    ],
  },
  {
    label: (
      <p className="flex">
        <img src="/images/icon/delivery.png" alt="이모티콘" />
        제품 배송 관리
      </p>
    ),
    key: "delivery",
    children: [
      {
        label: <p>나의 배송 이력 관리</p>,
        key: "/delivery/list/member",
      },
      {
        label: <p>나의 크리에이터 배송 등록</p>,
        key: "/delivery/member/creator/list",
      },
      {
        label: <p>전체 크리에이터 배송 등록</p>,
        key: "/delivery/global/creator/list",
      },
    ],
  },
  {
    label: (
      <p className="flex">
        <img src="/images/icon/contents.png" alt="이모티콘" />
        콘텐츠 관리
      </p>
    ),
    key: "contents",
    children: [
      {
        label: <p>나의 콘텐츠 이력 관리</p>,
        key: "/contents/list/member",
      },
      {
        label: <p>전체 콘텐츠 이력 관리</p>,
        key: "/contents/list/global",
      },
      {
        label: <p>나의 크리에이터 콘텐츠 등록</p>,
        key: "/contents/member/creator/list",
      },
      {
        label: <p>전체 크리에이터 콘텐츠 등록</p>,
        key: "/contents/global/creator/list",
      },
    ],
  },
  {
    label: (
      <p>
        <img src="/images/icon/notice.png" alt="이모티콘" />
        공지사항
      </p>
    ),
    key: "/notice",
  },
  {
    label: (
      <p>
        <img src="/images/icon/qna.png" alt="이모티콘" />
        Q&amp;A 게시판
      </p>
    ),
    key: "/qna",
  },
];
const App = () => {
  const location = useLocation();
  const [current, setCurrent] = useState("");
  const [openSub, setOpenSub] = useState([""]);
  const navigate = useNavigate();
  const { isPageMoving, setPageStore } = usePageStore();
  const onClick: MenuProps["onClick"] = async (e) => {
    let isOk = false;
    if (location.pathname === "/admin/metadata") {
      if (isPageMoving === true) {
        await new Promise<void>((resolve) => {
          showModal({
            type: "alert",
            messages: ["입력을 중단하고 입력전 상태로 원상복구 하겠습니까?"],
            confirmOptions: {
              okText: "복구",
              onOk() {
                isOk = true;
                resolve();
              },
              onCancel() {
                resolve();
              },
            },
          });
        });
        if (!isOk) {
          return;
        } else {
          setCurrent(e.key);
          setPageStore({ isPageMoving: false });
          return navigate(`${e.key}`);
        }
      } else {
        setCurrent(e.key);
        setPageStore({ isPageMoving: false });
        return navigate(`${e.key}`);
      }
    } else if (location.pathname === "/creator/register") {
      await new Promise<void>((resolve) => {
        showModal({
          type: "alert",
          messages: ["입력을 중단하시겠습니까?"],
          confirmOptions: {
            okText: "중단",
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        return;
      } else {
        setCurrent(e.key);
        return navigate(`${e.key}`);
      }
    } else {
      setCurrent(e.key);
      return navigate(`${e.key}`);
    }
  };
  useEffect(() => {
    if (
      location.pathname === "/creator/register" ||
      location.pathname === "/creator/bulk" ||
      location.pathname === "/creator/list/member" ||
      location.pathname === "/creator/list/member/info" ||
      location.pathname === "/creator/list/global" ||
      location.pathname === "/creator/list/global/info"
    ) {
      setOpenSub(["creator"]);
    } else if (
      location.pathname === "/contact/list/member" ||
      location.pathname === "/contact/list/member/info" ||
      location.pathname === "/contact/member/creator/list" ||
      location.pathname === "/contact/member/creator/register" ||
      location.pathname === "/contact/global/creator/list" ||
      location.pathname === "/contact/global/creator/register"
    ) {
      setOpenSub(["contact"]);
    } else if (
      location.pathname === "/delivery/list/member" ||
      location.pathname === "/delivery/list/member/info" ||
      location.pathname === "/delivery/member/creator/list" ||
      location.pathname === "/delivery/member/creator/register" ||
      location.pathname === "/delivery/global/creator/list" ||
      location.pathname === "/delivery/global/creator/register"
    ) {
      setOpenSub(["delivery"]);
    } else if (
      location.pathname === "/contents/list/global" ||
      location.pathname === "/contents/list/member" ||
      location.pathname === "/contents/list/member/info" ||
      location.pathname === "/contents/member/creator/list" ||
      location.pathname === "/contents/member/creator/register" ||
      location.pathname === "/contents/global/creator/list" ||
      location.pathname === "/contents/global/creator/register"
    ) {
      setOpenSub(["contents"]);
    } else {
      setOpenSub([]);
    }
    if (location.pathname === "/creator/list/member/info") {
      setCurrent("/creator/list/member");
    } else if (location.pathname === "/creator/list/global/info") {
      setCurrent("/creator/list/global");
    } else if (location.pathname === "/contact/list/member/info") {
      setCurrent("/contact/list/member");
    } else if (location.pathname === "/contact/member/creator/register") {
      setCurrent("/contact/member/creator/list");
    } else if (location.pathname === "/contact/global/creator/register") {
      setCurrent("/contact/global/creator/list");
    } else if (location.pathname === "/delivery/list/member/info") {
      setCurrent("/delivery/list/member");
    } else if (location.pathname === "/delivery/member/creator/register") {
      setCurrent("/delivery/member/creator/list");
    } else if (location.pathname === "/delivery/global/creator/register") {
      setCurrent("/delivery/global/creator/list");
    } else if (location.pathname === "/contents/list/member/info") {
      setCurrent("/contents/list/member");
    } else if (location.pathname === "/contents/member/creator/register") {
      setCurrent("/contents/member/creator/list");
    } else if (location.pathname === "/contents/global/creator/register") {
      setCurrent("/contents/global/creator/list");
    } else if (location.pathname.includes("/notice")) {
      setCurrent("/notice");
    } else if (location.pathname.includes("/qna")) {
      setCurrent("/qna");
    } else {
      setCurrent(location.pathname);
    }
  }, [location.pathname]);

  const onOpenChange = async (openKeys: string[]) => {
    setOpenSub(openKeys);
  };
  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      openKeys={openSub}
      onOpenChange={onOpenChange}
      items={items}
      mode="inline"
    />
  );
};
export default App;
