import React from "react";
import { Modal } from "antd";
import ErrorMessage from "./ErrorMessage";
import ConfirmationMessage from "./ConfirmationMessage";
import AlertMessage from "./AlertMessage";
import ErrorMessageWithBulk from "./ErrorMessageWithBulk";
import DefaultMessage from "./DefaultMessage";

interface ModalProps {
  messages: string[];
  type: "error" | "confirm" | "alert" | "bulk" | "normal";
  bulk?: number;
  confirmOptions?: {
    okText?: string;
    cancelText?: string;
    onOk?: () => void;
    onCancel?: () => void;
  };
}

const showModal = ({ messages, type, confirmOptions, bulk }: ModalProps) => {
  const modalDefaultConfig = {
    okText: "확인",
    centered: true,
    content: <DefaultMessage messages={messages} />,
  };
  const modalConfig = {
    okText: "확인",
    centered: true,
    content: <ErrorMessage messages={messages} />,
  };
  const modalConfigWithBulk = {
    okText: "확인",
    centered: true,
    content: <ErrorMessageWithBulk messages={messages} errorType={bulk ?? 0} />,
  };

  const modalConfirmConfig = {
    content: <ConfirmationMessage messages={messages} />,
    okText: "저장",
    cancelText: "취소",
    centered: true,
    ...confirmOptions,
  };
  const modalAlertConfig = {
    content: <AlertMessage messages={messages} />,
    okText: "삭제",
    cancelText: "취소",
    centered: true,
    ...confirmOptions,
  };

  if (type === "error") {
    Modal.error(modalConfig);
  } else if (type === "confirm") {
    Modal.confirm(modalConfirmConfig);
  } else if (type === "alert") {
    Modal.confirm(modalAlertConfig);
  } else if (type === "bulk") {
    Modal.error(modalConfigWithBulk);
  } else {
    Modal.error(modalDefaultConfig);
  }
};

export default showModal;
