import { headers } from "../config/env";
import Axios from "../config/axios";
import { BulkDeleteProps, ContentsInfo, ParamsProps } from "../utils/type";
import { useCustomerStore } from "../store/customerStore";
import { createSearchParams } from "../utils/requestUtils";

export async function fetchContentsList(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/contents/list`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchContentsListAll(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/contents/list/all`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchContentsListExcel(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  const searchParams = createSearchParams(params);
  return await Axios.get(`/contents/list/excel`, {
    params: searchParams,
    headers: headers(localStorage.getItem("accesstoken")!),
    responseType: "blob",
  });
}
export async function fetchContentsUserList(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/contents/list/user`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

export async function fetchAllContentsUserList(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/contents/list/all`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

export async function fetchContentsDetail(id: string) {
  return await Axios.get(`/contents/${id}`, {
    params: { customer: useCustomerStore.getState().id! },
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

export async function deleteContents({
  ids,
  select_all,
  filters,
}: BulkDeleteProps) {
  return await Axios.put(
    `/contents/delete`,
    { customer_id: useCustomerStore.getState().id!, ids, select_all, filters },
    {
      headers: headers(localStorage.getItem("accesstoken")!),
    }
  );
}

export async function createContents(data: ContentsInfo) {
  data.customer = useCustomerStore.getState().id!;
  return await Axios.post("/contents", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

export async function updateContents(data: ContentsInfo) {
  return await Axios.put("/contents", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
