import { create } from "zustand";
import {
  Metadatas,
  MetadataIdStore,
  MetadataStore,
  MetaAddbject,
} from "../utils/type";

export const INITIAL_STATE: MetadataIdStore = {
  id: null,
  category: [],
  marketing: [],
  product: [],
  language: [],
  brand: [],
  contact_method: [],
  delivery_progress: [],
  snsID: [],
  creator_type: [],
  supporter_recommand: [],
  contact_type: [],
  contact_account: [],
  notice_type: [],
  qna_type: [],
  team: [],
  country: [],
  dummy: [],
};

export const useMetadataStore = create<MetadataStore>((set, get) => ({
  // data
  marketing: INITIAL_STATE.marketing,
  category: INITIAL_STATE.category,
  product: INITIAL_STATE.product,
  language: INITIAL_STATE.language,
  brand: INITIAL_STATE.brand,
  contact_method: INITIAL_STATE.contact_method,
  delivery_progress: INITIAL_STATE.delivery_progress,
  snsID: INITIAL_STATE.snsID,
  creator_type: INITIAL_STATE.creator_type,
  supporter_recommand: INITIAL_STATE.supporter_recommand,
  contact_type: INITIAL_STATE.contact_type,
  contact_account: INITIAL_STATE.contact_account,
  notice_type: INITIAL_STATE.notice_type,
  qna_type: INITIAL_STATE.qna_type,
  team: INITIAL_STATE.team,
  country: INITIAL_STATE.country,
  // method
  setMetadata(item: MetadataIdStore) {
    set(() => item);
  },
  getMetadata(): MetadataIdStore {
    const state = get();
    return {
      id: state.id,
      category: state.category,
      marketing: state.marketing,
      product: state.product,
      language: state.language,
      brand: state.brand,
      contact_method: state.contact_method,
      delivery_progress: state.delivery_progress,
      snsID: state.snsID,
      creator_type: state.creator_type,
      supporter_recommand: state.supporter_recommand,
      contact_type: state.contact_type,
      contact_account: state.contact_account,
      notice_type: state.notice_type,
      qna_type: state.qna_type,
      team: state.team,
      country: state.country,
    };
  },
  updateList(propertyName: keyof MetaAddbject, newList: string[]) {
    set((state) => ({
      ...state,
      [propertyName]: newList,
    }));
  },
  setDummyDatas(item: string[]) {
    set((state) => ({
      ...state,
      dummy: item,
    }));
  },
  logoutMetadata() {
    set(() => INITIAL_STATE);
  },
}));
