import React, { useEffect, useState, useRef } from "react";
import { Select, Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import ChartFilterModal from "../pop/ChartFilterModal";
import DayChart from "../../components/chart/DayChart";
import dayjs, { Dayjs } from "dayjs";
import { ChartComponentProps, ParamsProps } from "../../utils/type";
import showModal from "../message/Modal";
import { handleErrorResponse, processParams } from "../../utils/common";
import { fetchDashboardChart } from "../../api/dashborad";
import { AxiosError } from "axios";
import { channel } from "diagnostics_channel";
export default function ResultTrend() {
  // data
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const periodOptions = [
    {
      label: "일별",
      value: "day",
    },
    {
      label: "월별",
      value: "month",
    },
  ];
  const typeOptions = [
    {
      value: "search",
      label: "서치",
    },
    {
      value: "contact",
      label: "컨택",
    },
    {
      value: "delivery",
      label: "제품 배송",
    },
    {
      value: "contents",
      label: "콘텐츠",
    },
  ];
  const categoryOptions = [
    {
      value: "user",
      label: "담당자",
    },
    {
      value: "team",
      label: "팀",
    },
    {
      value: "channel",
      label: "채널",
    },
    {
      value: "language",
      label: "언어",
    },
    {
      value: "brand",
      label: "브랜드",
    },
    {
      value: "country",
      label: "배송 국가",
    },
  ];

  const [filterCheckList, setFilterCheckList] = useState<string[]>([]);
  const [chartType, setChartType] = useState("search");
  const [chartCategory, setChartCategory] = useState("user");
  const [filterList, setFilterList] = useState(["a", "b"]);
  const [period, setPeriod] = useState("day");
  const [chartPropsData, setChartPropsData] = useState<ChartComponentProps>({
    xaxis: [],
    series: [],
  });
  const [chartSeriesData, setChartSeriesData] = useState<
    {
      name: string;
      data: number[];
    }[]
  >([]);
  const [categoryOption, setCategoryOptions] = useState(categoryOptions);
  const [typeOption, setTypeOption] = useState(typeOptions);
  // mounted
  useEffect(() => {
    const params = {
      type: chartType,
      category: chartCategory,
      dateType: period,
    };
    setCategoryOptions(categoryOptions);
    getDashboardChart(params);
    scrollToRight();
  }, []);
  // method
  const handleApply = (list: string[], type: string) => {
    setFilterCheckList(list);
    setChartPropsData((prevData) => {
      if (list.length > 0) {
        const updatedSeries = chartSeriesData.filter((item) =>
          list.includes(item.name)
        );
        return { ...prevData, series: updatedSeries };
      } else {
        const updatedSeries = chartSeriesData;
        return { ...prevData, series: updatedSeries };
      }
    });
  };
  const handleSelectChange = async (value: string, type: string) => {
    if (type === "type") {
      setChartType(value);
      if (value === "contact" || value === "delivery" || value === "contents") {
        setCategoryOptions(categoryOptions);
      } else if (value === "search") {
        setCategoryOptions(categoryOptions);
      } else {
        setCategoryOptions(categoryOptions);
      }
      const params = {
        type: value,
        category: chartCategory,
        dateType: period,
      };
      return await getDashboardChart(params);
    } else if (type === "category") {
      setChartCategory(value);
      if (value === "channel") {
        setTypeOption(typeOptions);
      } else if (value === "brand") {
        setTypeOption(typeOptions);
      } else {
        setTypeOption(typeOptions);
      }
      const params = {
        type: chartType,
        category: value,
        dateType: period,
      };
      return await getDashboardChart(params);
    }
  };
  const handlePeriodChange = async (e: RadioChangeEvent) => {
    const value = e.target.value;
    setPeriod(e.target.value);
    const params = {
      type: chartType,
      category: chartCategory,
      dateType: value,
    };
    return await getDashboardChart(params);
  };
  const getDashboardChart = async (paramsData: ParamsProps) => {
    const params = processParams(paramsData);
    try {
      const res = await fetchDashboardChart(params);
      if (res.data.status === 200) {
        const filters = res.data.data.map(
          (item: { name: string; data: number[] }, index: number) => {
            return item.name;
          }
        );
        setFilterList(filters);
        setChartSeriesData(res.data.data);
        setChartPropsData({
          xaxis: res.data.dateList,
          series: res.data.data,
        });
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };
  const scrollToRight = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.scrollLeft =
        scrollContainer.scrollWidth - scrollContainer.clientWidth;
    }
  };
  return (
    <article className="resultTrend">
      <div className="box">
        <p className="tit">항목별 성과 추이</p>
        <div className="sorting flex">
          <div className="flex">
            <p>차트 기준:</p>
            <Select
              style={{
                width: 124,
              }}
              value={chartType}
              onChange={(value) => handleSelectChange(value, "type")}
              options={typeOption}
            />
          </div>
          <div className="flex">
            <p>항목 기준:</p>
            <Select
              style={{
                width: 124,
              }}
              value={chartCategory}
              onChange={(value) => handleSelectChange(value, "category")}
              options={categoryOption}
            />
            <ChartFilterModal
              handleApply={(data) => handleApply(data, "chart")}
              list={filterList ?? []}
              filterReset={true}
            />
          </div>
          <div className="flex">
            <p>기간:</p>
            <Radio.Group
              options={periodOptions}
              onChange={handlePeriodChange}
              value={period}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
        </div>
        <div className="chartBox" ref={scrollContainerRef}>
          <DayChart
            xaxis={chartPropsData.xaxis}
            series={chartPropsData.series}
          />
        </div>
      </div>
    </article>
  );
}
