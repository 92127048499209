import React, { useState } from "react";
import { DatePicker, Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import locale from "antd/es/date-picker/locale/ko_KR";
import dayjs, { Dayjs } from "dayjs";
import ActivityLog from "../download/ActivityLog";
import { fetchLogListExcel } from "../../api/dashborad";
import { CsvHeaderProps, ParamsProps } from "../../utils/type";
import { logCsvHeaders } from "../../utils/csvHeaders";
import CsvDownloader from "../csv/CsvDownloader";
import handleDownload from "../../utils/useDownload";
const { RangePicker } = DatePicker;

export default function Log() {
  // data
  const periodOptions = [
    {
      label: "오늘",
      value: "today",
    },
    {
      label: "어제",
      value: "yesterday",
    },
    {
      label: "1주일",
      value: "week",
    },
    {
      label: "1개월",
      value: "month",
    },
    {
      label: "전체",
      value: "all",
    },
  ];
  const [period, setPeriod] = useState<string | null>("all");
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [isSearch, setIsSearch] = useState<number | null>(0);
  const [isDownLoad, setIsDownLoad] = useState(false);
  const [csvDatas, setCsvDatas] = useState<ParamsProps[] | null>();

  // mounted
  // method
  const handlePeriodChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    setPeriod(value);
    setDate(value);
  };
  const setDate = (value: string) => {
    switch (value) {
      case "today":
        setStartDate(dayjs());
        setEndDate(dayjs());
        return;
      case "yesterday":
        setStartDate(dayjs().subtract(1, "day"));
        setEndDate(dayjs());
        return;
      case "week":
        setStartDate(dayjs().subtract(7, "day"));
        setEndDate(dayjs());
        return;
      case "month":
        setStartDate(dayjs().subtract(1, "month"));
        setEndDate(dayjs());
        return;
      case "all":
        setStartDate(null);
        setEndDate(null);
        return;
      default:
        setStartDate(null);
        setEndDate(null);
        return;
    }
  };
  const onRangeChange: RangePickerProps["onChange"] = (dates, dateStrings) => {
    if (dates) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };
  const search = () => {
    setIsSearch((prev) => {
      if (prev || prev === 0) {
        return prev + 1;
      } else {
        return 0;
      }
    });
  };
  const transformDataWithLog = (
    csvHeaders: CsvHeaderProps[],
    csvData: ParamsProps[]
  ) => {
    const transformedData = csvData.map((data, index) => {
      const transformedItem: ParamsProps = { id: index + 1 };
      csvHeaders.forEach((header) => {
        if (header.key) {
          if (header.key === "createdAt") {
            const value = dayjs(data.createdAt).format("YYYY-MM-DD hh:mm:ss");
            transformedItem["createdAt"] = value;
          } else if (header.key === "accountId") {
            const value = data.user?.accountId ?? "";
            transformedItem["accountId"] = value;
          } else if (header.key === "username") {
            const value = data.user?.username ?? "";
            transformedItem["username"] = value;
          } else {
            const value = data.hasOwnProperty(header.key)
              ? data[header.key]
              : "";
            transformedItem[header.key] = value;
          }
        }
      });
      return transformedItem;
    });
    return transformedData;
  };
  const excelDownLoad = async () => {
    await getExcelDatas();
    setIsDownLoad(true);
  };
  const handleCloseCsv = () => {
    setIsDownLoad(false);
  };
  const getExcelDatas = async () => {
    let params = {
      startDate:
        startDate == null ? null : dayjs(startDate).format("YYYY-MM-DD"),
      endDate: endDate == null ? null : dayjs(endDate).format("YYYY-MM-DD"),
    };
    handleDownload(fetchLogListExcel, params);
    return;
  };
  return (
    <>
      <article className="log">
        <div className="sorting">
          <div className="flex mb26">
            <p className="fix">기간:</p>
            <Radio.Group
              options={periodOptions}
              onChange={handlePeriodChange}
              value={period}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
          <div className="flex">
            <p className="fix">날짜 구간:</p>
            <RangePicker
              locale={locale}
              onChange={onRangeChange}
              value={[startDate, endDate]}
            />
          </div>
          <div className="buttons">
            <button className="point" onClick={search}>
              조회
            </button>
            <button className="pointOut" onClick={excelDownLoad}>
              엑셀 내려받기
            </button>
          </div>
        </div>
        <ActivityLog
          search={isSearch}
          startDate={startDate}
          endDate={endDate}
        />
      </article>
      <CsvDownloader
        propsData={{ data: csvDatas ?? [], headers: logCsvHeaders }}
        isDownLoad={isDownLoad}
        closeDownLoad={handleCloseCsv}
      />
    </>
  );
}
