import React, { useState, useEffect } from "react";
import { fetchDashboardSummary } from "../../api/dashborad";
import showModal from "../message/Modal";
import { AxiosError } from "axios";
import { handleErrorResponse } from "../../utils/common";
export default function TodayResult() {
  // data
  const [today_creator, setToday_creator] = useState<number[]>([]);
  const [today_contact_cnt, setToday_contact_cnt] = useState<number[]>([]);
  const [today_delivery_cnt, setToday_delivery_cnt] = useState<number[]>([]);
  const [today_contents_cnt, setToday_contents_cnt] = useState<number[]>([]);
  // mounted
  useEffect(() => {
    getDashbardSummary();
  }, []);
  // method
  const calculateChangeAndDirection = (today: number, yesterday: number) => {
    if (yesterday === 0) {
      if (today === 0) {
        return {
          percentageChange: 0,
          direction: "",
        };
      } else {
        return {
          percentageChange: "신규",
          direction: "",
        };
      }
    }
    if (today === 0 && yesterday > 0) {
      return {
        percentageChange: "-",
        direction: "",
      };
    }
    var change = today - yesterday;
    var percentageChange = (change / Math.abs(yesterday)) * 100;
    percentageChange = Math.round(percentageChange * 100) / 100;
    var direction = change > 0 ? "surplus" : change < 0 ? "loss" : "";

    return {
      percentageChange: percentageChange,
      direction: direction,
    };
  };
  const getDashbardSummary = async () => {
    try {
      const res = await fetchDashboardSummary();
      if (res.data.status === 200) {
        const data = res.data.data;
        setToday_creator(data.today_creator);
        setToday_contact_cnt(data.today_contact_cnt);
        setToday_delivery_cnt(data.today_delivery_cnt);
        setToday_contents_cnt(data.today_contents_cnt);
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };
  const direction = (value: string) => {
    switch (value) {
      case "surplus":
        return "+";
      case "loss":
        return "";
      default:
        return "";
    }
  };
  return (
    <article className="todayResult">
      <ul className=" flexB">
        <li className="box">
          <p className="tit">오늘의 서치</p>
          <p className="number">{today_creator[0]}건</p>
          <span>
            전일 대비:{" "}
            <em
              className={
                calculateChangeAndDirection(today_creator[0], today_creator[1])
                  .direction
              }
            >
              {direction(
                calculateChangeAndDirection(today_creator[0], today_creator[1])
                  .direction
              )}
              {
                calculateChangeAndDirection(today_creator[0], today_creator[1])
                  .percentageChange
              }
              {`${
                calculateChangeAndDirection(today_creator[0], today_creator[1])
                  .percentageChange !== "신규" &&
                calculateChangeAndDirection(today_creator[0], today_creator[1])
                  .percentageChange !== "-"
                  ? "%"
                  : ""
              } `}
            </em>
          </span>
        </li>
        <li className="box">
          <p className="tit">오늘의 컨택</p>
          <p className="number">{today_contact_cnt[0]}건</p>
          <span>
            전일 대비:{" "}
            <em
              className={
                calculateChangeAndDirection(
                  today_contact_cnt[0],
                  today_contact_cnt[1]
                ).direction
              }
            >
              {direction(
                calculateChangeAndDirection(
                  today_contact_cnt[0],
                  today_contact_cnt[1]
                ).direction
              )}
              {
                calculateChangeAndDirection(
                  today_contact_cnt[0],
                  today_contact_cnt[1]
                ).percentageChange
              }
              {`${
                calculateChangeAndDirection(
                  today_contact_cnt[0],
                  today_contact_cnt[1]
                ).percentageChange !== "신규" &&
                calculateChangeAndDirection(
                  today_contact_cnt[0],
                  today_contact_cnt[1]
                ).percentageChange !== "-"
                  ? "%"
                  : ""
              } `}
            </em>
          </span>
        </li>
        <li className="box">
          <p className="tit">오늘의 제품 배송</p>
          <p className="number">{today_delivery_cnt[0]}건</p>
          <span>
            전일 대비:{" "}
            <em
              className={
                calculateChangeAndDirection(
                  today_delivery_cnt[0],
                  today_delivery_cnt[1]
                ).direction
              }
            >
              {direction(
                calculateChangeAndDirection(
                  today_delivery_cnt[0],
                  today_delivery_cnt[1]
                ).direction
              )}
              {
                calculateChangeAndDirection(
                  today_delivery_cnt[0],
                  today_delivery_cnt[1]
                ).percentageChange
              }
              {`${
                calculateChangeAndDirection(
                  today_delivery_cnt[0],
                  today_delivery_cnt[1]
                ).percentageChange !== "신규" &&
                calculateChangeAndDirection(
                  today_delivery_cnt[0],
                  today_delivery_cnt[1]
                ).percentageChange !== "-"
                  ? "%"
                  : ""
              } `}
            </em>
          </span>
        </li>
        <li className="box">
          <p className="tit">오늘의 콘텐츠 업로드</p>
          <p className="number">{today_contents_cnt[0]}건</p>
          <span>
            전일 대비:{" "}
            <em
              className={
                calculateChangeAndDirection(
                  today_contents_cnt[0],
                  today_contents_cnt[1]
                ).direction
              }
            >
              {direction(
                calculateChangeAndDirection(
                  today_contents_cnt[0],
                  today_contents_cnt[1]
                ).direction
              )}
              {
                calculateChangeAndDirection(
                  today_contents_cnt[0],
                  today_contents_cnt[1]
                ).percentageChange
              }
              {`${
                calculateChangeAndDirection(
                  today_contents_cnt[0],
                  today_contents_cnt[1]
                ).percentageChange !== "신규" &&
                calculateChangeAndDirection(
                  today_contents_cnt[0],
                  today_contents_cnt[1]
                ).percentageChange !== "-"
                  ? "%"
                  : ""
              } `}
            </em>
          </span>
        </li>
      </ul>
    </article>
  );
}
