import axios from "axios";
import { useUserStore } from "../store/userStore";

export const baseUrl = process.env.REACT_APP_API_URL;

const Axios = axios.create({
  baseURL: baseUrl,
});

Axios.interceptors.response.use(
  function (res) {
    return res;
  },

  function (err) {
    if (err.response.status === 401) {
      useUserStore.getState().logoutUser();
      throw err;
    } else {
      throw err;
    }
  }
);

export default Axios;
