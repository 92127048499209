import { createBrowserRouter } from "react-router-dom";
import AccountLayout from "../layouts/Account";
import Login from "../views/Login";

import {
  ProtectedRoute,
  ProtectedRouteWithLogin,
  ProtectedRouteWithAdmin,
} from "./PreventRoute";
// layouts
import DefaultLayout from "../layouts/Default";
import AdminLayout from "../layouts/Admin";
import Admin from "../views/Admin";
import Dashboard from "../views/Dashboard";
import CreatorRegister from "../views/creator/CreatorRegister";
import BulkRegister from "../views/creator/BulkRegister";
import MyCreator from "../views/creator/MyCreator";
import MyCreatorManage from "../views/creator/MyCreatorManage";
import AllCreators from "../views/creator/AllCreators";
import AllCreatorsManage from "../views/creator/AllCreatorsManage";
import Manager from "../views/creator/Manager";
import ManagerChange from "../views/creator/ManagerChange";
import MyContact from "../views/contact/MyContact";
import MyContactManage from "../views/contact/MyContactManage";
import MyContactRegister from "../views/contact/MyContactRegister";
import MyContactRegister2 from "../views/contact/MyContactRegister2";
import AllContactsRegister from "../views/contact/AllContactsRegister";
import AllContactsRegister2 from "../views/contact/AllContactsRegister2";
import MyDelivery from "../views/delivery/MyDelivery";
import MyDeliveryManage from "../views/delivery/MyDeliveryManage";
import MyDeliveryRegister from "../views/delivery/MyDeliveryRegister";
import MyDeliveryRegister2 from "../views/delivery/MyDeliveryRegister2";
import AllDeliveriesRegister from "../views/delivery/AllDeliveriesRegister";
import AllDeliveriesRegister2 from "../views/delivery/AllDeliveriesRegister2";
import MyContents from "../views/contents/MyContents";
import MyContentsManage from "../views/contents/MyContentsManage";
import MyContentsRegister from "../views/contents/MyContentsRegister";
import MyContentsRegister2 from "../views/contents/MyContentsRegister2";
import AllContentsRegister from "../views/contents/AllContentsRegister";
import AllContentsRegister2 from "../views/contents/AllContentsRegister2";
import Notice from "../views/notice/Notice";
import NoticeRegister from "../views/notice/NoticeRegister";
import Qna from "../views/qna/Qna";
import QnaDetail from "../views/qna/QnaDetail";
import QnaRegister from "../views/qna/QnaRegister";
import User from "../views/user/User";
import UserManage from "../views/user/UserManage";
import UserRegister from "../views/user/UserRegister";
import Download from "../views/download/Download";
import Metadata from "../views/metadata/Metadata";
import Mypage from "../views/Mypage";
import ChnagePasswordDone from "../views/ChangePasswordDone";
import AllContents from "../views/contents/AllContents";

const router = createBrowserRouter([
  {
    // path: "/",
    element: (
      <ProtectedRoute>
        <DefaultLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/creator/register",
        element: <CreatorRegister />,
      },
      {
        path: "/creator/bulk",
        element: <BulkRegister />,
      },
      {
        path: "/creator/list/member",
        element: <MyCreator />,
      },
      {
        path: "/creator/list/member/info",
        element: <MyCreatorManage />,
      },
      {
        path: "/creator/list/global",
        element: <AllCreators />,
      },
      {
        path: "/creator/list/global/info",
        element: <AllCreatorsManage />,
      },
      {
        path: "/admin/creator/list",
        element: <Manager />,
      },
      {
        path: "/admin/creator/update",
        element: <ManagerChange />,
      },
      {
        path: "/contact/list/member",
        element: <MyContact />,
      },
      {
        path: "/contact/list/member/info",
        element: <MyContactManage />,
      },
      {
        path: "/contact/member/creator/list",
        element: <MyContactRegister />,
      },
      {
        path: "/contact/member/creator/register",
        element: <MyContactRegister2 />,
      },
      {
        path: "/contact/global/creator/list",
        element: <AllContactsRegister />,
      },
      {
        path: "/contact/global/creator/register",
        element: <AllContactsRegister2 />,
      },
      {
        path: "/delivery/list/member",
        element: <MyDelivery />,
      },
      {
        path: "/delivery/list/member/info",
        element: <MyDeliveryManage />,
      },
      {
        path: "/delivery/member/creator/list",
        element: <MyDeliveryRegister />,
      },
      {
        path: "/delivery/member/creator/register",
        element: <MyDeliveryRegister2 />,
      },
      {
        path: "/delivery/global/creator/list",
        element: <AllDeliveriesRegister />,
      },
      {
        path: "/delivery/global/creator/register",
        element: <AllDeliveriesRegister2 />,
      },
      {
        path: "/contents/list/member",
        element: <MyContents />,
      },
      {
        path: "/contents/list/global",
        element: <AllContents />,
      },
      {
        path: "/contents/list/member/info",
        element: <MyContentsManage />,
      },
      {
        path: "/contents/member/creator/list",
        element: <MyContentsRegister />,
      },
      {
        path: "/contents/member/creator/register",
        element: <MyContentsRegister2 />,
      },
      {
        path: "/contents/global/creator/list",
        element: <AllContentsRegister />,
      },
      {
        path: "/contents/global/creator/register",
        element: <AllContentsRegister2 />,
      },
      {
        path: "/notice",
        element: <Notice />,
      },
      {
        path: "/notice/register",
        element: <NoticeRegister />,
      },
      {
        path: "/qna",
        element: <Qna />,
      },
      {
        path: "/qna/detail",
        element: <QnaDetail />,
      },
      {
        path: "/qna/register",
        element: <QnaRegister />,
      },
      {
        path: "/admin/user",
        element: <User />,
      },
      {
        path: "/admin/user/info",
        element: <UserManage />,
      },
      {
        path: "/admin/user/register",
        element: <UserRegister />,
      },
      {
        path: "/admin/download",
        element: <Download />,
      },
      {
        path: "/admin/metadata",
        element: <Metadata />,
      },
      {
        path: "/mypage",
        element: <Mypage />,
      },
    ],
  },
  {
    element: (
      <ProtectedRouteWithLogin>
        <AccountLayout />
      </ProtectedRouteWithLogin>
    ),
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/password/change/done",
        element: <ChnagePasswordDone />,
      },
    ],
  },
  {
    element: (
      <ProtectedRouteWithAdmin>
        <AdminLayout />
      </ProtectedRouteWithAdmin>
    ),
    children: [
      {
        path: "/admin",
        element: <Admin />,
      },
    ],
  },
]);

export default router;
