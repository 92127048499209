import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Customer } from "../../utils/type";
import showModal from "../../components/message/Modal";
import { createCustomer, updateCustomer } from "../../api/customer";
import { handleErrorResponse } from "../../utils/common";
import { AxiosError } from "axios";
interface Props {
  isOpen: boolean;
  propsData: Customer | null;
  closeModal: () => void;
  submitModal: () => void;
}
const initialCustomer = {
  customerName: "",
  business_type: "",
  address: "",
  business_number: "",
};
export default function RegisterClientModal({
  isOpen,
  propsData,
  closeModal,
  submitModal,
}: Props) {
  // data
  const [customer, setCustomer] = useState<Customer>(initialCustomer);
  // mounted
  useEffect(() => {
    if (propsData && propsData.id) {
      setCustomer(propsData);
    } else {
      setCustomer(initialCustomer);
    }
  }, [propsData]);
  // method
  const submit = async () => {
    if (customer.customerName === "") {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    let isOk = false;
    try {
      if (propsData?.id) {
        await new Promise<void>((resolve) => {
          showModal({
            type: "confirm",
            messages: ["고객사를 수정하시겠습니까?"],
            confirmOptions: {
              onOk() {
                isOk = true;
                resolve();
              },
              onCancel() {
                resolve();
              },
            },
          });
        });
      } else {
        await new Promise<void>((resolve) => {
          showModal({
            type: "confirm",
            messages: ["고객사를 생성하시겠습니까?"],
            confirmOptions: {
              onOk() {
                isOk = true;
                resolve();
              },
              onCancel() {
                resolve();
              },
            },
          });
        });
      }

      if (!isOk) {
        return;
      }
      let data = customer;
      let res;
      if (propsData?.id) {
        data.id = propsData.id;
        res = await updateCustomer(data);
      } else {
        res = await createCustomer(data);
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
    setCustomer(initialCustomer);
    submitModal();
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCustomer((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };
  const onClose = () => {
    setCustomer(initialCustomer);
    closeModal();
  };

  return isOpen ? (
    <section className="modal">
      <article className="modalBox large">
        <div>
          <div className="modal_header">
            <h2>고객사 {propsData?.id ? "수정" : "생성"}</h2>
          </div>
          <div className="modal_body">
            <div className="inputBox">
              <p>
                고객사 <span className="red">*</span>
              </p>
              <input
                type="text"
                name="customerName"
                value={customer.customerName ?? ""}
                onChange={onChange}
              />
            </div>
            <div className="inputBox">
              <p>산업</p>
              <input
                type="text"
                name="business_type"
                value={customer.business_type ?? ""}
                onChange={onChange}
              />
            </div>
            <div className="inputBox">
              <p>주소</p>
              <input
                type="text"
                name="address"
                value={customer.address ?? ""}
                onChange={onChange}
              />
            </div>
            <div className="inputBox">
              <p>사업자번호</p>
              <input
                type="text"
                name="business_number"
                value={customer.business_number ?? ""}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="buttonWrap">
            <button className="point" onClick={submit}>
              저장
            </button>
            <button className="gray" onClick={onClose}>
              취소
            </button>
          </div>
        </div>
      </article>
    </section>
  ) : null;
}
