import React from "react";
import { Tabs } from "antd";
import UserDropdownMenu from "../components/UserDropdownMenu";
import TodayResult from "../components/dashboard/TodayResult";
import ResultState from "../components/dashboard/ResultState";
import ResultTrend from "../components/dashboard/ResultTrend";
import Log from "../components/dashboard/Log";
const { TabPane } = Tabs;
export default function Dashboard() {
  // data
  // mounted
  // method
  return (
    <>
      <section className="contents dashboard">
        <article className="title flexB">
          <h1>대시보드 홈</h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <Tabs defaultActiveKey="1" className="tabBoard">
          <TabPane tab="성과 분석" key="1">
            <TodayResult />
            <ResultState />
            <ResultTrend />
          </TabPane>
          <TabPane tab="활동 로그 분석" key="2">
            <Log />
          </TabPane>
        </Tabs>
      </section>
    </>
  );
}
