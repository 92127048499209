import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Select, DatePicker, Modal } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import { fetchCreatorDetail } from "../../api/creator";
import { INITIAL_STATE, useMetadataStore } from "../../store/metadataStore";
import { ContentsInfo, MetaAddbject, ResContentsInfo } from "../../utils/type";
import { fetchContentsDetail, updateContents } from "../../api/contents";
import type { DatePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import showModal from "../../components/message/Modal";
import {
  areObjectsEqual,
  convertToInteger,
  getEmptyProperty,
  handleErrorResponse,
} from "../../utils/common";
import { AxiosError } from "axios";
import { useUserStore } from "../../store/userStore";

const initialContents = {
  customer: null,
  creator: null,
  user: null,
  contents_date: null,
  language: "",
  brand: "",
  product: "",
  hits: null,
  comments: null,
  likes: null,
  saves: null,
  url: "",
  recommend: "",
  ugc_agree: false,
  ugc_agree_date: null,
  note: "",
};

export default function MyContentsManage() {
  const { language, brand, product, supporter_recommand, getMetadata } =
    useMetadataStore();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const params_id = searchParams.get("id");
  const [metadata, setMetadata] = useState<MetaAddbject>(INITIAL_STATE);
  const [contentsInfo, setContentsInfo] =
    useState<ContentsInfo>(initialContents);
  const [resContentsInfo, setResContentsInfo] =
    useState<ResContentsInfo>(initialContents);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [productOptions, setProductOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [contentsDate, setContentsDate] = useState<Dayjs | null>(null);
  const [ugcDate, setUgcDate] = useState<Dayjs | null>(null);
  const [errorProperties, setErrorProperties] = useState<string | null>(null);
  const { auth, id } = useUserStore();
  // mounted
  useEffect(() => {
    getContentsDetail();
  }, []);
  useEffect(() => {
    setMetadata(getMetadata());
  }, [language, brand, supporter_recommand, product]);
  // method
  const getContentsDetail = async () => {
    if (params_id !== null) {
      try {
        const res = await fetchContentsDetail(params_id);
        if (res.data.status === 200) {
          setResContentsInfo(res.data.data);
          setContentsInfo({
            ...res.data.data,
            user: res.data.data.user.id,
            creator: res.data.data.creator.id,
          });
          setContentsDate(dayjs(res.data.data.contents_date));
          setUgcDate(
            res.data.data.ugc_agree_date === null
              ? null
              : dayjs(res.data.data.ugc_agree_date)
          );
        }
      } catch (err) {
        handleErrorResponse(err as AxiosError);
      }
    }
  };
  const handleSelectChange = (value: string, type: string) => {
    if (errorProperties === type) {
      setErrorProperties(null);
    }
    if (type === "brand") {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        brand: value,
        product: "",
      }));
      const productList = product
        .filter((item) => item.brand === value)
        .map((item) => ({ value: item.name, label: item.name }));
      setProductOptions(productList);
    } else if (type === "language") {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        language: value,
      }));
    } else if (type === "recommend") {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        recommend: value,
      }));
    } else if (type === "product") {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        product: value,
      }));
    } else if (type === "ugc_agree") {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        ugc_agree: value === "false" ? false : true,
      }));
    }
  };
  const onContentsDateChange: DatePickerProps["onChange"] = (
    date: Dayjs | null,
    dateString
  ) => {
    if (errorProperties === "contentsDate") {
      setErrorProperties(null);
    }
    setContentsDate(date);
  };
  const onUgcDateChange: DatePickerProps["onChange"] = (
    date: Dayjs | null,
    dateString
  ) => {
    setUgcDate(date);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (errorProperties === name) {
      setErrorProperties(null);
    }
    if (
      name === "comments" ||
      name === "hits" ||
      name === "likes" ||
      name === "saves"
    ) {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        [name]: convertToInteger(Number(value)),
      }));
    } else {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };
  const transformArray = (
    data: string[]
  ): { value: string; label: string }[] => {
    return data.map((item) => ({ value: item, label: item }));
  };
  const compareDate = (date1: string, date2: string) => {
    if (date1 === date2) {
      return false;
    }
    return true;
  };

  const submit = async () => {
    if (auth === "supporter" && contentsInfo.user !== id) {
      return showModal({
        type: "error",
        messages: ["다른 유저가 등록한 정보는 수정할 수 없습니다."],
      });
    }
    if (isButtonDisabled) {
      return;
    }
    let compare = areObjectsEqual(contentsInfo, resContentsInfo, [
      "language",
      "hits",
      "likes",
      "comments",
      "saves",
      "url",
      "recommend",
      "ugc_agree",
      "note",
    ]);
    const date1 = compareDate(
      dayjs(contentsDate).format("YYYY-MM-DD"),
      dayjs(resContentsInfo.contents_date).format("YYYY-MM-DD")
    );
    const date2 = compareDate(
      dayjs(ugcDate).format("YYYY-MM-DD"),
      dayjs(resContentsInfo.ugc_agree_date).format("YYYY-MM-DD")
    );
    // setIsButtonDisabled(false);
    // return;

    if (!compare || date1 || date2) {
    }
    let where = getEmptyProperty(contentsInfo, [
      "language",
      "contentsDate",
      "brand",
      "product",
      "url",
    ]);
    setErrorProperties(where);
    if (contentsDate === null) {
      setErrorProperties("contentsDate");
    }
    if (
      contentsInfo.brand === "" ||
      contentsInfo.language === "" ||
      contentsInfo.brand === "" ||
      contentsInfo.url === "" ||
      contentsDate === null
    ) {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }

    try {
      setIsButtonDisabled(true);
      const data = {
        ...contentsInfo,
        contents_date: dayjs(contentsDate).format("YYYY-MM-DD"),
        ugc_agree_date:
          ugcDate === null ? null : dayjs(ugcDate).format("YYYY-MM-DD"),
      };
      let isOk = false;
      if (!compare || date1 || date2) {
        await new Promise<void>((resolve) => {
          showModal({
            type: "alert",
            messages: [
              "브랜드 및 제품 외 정보에 수정 사항이 있습니다.",
              "동일한 배송 번호 건에 수정 사항을 모두 적용하시겠습니까?",
            ],
            confirmOptions: {
              okText: "저장",
              onOk() {
                isOk = true;
                data.flag = 1;
                resolve();
              },
              onCancel() {
                resolve();
              },
            },
          });
        });
      } else {
        await new Promise<void>((resolve) => {
          showModal({
            type: "alert",
            messages: ["해당 사항을 수정하시겠습니까?"],
            confirmOptions: {
              okText: "저장",
              onOk() {
                isOk = true;
                resolve();
                data.flag = 0;
              },
              onCancel() {
                resolve();
              },
            },
          });
        });
      }
      if (!isOk) {
        return;
      }
      // return;
      let res = await updateContents(data);
      return navigate("/contents/list/member");
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    } finally {
      setIsButtonDisabled(false);
    }
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>콘텐츠 관리 &gt; 나의 콘텐츠 이력 관리 &gt; 콘텐츠 정보</h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <article className="register">
          <h3 className="contentTitle">콘텐츠 정보</h3>
          <div className="registerBox">
            <div className="flex">
              <div>
                <p>크리에이터 ID</p>
                <input
                  type="text"
                  disabled
                  className="basic"
                  value={resContentsInfo.creator?.creatorID ?? ""}
                />
              </div>
              <div>
                <p>담당자</p>
                <input
                  type="text"
                  disabled
                  value={resContentsInfo.user?.username ?? ""}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>채널</p>
                <input
                  type="text"
                  disabled
                  className="basic"
                  value={resContentsInfo.creator?.channel ?? ""}
                />
              </div>
              <div>
                <p>언어</p>
                <input
                  type="text"
                  disabled
                  value={resContentsInfo?.language ?? ""}
                />
              </div>
              <div>
                <p>구분</p>
                <input
                  type="text"
                  disabled
                  value={resContentsInfo.creator?.type ?? ""}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>URL</p>
                <input
                  type="text"
                  disabled
                  className="basic"
                  value={resContentsInfo.creator?.url ?? ""}
                />
              </div>
              <div>
                <p>팔로워수</p>
                <input
                  type="text"
                  disabled
                  value={resContentsInfo.creator?.followerNo ?? ""}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  언어 <span className="red">*</span>
                </p>
                <Select
                  placeholder="언어"
                  style={{
                    width: 196,
                  }}
                  value={contentsInfo.language ?? ""}
                  onChange={(value) => handleSelectChange(value, "language")}
                  options={transformArray(metadata.language)}
                />
              </div>
              <div>
                <p>
                  콘텐츠 업로드 날짜 <span className="red">*</span>
                </p>
                <DatePicker
                  placeholder="날짜를 선택하세요."
                  style={{
                    width: 196,
                  }}
                  value={contentsDate}
                  locale={locale}
                  onChange={onContentsDateChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  브랜드 <span className="red">*</span>
                </p>
                <Select
                  placeholder="브랜드"
                  style={{
                    width: 196,
                  }}
                  value={contentsInfo.brand ?? ""}
                  onChange={(value) => handleSelectChange(value, "brand")}
                  options={transformArray(metadata.brand)}
                />
              </div>
              <div>
                <p>
                  제품 <span className="red">*</span>
                </p>
                <Select
                  placeholder="제품"
                  style={{
                    width: 628,
                  }}
                  value={
                    contentsInfo.product === "" ? null : contentsInfo.product
                  }
                  onChange={(value) => handleSelectChange(value, "product")}
                  options={productOptions}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>조회수</p>
                <input
                  type="number"
                  name="hits"
                  value={contentsInfo.hits ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>댓글</p>
                <input
                  type="number"
                  name="comments"
                  value={contentsInfo.comments ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>좋아요</p>
                <input
                  type="number"
                  name="likes"
                  value={contentsInfo.likes ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>저장</p>
                <input
                  type="number"
                  name="saves"
                  value={contentsInfo.saves ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  URL <span className="red">*</span>
                </p>
                <input
                  type="text"
                  className="basic"
                  name="url"
                  value={contentsInfo.url ?? ""}
                  placeholder="http://example.com"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>서포터추천</p>
                <Select
                  placeholder="서포터추천"
                  style={{
                    width: 196,
                  }}
                  value={
                    contentsInfo.recommend === ""
                      ? null
                      : contentsInfo.recommend
                  }
                  onChange={(value) => handleSelectChange(value, "recommend")}
                  options={transformArray(metadata.supporter_recommand)}
                />
              </div>
              <div>
                <p>UGC 약관 동의</p>
                <Select
                  placeholder="UGC 약관 동의"
                  style={{
                    width: 196,
                  }}
                  value={`${contentsInfo.ugc_agree}`}
                  onChange={(value) => handleSelectChange(value, "ugc_agree")}
                  options={[
                    {
                      value: "true",
                      label: "Yes",
                    },
                    {
                      value: "false",
                      label: "No",
                    },
                  ]}
                />
              </div>
              <div>
                <p>UGC 약관 안내일</p>
                <DatePicker
                  placeholder="날짜를 선택하세요."
                  style={{
                    width: 196,
                  }}
                  locale={locale}
                  value={ugcDate}
                  onChange={onUgcDateChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>비고</p>
                <input
                  type="text"
                  className="note"
                  name="note"
                  value={contentsInfo.note ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="buttonWrap">
            <button
              className="point"
              onClick={submit}
              disabled={isButtonDisabled}
            >
              수정
            </button>
            <Link to="/contents/list/member" className="back">
              취소
            </Link>
          </div>
        </article>
      </section>
    </>
  );
}
