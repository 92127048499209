import React from "react";

interface MessageProps {
  messages: string[];
  errorType: number;
}

const ErrorMessageWithBulk = ({ messages, errorType }: MessageProps) => (
  <div className="flex">
    <img src="/images/icon/error.png" alt="이모티콘" />
    <ul>
      <p>업로드하신 엑셀 파일에 아래와 같은 오류가 있습니다.</p>
      <li>
        {errorType === 1 && "필수 입력 필두 중 입력되지 않은 값이 있습니다."}
        {errorType === 2 && "데이터 타입이 맞지 않게 입력된 값이 있습니다."}
        {errorType === 3 && "아래 크리에이터는 이미 등록된 크리에이터입니다."}
        <ul>
          {messages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      </li>
    </ul>
  </div>
);

export default ErrorMessageWithBulk;
