import { headers, headersWithFile } from "../config/env";
import Axios from "../config/axios";
import { BulkDeleteProps, Customer, ParamsProps } from "../utils/type";
import { useCustomerStore } from "../store/customerStore";

export async function fetchCustomerList(params: ParamsProps) {
  return await Axios.get(`/customer/list`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

export async function fetchCustomerDetail(id: string) {
  return await Axios.get(`/customer/${id}`, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

export async function deleteCustomers({
  ids,
  select_all,
  filters,
}: BulkDeleteProps) {
  return await Axios.put(
    "/customer/delete",
    { customer_id: useCustomerStore.getState().id!, ids, select_all, filters },
    {
      headers: headers(localStorage.getItem("accesstoken")!),
    }
  );
}

export async function createCustomer(data: Customer) {
  return await Axios.post("/customer", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function updateCustomer(data: Customer) {
  return await Axios.put("/customer", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function uploadLogo(data: FormData) {
  return await Axios.post("/customer/upload/logo", data, {
    headers: headersWithFile(localStorage.getItem("accesstoken")!),
  });
}
export async function deleteLogo(id: string) {
  return await Axios.delete(`/customer/delete/logo/${id}`, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
