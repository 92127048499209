import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";

interface CsvDownloaderProps {
  propsData: {
    data: any[];
    headers: { label: string; key: string }[];
  };
  isDownLoad: boolean;
  closeDownLoad: () => void;
}

export default function CsvDownloader({
  propsData,
  isDownLoad,
  closeDownLoad,
}: CsvDownloaderProps) {
  // data
  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  // mounted
  useEffect(() => {}, [propsData]);
  useEffect(() => {
    if (isDownLoad === true) {
      csvExport();
    }
  }, [isDownLoad]);
  // method
  const csvExport = async () => {
    if (csvLink.current) {
      csvLink.current.link?.click();
    }
  };
  return (
    <div style={{ display: "none" }}>
      <CSVLink
        data={propsData.data}
        headers={propsData.headers}
        ref={csvLink}
        filename={"crysbe.csv"}
        asyncOnClick={true}
        onClick={(event, done) => {
          done();
          closeDownLoad();
        }}
      >
        Download me
      </CSVLink>
    </div>
  );
}
