import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Select } from "antd";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import { fetchCreatorDetail } from "../../api/creator";
import {
  ContactInfo,
  MetaAddbject,
  Metadatas,
  ResContactInfo,
  UserForUser,
} from "../../utils/type";
import { INITIAL_STATE, useMetadataStore } from "../../store/metadataStore";
import { useUserStore } from "../../store/userStore";
import showModal from "../../components/message/Modal";
import {
  createContact,
  fetchContactDetail,
  updateContact,
} from "../../api/contact";
import { getEmptyProperty, handleErrorResponse } from "../../utils/common";
import { AxiosError } from "axios";

const initialContact = {
  creator: null,
  user: null,
  customer: null,
  language: "",
  brand: "",
  method: "",
  type: "",
  account: "",
  note: "",
  is_accept: null,
  is_counting: null,
};

export default function MyContactManage() {
  // data
  const {
    language,
    brand,
    contact_method,
    contact_type,
    contact_account,
    getMetadata,
  } = useMetadataStore();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const params_id = searchParams.get("id");
  const [contactInfo, setContactInfo] = useState<ContactInfo>(initialContact);
  const [resContactInfo, setResContactInfo] =
    useState<ResContactInfo>(initialContact);
  const [metadata, setMetadata] = useState<MetaAddbject>(INITIAL_STATE);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errorProperties, setErrorProperties] = useState<string | null>(null);
  const { auth, id } = useUserStore();
  // mounted
  useEffect(() => {
    getContactDetail();
  }, []);
  useEffect(() => {
    setMetadata(getMetadata());
  }, [language, brand, contact_method, contact_type, contact_account]);
  // method
  const getContactDetail = async () => {
    if (params_id !== null) {
      try {
        const res = await fetchContactDetail(params_id);
        if (res.data.status === 200) {
          setResContactInfo(res.data.data);
          setContactInfo({
            ...res.data.data,
            user: res.data.data.user.id,
            creator: res.data.data.creator.id,
          });
        }
      } catch (err) {
        handleErrorResponse(err as AxiosError);
      }
    }
  };
  const transformArray = (
    data: string[]
  ): { value: string; label: string }[] => {
    return data.map((item) => ({ value: item, label: item }));
  };
  const transformArrayForType = (
    data: { type: string; is_counting: boolean }[]
  ): { value: string; label: string }[] => {
    return data.map((item) => ({ value: item.type, label: item.type }));
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (errorProperties === name) {
      setErrorProperties(null);
    }
    setContactInfo((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };
  const submit = async () => {
    if (auth === "supporter" && contactInfo.user !== id) {
      return showModal({
        type: "error",
        messages: ["다른 유저가 등록한 정보는 수정할 수 없습니다."],
      });
    }
    if (isButtonDisabled) {
      return;
    }
    let where = getEmptyProperty(contactInfo, [
      "brand",
      "language",
      "method",
      "type",
    ]);
    setErrorProperties(where);
    if (
      contactInfo.brand === "" ||
      contactInfo.language === "" ||
      contactInfo.method === "" ||
      contactInfo.type === ""
    ) {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    try {
      setIsButtonDisabled(true);
      let isOk = false;
      await new Promise<void>((resolve) => {
        showModal({
          type: "confirm",
          messages: ["컨택을 수정하시겠습니까?"],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        return;
      }
      let data = contactInfo;
      let res = await updateContact(data);
      return navigate("/contact/list/member");
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    } finally {
      setIsButtonDisabled(false);
    }
  };
  const handleSelectChange = (value: string, type: string) => {
    if (errorProperties === type) {
      setErrorProperties(null);
    }
    if (type === "brand") {
      setContactInfo((prevUserData) => ({
        ...prevUserData,
        brand: value,
      }));
    } else if (type === "language") {
      setContactInfo((prevUserData) => ({
        ...prevUserData,
        language: value,
      }));
    } else if (type === "contact_type") {
      const countingValue = metadata.contact_type.find(
        (item, index) => item.type === value
      );
      if (countingValue) {
        setContactInfo((prevUserData) => ({
          ...prevUserData,
          type: value,
          is_counting: countingValue.is_counting,
        }));
      }
    } else if (type === "contact_method") {
      setContactInfo((prevUserData) => ({
        ...prevUserData,
        method: value,
      }));
    } else if (type === "is_accept") {
      setContactInfo((prevUserData) => ({
        ...prevUserData,
        is_accept: value,
      }));
    } else if (type === "contact_account") {
      setContactInfo((prevUserData) => ({
        ...prevUserData,
        account: value,
      }));
    }
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>컨택 관리 &gt; 나의 컨택 이력 관리 &gt; 컨택 정보</h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <article className="register">
          <h3 className="contentTitle">컨택 정보</h3>
          <div className="registerBox">
            <div className="flex">
              <div>
                <p>크리에이터 ID</p>
                <input
                  type="text"
                  disabled
                  className="basic"
                  value={resContactInfo.creator?.creatorID ?? ""}
                />
              </div>
              <div>
                <p>담당자</p>
                <input
                  type="text"
                  disabled
                  value={resContactInfo.user?.username ?? ""}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>채널</p>
                <input
                  type="text"
                  disabled
                  className="basic"
                  value={resContactInfo.creator?.channel ?? ""}
                />
              </div>
              <div>
                <p>언어</p>
                <input
                  type="text"
                  disabled
                  value={resContactInfo.language ?? ""}
                />
              </div>
              <div>
                <p>구분</p>
                <input type="text" disabled value={resContactInfo.type ?? ""} />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>URL</p>
                <input
                  type="text"
                  disabled
                  className="basic"
                  value={resContactInfo.creator?.url ?? ""}
                />
              </div>
              <div>
                <p>팔로워수</p>
                <input
                  type="text"
                  disabled
                  value={resContactInfo.creator?.followerNo ?? ""}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  브랜드 <span className="red">*</span>
                </p>
                <Select
                  placeholder="브랜드"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "brand" ? "error" : ""}`}
                  value={contactInfo.brand ?? ""}
                  onChange={(value) => handleSelectChange(value, "brand")}
                  options={transformArray(metadata.brand)}
                />
              </div>
              <div>
                <p>
                  언어 <span className="red">*</span>
                </p>
                <Select
                  placeholder="언어"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "language" ? "error" : ""}`}
                  value={contactInfo.language ?? ""}
                  onChange={(value) => handleSelectChange(value, "language")}
                  options={transformArray(metadata.language)}
                />
              </div>
              <div>
                <p>
                  방법 <span className="red">*</span>
                </p>
                <Select
                  placeholder="방법"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "method" ? "error" : ""}`}
                  value={contactInfo.method ?? ""}
                  onChange={(value) =>
                    handleSelectChange(value, "contact_method")
                  }
                  options={transformArray(metadata.contact_method)}
                />
              </div>
              <div>
                <p>
                  컨택구분 <span className="red">*</span>
                </p>
                <Select
                  placeholder="컨택구분"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "type" ? "error" : ""}`}
                  value={contactInfo.type ?? ""}
                  onChange={(value) =>
                    handleSelectChange(value, "contact_type")
                  }
                  options={transformArrayForType(metadata.contact_type)}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>수락 여부</p>
                <Select
                  placeholder="수락 여부"
                  style={{
                    width: 196,
                  }}
                  value={contactInfo.is_accept}
                  onChange={(value) => handleSelectChange(value, "is_accept")}
                  options={[
                    {
                      value: "미정",
                      label: "미정",
                    },
                    {
                      value: "수락",
                      label: "수락",
                    },
                    {
                      value: "거부",
                      label: "거부",
                    },
                  ]}
                />
              </div>
              <div>
                <p>컨택계정명</p>
                <Select
                  placeholder="컨택계정명"
                  style={{
                    width: 196,
                  }}
                  value={contactInfo.account ?? ""}
                  onChange={(value) =>
                    handleSelectChange(value, "contact_account")
                  }
                  options={transformArray(metadata.contact_account)}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>비고</p>
                <input
                  type="text"
                  className="note"
                  name="note"
                  value={contactInfo.note ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="buttonWrap">
            <button className="point" onClick={submit}>
              수정
            </button>
            <Link to="/contact/list/member" className="back">
              취소
            </Link>
          </div>
        </article>
      </section>
    </>
  );
}
