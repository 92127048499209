import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Select } from "antd";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import { Metadatas, NoticeInfo } from "../../utils/type";
import { useMetadataStore, INITIAL_STATE } from "../../store/metadataStore";
import { useUserStore } from "../../store/userStore";
import showModal from "../../components/message/Modal";
import {
  createNotice,
  fetchNoticeDetail,
  updateNotice,
} from "../../api/notice";
import { getEmptyProperty, handleErrorResponse } from "../../utils/common";
import { AxiosError } from "axios";

const initialNotice = {
  customer: null,
  user: null,
  title: "",
  url: "",
  url2: "",
  url3: "",
  contents: "",
  category: "",
};

export default function NoticeRegister() {
  // data
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const noticeId = searchParams.get("id");
  const { notice_type, getMetadata } = useMetadataStore();
  const [metadata, setMetadata] = useState<Metadatas>(INITIAL_STATE);
  const { username, auth, is_superuser } = useUserStore();
  const [errorProperties, setErrorProperties] = useState<string | null>(null);
  const [notice, setNotice] = useState<NoticeInfo>(initialNotice);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // mounted
  useEffect(() => {
    if (noticeId && noticeId !== "") {
      getNoticeDetail(noticeId);
    }
  }, []);
  useEffect(() => {
    if (!(noticeId && noticeId !== "")) {
      setNotice((prevData) => ({
        ...prevData,
        user: { username: `${username}`, id: 10000 },
      }));
    }
  }, [username]);
  useEffect(() => {
    setMetadata(getMetadata());
  }, [notice_type]);
  // method
  const getNoticeDetail = async (noticeId: string) => {
    if (noticeId !== null) {
      try {
        const res = await fetchNoticeDetail(noticeId);
        if (res.data.status === 200) {
          setNotice(res.data.data);
        }
      } catch (err) {
        handleErrorResponse(err as AxiosError);
      }
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (errorProperties === name) {
      setErrorProperties(null);
    }
    setNotice((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNotice((prevData) => ({
      ...prevData,
      contents: value,
    }));
  };
  const transformArray = (
    data: string[]
  ): { value: string; label: string }[] => {
    return data.map((item) => ({ value: item, label: item }));
  };
  const handleSelectChange = (value: string, type: string) => {
    if (errorProperties === type) {
      setErrorProperties(null);
    }
    if (type === "category") {
      setNotice((prevData) => ({
        ...prevData,
        category: value,
      }));
    }
  };
  const submit = async () => {
    if (isButtonDisabled) {
      return;
    }
    let where = getEmptyProperty(notice, ["title"]);
    setErrorProperties(where);
    if (notice.title === "") {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    try {
      setIsButtonDisabled(true);
      let isOk = false;
      await new Promise<void>((resolve) => {
        showModal({
          type: "confirm",
          messages: [
            `글을 ${
              noticeId && noticeId !== "" ? "수정" : "등록"
            }하시겠습니까?`,
          ],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        return;
      }
      let data = notice;
      data.user = null;
      let res;
      if (noticeId && noticeId !== "") {
        res = await updateNotice(data);
      } else {
        res = await createNotice(data);
      }
      return navigate("/notice");
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    } finally {
      setIsButtonDisabled(false);
    }
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>
            공지사항 &gt;
            {noticeId && noticeId !== "" ? " 조회" : " 작성"}
            {noticeId &&
            noticeId !== "" &&
            (is_superuser || auth !== "supporter")
              ? "/수정"
              : null}
          </h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <article className="register">
          <div className="registerBox mt95">
            <div className="flex">
              <div>
                <p>
                  제목 <span className="red">*</span>
                </p>
                <input
                  type="text"
                  className={`large white ${
                    errorProperties === "title" ? "error" : ""
                  }`}
                  name="title"
                  value={notice.title ?? ""}
                  onChange={onChange}
                  disabled={is_superuser || auth !== "supporter" ? false : true}
                />
              </div>
              <div>
                <p>카테고리</p>
                <Select
                  disabled={is_superuser || auth !== "supporter" ? false : true}
                  placeholder="카테고리"
                  style={{
                    width: 196,
                  }}
                  value={notice.category === "" ? null : notice.category}
                  className={`${errorProperties === "category" ? "error" : ""}`}
                  onChange={(value) => handleSelectChange(value, "category")}
                  options={transformArray(metadata.notice_type)}
                />
              </div>
              <div>
                <p>담당자</p>
                <input
                  type="text"
                  disabled
                  value={notice.user?.username ? notice.user?.username : ""}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>내용</p>
                <textarea
                  disabled={is_superuser || auth !== "supporter" ? false : true}
                  placeholder="내용 작성"
                  name="contents"
                  value={notice.contents ?? ""}
                  onChange={onTextAreaChange}
                  className="white"
                ></textarea>
              </div>
            </div>
            {(is_superuser || auth !== "supporter") && (
              <div>
                <div className="flex">
                  <div>
                    <p>링크 1</p>
                    <input
                      type="text"
                      className="large"
                      name="url"
                      placeholder="https://www.example.com/"
                      value={notice.url ?? ""}
                      onChange={onChange}
                      disabled={
                        is_superuser || auth !== "supporter" ? false : true
                      }
                    />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p>링크 2</p>
                    <input
                      type="text"
                      className="large"
                      name="url2"
                      placeholder="https://www.example.com/"
                      value={notice.url2 ?? ""}
                      onChange={onChange}
                      disabled={
                        is_superuser || auth !== "supporter" ? false : true
                      }
                    />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p>링크 3</p>
                    <input
                      type="text"
                      className="large"
                      name="url3"
                      placeholder="https://www.example.com/"
                      value={notice.url3 ?? ""}
                      onChange={onChange}
                      disabled={
                        is_superuser || auth !== "supporter" ? false : true
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {!is_superuser && auth === "supporter" && (
              <div className="links">
                {notice.url ? (
                  <div className="flex">
                    <div>
                      <p className="linkArea">
                        링크:{" "}
                        <a
                          href={notice.url ?? ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {notice.url}
                        </a>
                      </p>
                    </div>
                  </div>
                ) : null}
                {notice.url2 ? (
                  <div className="flex">
                    <div>
                      <p className="linkArea">
                        링크:{" "}
                        <a
                          href={notice.url2 ?? ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {notice.url2}
                        </a>
                      </p>
                    </div>
                  </div>
                ) : null}
                {notice.url3 ? (
                  <div className="flex">
                    <div>
                      <p className="linkArea">
                        링크:{" "}
                        <a
                          href={notice.url3 ?? ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {notice.url3}
                        </a>
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
          {(is_superuser || auth !== "supporter") && (
            <div className="buttonWrap">
              <button
                className="point"
                onClick={submit}
                disabled={isButtonDisabled}
              >
                {noticeId && noticeId !== "" ? "수정" : "저장"}
              </button>
              <Link to="/notice" className="back">
                취소
              </Link>
            </div>
          )}
          {auth === "supporter" && (
            <div className="buttonWrap">
              <Link to="/notice" className="back">
                이전
              </Link>
            </div>
          )}
        </article>
      </section>
    </>
  );
}
