import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Select } from "antd";
import UserDropdownMenu from "../components/UserDropdownMenu";
import {
  UserForAdmin,
  Metadatas,
  MetaAddbject,
  UserState,
} from "../utils/type";
import showModal from "../components/message/Modal";
import { INITIAL_STATE, useMetadataStore } from "../store/metadataStore";
import { useUserStore } from "../store/userStore";
import {
  checkPassword2,
  getEmptyProperty,
  handleErrorResponse,
  roles,
} from "../utils/common";
import { fetchProfile, updateUser } from "../api";
import { AxiosError } from "axios";

const initialUser = {
  customer: null,
  username: "",
  accountId: "",
  email: "",
  password: "",
  team: "",
  phone: "",
  address: "",
  zipcode: "",
  note: "",
  brand: "",
  country: "",
  language: "",
  auth: "",
  url: "",
  confirmPassword: "",
  id: null,
  is_login: false,
  birth: null,
  createdAt: null,
  is_superuser: false,
  last_login: null,
  updatedAt: null,
};

export default function Mypage() {
  // data
  const navigate = useNavigate();
  const { language, brand, team, country, getMetadata } = useMetadataStore();
  const [metadata, setMetadata] = useState<Metadatas>(INITIAL_STATE);
  const { getUser, auth, setUser } = useUserStore();
  const [user, setUserData] = useState<UserState>(initialUser);
  const [errorProperties, setErrorProperties] = useState<string | null>(null);
  // mounted
  useEffect(() => {
    setMetadata(getMetadata());
  }, [team, language, brand, country]);
  useEffect(() => {
    setUserData(getUser());
  }, []);
  // method
  const handleCancel = () => {
    navigate(-1);
  };
  const onUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (errorProperties === name) {
      setErrorProperties(null);
    }
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };
  const handleSelectChange = (value: string, type: string) => {
    if (errorProperties === type) {
      setErrorProperties(null);
    }
    if (type === "team") {
      setUserData((prevUserData) => ({
        ...prevUserData,
        team: value,
      }));
    } else if (type === "language") {
      setUserData((prevUserData) => ({
        ...prevUserData,
        language: value,
      }));
    } else if (type === "brand") {
      setUserData((prevUserData) => ({
        ...prevUserData,
        brand: value,
      }));
    } else if (type === "auth") {
      setUserData((prevUserData) => ({
        ...prevUserData,
        auth: value,
      }));
    } else if (type === "country") {
      setUserData((prevUserData) => ({
        ...prevUserData,
        country: value,
      }));
    }
  };
  const transformArray = (
    data: string[]
  ): { value: string; label: string }[] => {
    return data.map((item) => ({ value: item, label: item }));
  };

  const submit = async () => {
    const pass = user.password?.trim();
    const confirm = user.confirmPassword?.trim();
    let where = getEmptyProperty(user, [
      "accountId",
      "username",
      "team",
      "language",
      "brand",
      "auth",
    ]);
    setErrorProperties(where);
    if (
      user.accountId === "" ||
      user.username === "" ||
      user.team === "" ||
      user.language === "" ||
      user.brand === "" ||
      user.auth === ""
    ) {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    if (pass && pass !== "") {
      if (user.password !== user.confirmPassword) {
        setErrorProperties("confirmPassword");
        return showModal({
          type: "error",
          messages: [
            "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
            "필수 입력값을 올바르게 모두 입력해주세요.",
          ],
        });
      }
      const check = checkPassword2(pass);
      if (check !== null) {
        setErrorProperties("password");
        return showModal({
          type: "error",
          messages: [
            "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
            "필수 입력값을 올바르게 모두 입력해주세요.",
          ],
        });
      }
    }
    try {
      let isOk = false;
      await new Promise<void>((resolve) => {
        showModal({
          type: "confirm",
          messages: ["내정보를 저장하시겠습니까?"],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        return;
      }
      const data: UserForAdmin = {
        id: user.id,
        customer: null,
        username: user.username,
        accountId: user.accountId,
        email: user.email,
        team: user.team,
        phone: user.phone ?? "",
        address: user.address,
        zipcode: user.zipcode,
        note: user.note ?? "",
        brand: user.brand,
        country: user.country,
        language: user.language,
        auth: user.auth,
        url: user.url ?? "",
      };
      if (pass && pass !== "") {
        data.password = pass;
      }
      let res = await updateUser(data);
      user.password = "";
      user.confirmPassword = "";
      if (res.data.status === 200) {
        const res2 = await fetchProfile();
        setUser({ is_login: true, ...res2.data.data });
        return navigate(-1);
      } else {
        if (res.data.status === 403) {
          return showModal({
            type: "error",
            messages: [res.data.message],
          });
        }
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>내 정보 관리</h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <article className="register">
          <h3 className="contentTitle">기본 정보</h3>
          <div className="registerBox">
            <div className="flex">
              <div>
                <p>
                  유저 ID <span className="red">*</span>
                </p>
                <input
                  type="text"
                  disabled
                  className={`basic ${
                    errorProperties === "accountId" ? "error" : ""
                  }`}
                  name="accountId"
                  value={user.accountId ?? ""}
                  onChange={onUserChange}
                />
              </div>
              <div>
                <p>
                  담당자 <span className="red">*</span>
                </p>
                <input
                  type="text"
                  disabled
                  className={`${errorProperties === "username" ? "error" : ""}`}
                  value={user.username ?? ""}
                  onChange={onUserChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  비밀번호 <span className="red">*</span>
                  <span className="condition"> (영문, 숫자 조합 8자 이상)</span>
                </p>
                <input
                  type="password"
                  className={`basic ${
                    errorProperties === "password" ? "error" : ""
                  }`}
                  name="password"
                  value={user.password ?? ""}
                  onChange={onUserChange}
                />
              </div>
              <div>
                <p>
                  비밀번호 확인 <span className="red">*</span>
                </p>
                <input
                  type="password"
                  className={`basic ${
                    errorProperties === "confirmPassword" ? "error" : ""
                  }`}
                  name="confirmPassword"
                  value={user.confirmPassword ?? ""}
                  onChange={onUserChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  팀 <span className="red">*</span>
                </p>
                <Select
                  disabled={auth !== "supporter" ? false : true}
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "team" ? "error" : ""}`}
                  value={user.team}
                  onChange={(value) => handleSelectChange(value, "team")}
                  options={transformArray(metadata.team)}
                />
              </div>
              <div>
                <p>
                  언어 <span className="red">*</span>
                </p>
                <Select
                  disabled={auth !== "supporter" ? false : true}
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "language" ? "error" : ""}`}
                  value={user.language}
                  onChange={(value) => handleSelectChange(value, "language")}
                  options={transformArray(metadata.language)}
                />
              </div>
              <div>
                <p>
                  브랜드 <span className="red">*</span>
                </p>
                <Select
                  disabled={auth !== "supporter" ? false : true}
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "brand" ? "error" : ""}`}
                  value={user.brand}
                  onChange={(value) => handleSelectChange(value, "brand")}
                  options={transformArray(metadata.brand)}
                />
              </div>
              <div>
                <p>
                  권한 <span className="red">*</span>
                </p>
                <Select
                  disabled={auth === "admin" ? false : true}
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "auth" ? "error" : ""}`}
                  value={user.auth}
                  onChange={(value) => handleSelectChange(value, "auth")}
                  options={roles}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>담당자 개인 이메일 주소</p>
                <input
                  type="text"
                  className="basic"
                  name="email"
                  value={user.email ?? ""}
                  onChange={onUserChange}
                />
              </div>
              <div>
                <p>담당자 전화번호</p>
                <input
                  type="text"
                  name="phone"
                  value={user.phone ?? ""}
                  onChange={onUserChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>담당자 주소</p>
                <input
                  type="text"
                  className="large"
                  name="address"
                  value={user.address ?? ""}
                  onChange={onUserChange}
                />
              </div>
              <div>
                <p>Zip Code</p>
                <input
                  type="text"
                  name="zipcode"
                  value={user.zipcode ?? ""}
                  onChange={onUserChange}
                />
              </div>
              <div>
                <p>담당자 국가</p>
                <Select
                  placeholder="국가"
                  style={{
                    width: 196,
                  }}
                  value={user.country}
                  onChange={(value) => handleSelectChange(value, "country")}
                  options={transformArray(metadata.country)}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>비고</p>
                <input
                  type="text"
                  className="note"
                  name="note"
                  value={user.note ?? ""}
                  onChange={onUserChange}
                />
              </div>
            </div>
          </div>
          <div className="buttonWrap">
            <button className="point" onClick={submit}>
              저장
            </button>
            <button className="back" onClick={handleCancel}>
              취소
            </button>
          </div>
        </article>
      </section>
    </>
  );
}
