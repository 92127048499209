import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Select, Dropdown, Space, Button } from "antd";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import { ReqReplyInfo, ResBoardInfo, replyInfo } from "../../utils/type";
import { useUserStore } from "../../store/userStore";
import dayjs from "dayjs";
import {
  createBoardReply,
  deleteBoardReplys,
  deleteBoards,
  fetchBoardDetail,
  updateBoardReplys,
} from "../../api/board";
import showModal from "../../components/message/Modal";
import { handleErrorResponse } from "../../utils/common";
import { AxiosError } from "axios";

const initialBoard = {
  customer: null,
  author: null,
  title: "",
  url: "",
  url2: "",
  url3: "",
  contents: "",
  category: "",
  replys: null,
};

export default function QnaDetail() {
  // data
  const { id, auth, is_superuser } = useUserStore();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const boardId = searchParams.get("id");
  const [board, setBoard] = useState<ResBoardInfo>(initialBoard);
  const [replys, setReplys] = useState<replyInfo[] | null>();
  const [compareReplys, setCompareReplys] = useState<replyInfo[] | null>();
  const [contents, setContents] = useState("");
  const [replyContents, setReplyContents] = useState("");
  const [statueUpdate, setStatueUpdate] = useState<number | null>(null);
  // mounted
  useEffect(() => {
    if (boardId && boardId !== "") {
      getBoardDetail(boardId);
    }
  }, []);
  // method
  const boardEvent = async (
    entityType: string,
    actionType: string,
    id: number | undefined
  ) => {
    if (entityType === "reply") {
      if (id) {
        if (actionType === "update") {
          setStatueUpdate(id);
        } else if (actionType === "delete") {
          let isOk = false;
          await new Promise<void>((resolve) => {
            showModal({
              type: "alert",
              messages: ["댓글을 삭제하시겠습니까?"],
              confirmOptions: {
                okText: "삭제",
                onOk() {
                  isOk = true;
                  resolve();
                },
                onCancel() {
                  resolve();
                },
              },
            });
          });
          if (!isOk) {
            return;
          }
          await deleteReply(`${id}`);
        } else {
          return;
        }
      }
    } else {
      if (actionType === "update") {
        return navigate(`/qna/register?id=${boardId}`);
      } else if (actionType === "delete") {
        let isOk = false;
        await new Promise<void>((resolve) => {
          showModal({
            type: "alert",
            messages: ["게시글을 삭제하시겠습니까?"],
            confirmOptions: {
              okText: "삭제",
              onOk() {
                isOk = true;
                resolve();
              },
              onCancel() {
                resolve();
              },
            },
          });
        });
        if (!isOk) {
          return;
        }
        deleteBoard();
      } else {
        return;
      }
    }
  };
  const getBoardDetail = async (boardId: string) => {
    if (boardId !== null) {
      try {
        const res = await fetchBoardDetail(boardId);
        if (res.data.status === 200) {
          setBoard(res.data.data);
          setReplys(res.data.data.replys);
          setCompareReplys([...res.data.data.replys]);
        }
      } catch (err) {
        handleErrorResponse(err as AxiosError);
      }
    }
  };
  const getBoardReplys = async (boardId: string) => {
    if (boardId !== null) {
      try {
        const res = await fetchBoardDetail(boardId);
        if (res.data.status === 200) {
          setReplys(res.data.data.replys);
          setCompareReplys([...res.data.data.replys]);
        }
      } catch (err) {
        handleErrorResponse(err as AxiosError);
      }
    }
  };
  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setContents(value);
  };

  const submit = async () => {
    if (contents.length < 1) {
      return;
    }
    const data: ReqReplyInfo = {
      contents: contents,
      boardId: Number(boardId),
    };
    try {
      const res = await createBoardReply(data);
      setContents("");
      if (res.data.status === 200) {
        if (boardId) {
          await getBoardReplys(boardId);
        }
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    } finally {
    }
  };
  const handleUpdateReply = async (index: number | undefined) => {
    if (index === undefined) {
      setStatueUpdate(null);
      setReplys(compareReplys);
    } else {
      if (replys && replys.length > 0) {
        if (replys[index].contents !== "") {
          const data = {
            id: replys[index].id,
            contents: replys[index].contents,
          };
          await updateReply(data);
        }
      }
    }
  };
  const updateReply = async (data: ReqReplyInfo) => {
    try {
      const res = await updateBoardReplys(data);
      setStatueUpdate(null);
      if (res.data.status === 200) {
        if (boardId) {
          await getBoardReplys(boardId);
        }
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };
  const deleteReply = async (id: string) => {
    try {
      const res = await deleteBoardReplys(id);
      if (res.data.status === 200) {
        if (boardId) {
          await getBoardReplys(boardId);
        }
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };
  const changeReplyContents = (idx: number, value: string) => {
    setReplys((prevReplys) => {
      const newReplys = JSON.parse(JSON.stringify(prevReplys || []));

      if (newReplys[idx]) {
        newReplys[idx].contents = value;
      }

      return newReplys;
    });
  };

  const deleteBoard = async () => {
    try {
      if (boardId) {
        const res = await deleteBoards(boardId);
        if (res.data.status === 200) {
          return navigate("/qna");
        } else {
          return;
        }
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>Q&#38;A 게시판 &gt; 조회</h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <article className="register">
          <div className="qnaBox">
            <div className="top">
              <p className="category">
                {board.category}
                {!is_superuser &&
                  auth === "supporter" &&
                  board.author?.id !== id ? (
                  <></>
                ) : (
                  <Dropdown
                    menu={{
                      items: [
                        board.author?.id === id
                          ? {
                            label: (
                              <button
                                onClick={(e) =>
                                  boardEvent("board", "update", undefined)
                                }
                              >
                                수정
                              </button>
                            ),
                            key: "1",
                          }
                          : null,
                        {
                          label: (
                            <button
                              onClick={(e) =>
                                boardEvent("board", "delete", undefined)
                              }
                            >
                              삭제
                            </button>
                          ),
                          key: "2",
                        },
                      ],
                    }}
                    trigger={["click"]}
                    className="replyDropdown"
                    overlayClassName="replyOverlay"
                  >
                    <Button>
                      <Space>
                        <span className="material-icons">more_vert</span>
                      </Space>
                    </Button>
                  </Dropdown>
                )}
              </p>
              <h2>{board.title}</h2>
              <p className="writer">
                {board.author?.username}
                <span className="date">
                  {board.createdAt
                    ? dayjs(board.createdAt).format("YYYY-MM-DD HH:mm")
                    : ""}
                </span>
              </p>
            </div>
            <pre className="qnaContents">{board.contents}</pre>
            {board.url ? <p className="linkArea">
              링크 :{" "}
              <a href={board.url ?? ""} target="_blank" rel="noreferrer">
                {board.url}
              </a>
            </p> : null}
            {board.url2 ? <p className="linkArea">
              링크 :{" "}
              <a href={board.url2 ?? ""} target="_blank" rel="noreferrer">
                {board.url2}
              </a>
            </p> : null}
            {board.url3 ? <p className="linkArea">
              링크 :{" "}
              <a href={board.url3 ?? ""} target="_blank" rel="noreferrer">
                {board.url3}
              </a>
            </p> : null}
            <div className="bottom">
              <div className="replyArea">
                <textarea
                  rows={4}
                  placeholder="답글을 입력하세요."
                  name="contents"
                  value={contents ?? ""}
                  onChange={onTextAreaChange}
                ></textarea>
                <div className="buttons">
                  <button className="point" onClick={submit}>
                    등록
                  </button>
                </div>
              </div>
              <ul className="replyList">
                {replys?.map((data, index) => (
                  <li key={index}>
                    <div className="flexB">
                      <p className="writer">
                        {data.author?.username}
                        <span className="date">
                          {data.createdAt
                            ? dayjs(data.createdAt).format("YYYY-MM-DD HH:mm")
                            : ""}
                        </span>
                      </p>
                      {!is_superuser &&
                        auth === "supporter" &&
                        data.author?.id !== id ? (
                        <></>
                      ) : statueUpdate === data.id ? (
                        <></>
                      ) : (
                        <Dropdown
                          menu={{
                            items: [
                              data.author?.id === id
                                ? {
                                  label: (
                                    <button
                                      onClick={(e) =>
                                        boardEvent("reply", "update", data.id)
                                      }
                                    >
                                      수정
                                    </button>
                                  ),
                                  key: "1",
                                }
                                : null,
                              {
                                label: (
                                  <button
                                    onClick={(e) =>
                                      boardEvent("reply", "delete", data.id)
                                    }
                                  >
                                    삭제
                                  </button>
                                ),
                                key: "2",
                              },
                            ],
                          }}
                          trigger={["click"]}
                          className="replyDropdown"
                          overlayClassName="replyOverlay"
                        >
                          <Button>
                            <Space>
                              <span className="material-icons">more_vert</span>
                            </Space>
                          </Button>
                        </Dropdown>
                      )}
                    </div>
                    {statueUpdate === data.id ? (
                      <div className="replyEdit">
                        <textarea
                          rows={4}
                          value={data.contents ?? ""}
                          onChange={(e) =>
                            changeReplyContents(index, e.target.value)
                          }
                        ></textarea>
                        <div className="buttons">
                          <button
                            className="point"
                            onClick={(e) => handleUpdateReply(index)}
                          >
                            수정
                          </button>
                          <button
                            className="back"
                            onClick={(e) => handleUpdateReply(undefined)}
                          >
                            취소
                          </button>
                        </div>
                      </div>
                    ) : (
                      <pre>{data.contents}</pre>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </article>
      </section>
    </>
  );
}
