export const creatorCsvHeaders = [
  { label: "번호", key: "id" },
  { label: "크리에이터ID", key: "creatorID" },
  { label: "담당자", key: "username" },
  { label: "채널", key: "channel" },
  { label: "언어", key: "language" },
  { label: "구분", key: "type" },
  { label: "카테고리", key: "category" },
  { label: "URL", key: "url" },
  { label: "팔로워 수", key: "followerNo" },
  { label: "크리에이터 이메일 주소", key: "email" },
  { label: "크리에이터 전화번호", key: "phone" },
  { label: "제품 배송 주소", key: "address" },
  { label: "Zip Code", key: "zipcode" },
  { label: "국가", key: "country" },
  { label: "비고", key: "note" },
  { label: "등록 날짜", key: "createdAt" },
];
export const contactCsvHeaders = [
  { label: "번호", key: "id" },
  { label: "크리에이터ID", key: "creatorID" },
  { label: "채널", key: "channel" },
  { label: "담당자", key: "username" },
  { label: "브랜드", key: "brand" },
  { label: "언어", key: "language" },
  { label: "방법", key: "method" },
  { label: "컨택구분", key: "type" },
  { label: "수락여부", key: "is_accept" },
  { label: "컨택계정명", key: "account" },
  { label: "비고", key: "note" },
  { label: "컨택 날짜", key: "createdAt" },
];
export const deliveryCsvHeaders = [
  { label: "번호", key: "productId" },
  { label: "하위 번호", key: "subId" },
  { label: "크리에이터ID", key: "creatorID" },
  { label: "채널", key: "channel" },
  { label: "담당자", key: "username" },
  { label: "언어", key: "language" },
  { label: "배송 현황", key: "progress" },
  { label: "브랜드", key: "brand" },
  { label: "제품명", key: "product" },
  { label: "ERP주문번호(IMSP코드)", key: "erp_orderNo" },
  { label: "ERP번호", key: "erpNo" },
  { label: "비고", key: "note" },
  { label: "배송 날짜", key: "createdAt" },
];
export const contentsCsvHeaders = [
  { label: "번호", key: "contentsId" },
  { label: "하위 번호", key: "subId" },
  { label: "크리에이터ID", key: "creatorID" },
  { label: "담당자", key: "username" },
  { label: "언어", key: "language" },
  { label: "콘텐츠 업로드 날짜", key: "contents_date" },
  { label: "브랜드", key: "brand" },
  { label: "제품명", key: "product" },
  { label: "조회수", key: "hits" },
  { label: "댓글", key: "comments" },
  { label: "좋아요", key: "likes" },
  { label: "저장", key: "saves" },
  { label: "URL", key: "url" },
  { label: "서포터추천", key: "recommend" },
  { label: "UGC 약관 동의", key: "ugc_agree" },
  { label: "UGC 약관 안내일", key: "ugc_agree_date" },
  { label: "비고", key: "note" },
  { label: "확인날짜", key: "createdAt" },
];
export const userCsvHeaders = [
  { label: "번호", key: "id" },
  { label: "유저ID", key: "accountId" },
  { label: "담당자", key: "username" },
  { label: "팀", key: "team" },
  { label: "언어", key: "language" },
  { label: "브랜드", key: "brand" },
  { label: "권한", key: "auth" },
  { label: "담당자 개인 이메일 주소", key: "email" },
  { label: "담당자 전화번호", key: "phone" },
  { label: "담당자 주소", key: "address" },
  { label: "Zip Code", key: "zipcode" },
  { label: "담당자 국가", key: "country" },
  { label: "비고", key: "note" },
  { label: "생성 날짜", key: "createdAt" },
];
export const logCsvHeaders = [
  { label: "활동 번호", key: "id" },
  { label: "활동 페이지", key: "contents" },
  { label: "활동", key: "type" },
  { label: "날짜", key: "createdAt" },
  { label: "담당자ID", key: "accountId" },
  { label: "담당자", key: "username" },
];
