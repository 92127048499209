import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Select } from "antd";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import { fetchCreatorDetail } from "../../api/creator";
import { DeliverytInfo, MetaAddbject, UserForUser } from "../../utils/type";
import { INITIAL_STATE, useMetadataStore } from "../../store/metadataStore";
import { useUserStore } from "../../store/userStore";
import showModal from "../../components/message/Modal";
import { createDelivery } from "../../api/delivery";
import { getEmptyProperty, handleErrorResponse } from "../../utils/common";
import { AxiosError } from "axios";

const initialCreator = {
  url: "",
  phone: "",
  note: "",
  customer: null,
  creatorID: "",
  email: "",
  address: "",
  zipcode: "",
  country: "",
  language: "",
  channel: "",
  followerNo: null,
  category: "",
  type: "",
  id: null,
  uuid: null,
};
const initialDelivery = {
  creator: null,
  user: null,
  customer: null,
  language: "",
  progress: "",
  brand: "",
  product: "",
  erpNo: "",
  erp_orderNo: "",
  note: "",
};

export default function MyDeliveryRegister2() {
  // data
  const { language, brand, delivery_progress, product, getMetadata } =
    useMetadataStore();
  const { username, id } = useUserStore();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const params_id = searchParams.get("id");
  const [creator, setCreator] = useState<UserForUser>(initialCreator);
  const [metadata, setMetadata] = useState<MetaAddbject>(INITIAL_STATE);
  const [deliveryInfo, setDeliveryInfo] =
    useState<DeliverytInfo>(initialDelivery);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAddDelivery, setIsAddDelivery] = useState(false);
  const [productOptions, setProductOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [errorProperties, setErrorProperties] = useState<string | null>(null);
  // mounted
  useEffect(() => {
    getCreatorDetail();
  }, []);
  useEffect(() => {
    setMetadata(getMetadata());
  }, [language, brand, delivery_progress, product]);
  // method
  const getCreatorDetail = async () => {
    if (params_id !== null) {
      try {
        const res = await fetchCreatorDetail(params_id);
        if (res.data.status === 200) {
          setCreator(res.data.data);
          setDeliveryInfo((prevUserData) => ({
            ...prevUserData,
            creator: res.data.data.id,
            user: id,
          }));
        }
      } catch (err) {
        handleErrorResponse(err as AxiosError);
      }
    }
  };
  const handleSelectChange = (value: string, type: string) => {
    if (errorProperties === type) {
      setErrorProperties(null);
    }
    if (type === "brand") {
      setDeliveryInfo((prevUserData) => ({
        ...prevUserData,
        brand: value,
        product: "",
      }));
      const productList = product
        .filter((item) => item.brand === value)
        .reduce<
          {
            id: string;
            name: string;
            brand: string;
          }[]
        >((acc, item) => {
          if (!acc.some((product) => product.name === item.name)) {
            acc.push(item);
          }
          return acc;
        }, [])
        .map((item) => ({ value: item.name, label: item.name }));
      setProductOptions(productList);
    } else if (type === "language") {
      setDeliveryInfo((prevUserData) => ({
        ...prevUserData,
        language: value,
      }));
    } else if (type === "progress") {
      setDeliveryInfo((prevUserData) => ({
        ...prevUserData,
        progress: value,
      }));
    } else if (type === "product") {
      setDeliveryInfo((prevUserData) => ({
        ...prevUserData,
        product: value,
      }));
    }
  };
  const transformArray = (
    data: string[]
  ): { value: string; label: string }[] => {
    return data.map((item) => ({ value: item, label: item }));
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (errorProperties === name) {
      setErrorProperties(null);
    }
    setDeliveryInfo((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };
  const submit = async (value: string) => {
    let where = getEmptyProperty(deliveryInfo, [
      "language",
      "progress",
      "brand",
      "product",
      "erp_orderNo",
    ]);
    setErrorProperties(where);
    if (
      deliveryInfo.brand === "" ||
      deliveryInfo.language === "" ||
      deliveryInfo.progress === "" ||
      deliveryInfo.product === "" ||
      deliveryInfo.erp_orderNo === ""
    ) {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    let isOk = false;
    if (value !== "save") {
      await new Promise<void>((resolve) => {
        showModal({
          type: "confirm",
          messages: [
            "입력하신 제품을 먼저 저장하신 후에 제품 추가가 가능합니다.",
            "입력하신 제품 배송을 저장하시겠습니까?",
          ],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
    } else {
      isOk = true;
    }
    if (!isOk) {
      setIsButtonDisabled(false);
      return;
    }
    const data = deliveryInfo;
    try {
      setIsButtonDisabled(true);
      let res = await createDelivery(data);
      if (res.data.status === 200) {
        if (value !== "save") {
          if (res.data.data?.productId) {
            setIsAddDelivery(true);
            setDeliveryInfo((prevUserData) => ({
              ...prevUserData,
              productId: res.data.data.productId,
              product: "",
              brand: "",
            }));
          }
        } else {
          return navigate("/delivery/list/member");
        }
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    } finally {
      setIsButtonDisabled(false);
    }
  };
  const handleCancelButton = () => {
    if (isAddDelivery) {
      return navigate("/delivery/list/member");
    } else {
      return navigate("/delivery/member/creator/list");
    }
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>
            제품 배송 관리 &gt; 나의 크리에이터 배송 등록 &gt; 배송 정보 입력
          </h1>
          <UserDropdownMenu />
        </article>
        <article className="register">
          <h3 className="contentTitle">배송 정보</h3>
          <div className="registerBox">
            <div className="flex">
              <div>
                <p>크리에이터 ID</p>
                <input
                  type="text"
                  disabled
                  className="basic"
                  value={creator.creatorID ?? ""}
                />
              </div>
              <div>
                <p>담당자</p>
                <input type="text" disabled value={username ?? ""} />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>채널</p>
                <input
                  type="text"
                  disabled
                  className="basic"
                  value={creator?.channel ?? ""}
                />
              </div>
              <div>
                <p>언어</p>
                <input type="text" disabled value={creator?.language ?? ""} />
              </div>
              <div>
                <p>구분</p>
                <input type="text" disabled value={creator?.type ?? ""} />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>URL</p>
                <input
                  type="text"
                  disabled
                  className="basic"
                  value={creator?.url ?? ""}
                />
              </div>
              <div>
                <p>팔로워수</p>
                <input type="text" disabled value={creator?.followerNo ?? ""} />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  언어 <span className="red">*</span>
                </p>
                <Select
                  disabled={isAddDelivery}
                  placeholder="언어"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "language" ? "error" : ""}`}
                  onChange={(value) => handleSelectChange(value, "language")}
                  options={transformArray(metadata.language)}
                />
              </div>
              <div>
                <p>
                  배송 현황 <span className="red">*</span>
                </p>
                <Select
                  disabled={isAddDelivery}
                  placeholder="배송 현황"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "progress" ? "error" : ""}`}
                  onChange={(value) => handleSelectChange(value, "progress")}
                  options={transformArray(metadata.delivery_progress)}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  브랜드 <span className="red">*</span>
                </p>
                <Select
                  placeholder="브랜드"
                  style={{
                    width: 196,
                  }}
                  value={deliveryInfo.brand === "" ? null : deliveryInfo.brand}
                  className={`${errorProperties === "brand" ? "error" : ""}`}
                  onChange={(value) => handleSelectChange(value, "brand")}
                  options={transformArray(metadata.brand)}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  제품 <span className="red">*</span>
                </p>
                <Select
                  placeholder="제품"
                  style={{
                    width: 628,
                  }}
                  className={`${errorProperties === "product" ? "error" : ""}`}
                  value={
                    deliveryInfo.product === "" ? null : deliveryInfo.product
                  }
                  onChange={(value) => handleSelectChange(value, "product")}
                  options={productOptions}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  ERP주문번호&#40;IMSP코드&#41; <span className="red">*</span>
                </p>
                <input
                  disabled={isAddDelivery}
                  type="text"
                  className={`basic ${
                    errorProperties === "erp_orderNo" ? "error" : ""
                  }`}
                  name="erp_orderNo"
                  value={deliveryInfo.erp_orderNo ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>ERP번호</p>
                <input
                  disabled={isAddDelivery}
                  type="text"
                  className="basic"
                  name="erpNo"
                  value={deliveryInfo.erpNo ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>비고</p>
                <input
                  type="text"
                  className="note"
                  disabled={isAddDelivery}
                  name="note"
                  value={deliveryInfo.note ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="buttonWrap">
            <button
              className="point"
              onClick={(e) => submit("add")}
              disabled={isButtonDisabled}
            >
              제품 추가
            </button>
            <button
              className="point"
              onClick={(e) => submit("save")}
              disabled={isButtonDisabled}
            >
              저장
            </button>
            <button className="back" onClick={handleCancelButton}>
              취소
            </button>
          </div>
        </article>
      </section>
    </>
  );
}
