import React, { ChangeEvent, useState, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { Dropdown, Space, Button } from "antd";
import UserLnb from "../components/UserLnb";
import AdminLnb from "../components/AdminLnb";
import { fetchMetadata } from "../api/metadata";
import { useMetadataStore, INITIAL_STATE } from "../store/metadataStore";
import { useCustomerStore } from "../store/customerStore";
import { useUserStore } from "../store/userStore";
import showModal from "../components/message/Modal";
import { deleteLogo, fetchCustomerDetail, uploadLogo } from "../api/customer";
import { AxiosError } from "axios";
import { handleErrorResponse } from "../utils/common";

const DefaultLayout = () => {
  const { setMetadata } = useMetadataStore();
  const { auth, is_superuser } = useUserStore();
  const [isImageOver, setIsImageOver] = useState(false);
  const { id, logo, setCustomer } = useCustomerStore();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isLogo, setIsLogo] = useState(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    getMetadata();
  }, []);
  useEffect(() => {
    if (logo && logo.image !== "") {
      setIsLogo(true);
      const img = new Image();
      img.onload = () => {
        const ratio = (img.height / img.width) * 100;
        if (ratio > 23.33) {
          setIsImageOver(true);
        }
      };
      img.src = logo.image;
    } else {
      setIsLogo(false);
    }
  }, [logo]);

  const getMetadata = async () => {
    try {
      let res = await fetchMetadata();
      if (res && res.data && res.data?.data) {
        setMetadata(res.data?.data ?? INITIAL_STATE);
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };
  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  const bannerChange = (type: string) => {
    if (type === "update") {
      handelFile();
    } else {
      handleDeleteLogo();
    }
  };
  const items = [
    {
      label: <button onClick={(e) => bannerChange("update")}>수정</button>,
      key: "1",
    },
    {
      label: <button onClick={(e) => bannerChange("delete")}>삭제</button>,
      key: "2",
    },
  ];
  const menuProps = {
    items,
  };
  const handelFile = () => {
    fileInputRef.current?.click();
  };
  const handleDeleteLogo = async () => {
    try {
      if (logo?.id) {
        const res = await deleteLogo(logo?.id);
        if (res.data.status === 200) {
          let res2 = await fetchCustomerDetail(`${id}`);
          setCustomer(res2.data.data);
          setIsImageOver(false);
        }
      }
    } catch (err) {
      return showModal({
        type: "error",
        messages: ["업로드에 실패했습니다."],
      });
    }
  };
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const fileSize = files[0].size;
      const maxSize = 5 * 1024 * 1024;
      if (fileSize > maxSize) {
        return showModal({
          type: "error",
          messages: ["파일 크기는 5MB를 초과할 수 없습니다."],
        });
      }

      const formData = new FormData();
      formData.append("customer", `${id}`);
      formData.append("file", files[0]);
      try {
        const res = await uploadLogo(formData);
        if (res.data.status === 200) {
          let res2 = await fetchCustomerDetail(`${id}`);
          setCustomer(res2.data.data);
          setIsImageOver(false);
        }
      } catch (err) {
        return showModal({
          type: "error",
          messages: ["업로드에 실패했습니다."],
        });
      }
    }
  };
  return (
    <div className="contWrap flexB">
      <div className="lnb">
        <div>
          <div className="top">
            <div className="lnbLogo">
              <input
                style={{ display: "none" }}
                type="file"
                name="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              {!isLogo ? (
                <label htmlFor="file">
                  <button className="addLogo" onClick={handelFile}>
                    로고를 추가하세요
                    <img src="/images/icon/plus.png" alt="추가 이모티콘" />
                  </button>
                </label>
              ) : (
                <div
                  className="img"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    className={`${isImageOver ? "contain" : "cover"}`}
                    src={(logo && logo.image) ?? ""}
                    alt="logo"
                  />
                  <div
                    className={`dropBox ${dropdownVisible ? "visible" : ""}`}
                  >
                    {auth !== "supporter" && (
                      <Dropdown
                        menu={menuProps}
                        className="logoDropdown"
                        overlayClassName="logoOverlay"
                      >
                        <Button>
                          <Space>
                            <img
                              src="/images/icon/dropdown.png"
                              alt="이모티콘"
                            />
                          </Space>
                        </Button>
                      </Dropdown>
                    )}
                  </div>
                </div>
              )}
            </div>
            <article>
              <p className="authority">For User</p>
              <UserLnb />
            </article>
          </div>
          {(is_superuser || auth === "admin") && (
            <article className="bottom">
              <p className="authority">For Admin</p>
              <AdminLnb />
            </article>
          )}
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default DefaultLayout;
