import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Select } from "antd";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import { Metadatas, ReqBoardInfo } from "../../utils/type";
import { useMetadataStore, INITIAL_STATE } from "../../store/metadataStore";
import { useUserStore } from "../../store/userStore";
import showModal from "../../components/message/Modal";
import { createBoard, fetchBoardDetail, updateBoard } from "../../api/board";
import { handleErrorResponse } from "../../utils/common";
import { AxiosError } from "axios";
const initialBoard = {
  customer: null,
  author: null,
  title: "",
  contents: "",
  category: "",
  url: "",
  url2: "",
  url3: "",
};

export default function QnaRegister() {
  // data
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const boardId = searchParams.get("id");
  const { qna_type, getMetadata } = useMetadataStore();
  const [metadata, setMetadata] = useState<Metadatas>(INITIAL_STATE);
  const { username, auth } = useUserStore();
  const [errorProperties, setErrorProperties] = useState<string | null>(null);
  const [board, setBoard] = useState<ReqBoardInfo>(initialBoard);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // mounted
  useEffect(() => {
    if (boardId && boardId !== "") {
      getBoardDetail(boardId);
    }
  }, []);
  useEffect(() => {
    setMetadata(getMetadata());
  }, [qna_type]);
  useEffect(() => {
    if (!(boardId && boardId !== "")) {
      setBoard((prevData) => ({
        ...prevData,
        author: { username: `${username}`, id: 10000 },
      }));
    }
  }, [username]);
  // method
  const getBoardDetail = async (boardId: string) => {
    if (boardId !== null) {
      try {
        const res = await fetchBoardDetail(boardId);
        if (res.data.status === 200) {
          setBoard(res.data.data);
        }
      } catch (err) {
        handleErrorResponse(err as AxiosError);
      }
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (errorProperties === name) {
      setErrorProperties(null);
    }
    setBoard((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const transformArray = (
    data: string[]
  ): { value: string; label: string }[] => {
    return data.map((item) => ({ value: item, label: item }));
  };
  const handleSelectChange = (value: string, type: string) => {
    if (errorProperties === type) {
      setErrorProperties(null);
    }
    if (type === "category") {
      setBoard((prevData) => ({
        ...prevData,
        category: value,
      }));
    }
  };
  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setBoard((prevData) => ({
      ...prevData,
      contents: value,
    }));
  };
  const submit = async () => {
    if (isButtonDisabled) {
      return;
    }
    if (board.title === "") {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }

    try {
      setIsButtonDisabled(true);
      let isOk = false;
      await new Promise<void>((resolve) => {
        showModal({
          type: "confirm",
          messages: [
            `글을 ${boardId && boardId !== "" ? "수정" : "등록"}하시겠습니까?`,
          ],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        return;
      }
      let res;
      if (boardId && boardId !== "") {
        let data: ReqBoardInfo = {
          customer: null,
          id: Number(boardId),
          title: board.title,
          contents: board.contents,
          category: board.category,
          url: board.url,
          url2: board.url2,
          url3: board.url3,
        };
        res = await updateBoard(data);
        if (res.data.status === 200) {
          return navigate(`/qna/detail?id=${boardId}`);
        }
      } else {
        let data: ReqBoardInfo = {
          customer: null,
          title: board.title,
          contents: board.contents,
          category: board.category,
          url: board.url,
          url2: board.url2,
          url3: board.url3,
        };
        res = await createBoard(data);
      }
      return navigate(`/qna`);
    } catch (error) {
      return showModal({
        type: "error",
        messages: ["다시 한번 확인해주세요."],
      });
    } finally {
      setIsButtonDisabled(false);
    }
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>
            Q&#38;A 게시판 &gt;
            {boardId && boardId !== "" ? " 수정" : " 작성"}
          </h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <article className="register">
          <div className="registerBox mt95">
            <div className="flex">
              <div>
                <p>
                  제목 <span className="red">*</span>
                </p>
                <input
                  type="text"
                  className={`large ${errorProperties === "title" ? "error" : ""
                    }`}
                  name="title"
                  value={board.title ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>카테고리</p>
                <Select
                  placeholder="카테고리"
                  style={{
                    width: 196,
                  }}
                  value={board.category === "" ? null : board.category}
                  className={`${errorProperties === "category" ? "error" : ""}`}
                  onChange={(value) => handleSelectChange(value, "category")}
                  options={transformArray(metadata.qna_type)}
                />
              </div>
              <div>
                <p>담당자</p>
                <input
                  type="text"
                  disabled
                  value={board.author?.username ? board.author?.username : ""}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>내용</p>
                <textarea
                  placeholder="내용 작성"
                  name="contents"
                  value={board.contents ?? ""}
                  onChange={onTextAreaChange}
                ></textarea>
              </div>
            </div>
            <div>
              <div className="flex">
                <div>
                  <p>링크</p>
                  <input
                    type="text"
                    className="large"
                    name="url"
                    placeholder="https://www.example.com/"
                    value={board.url ?? ""}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="flex">
                <div>
                  <p>링크</p>
                  <input
                    type="text"
                    className="large"
                    name="url2"
                    placeholder="https://www.example.com/"
                    value={board.url2 ?? ""}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="flex">
                <div>
                  <p>링크</p>
                  <input
                    type="text"
                    className="large"
                    name="url3"
                    placeholder="https://www.example.com/"
                    value={board.url3 ?? ""}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="buttonWrap">
            <button
              className="point"
              onClick={submit}
              disabled={isButtonDisabled}
            >
              {boardId && boardId !== "" ? "수정" : "저장"}
            </button>

            <Link to="/qna" className="back">
              취소
            </Link>
          </div>
        </article>
      </section >
    </>
  );
}
