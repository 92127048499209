import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Pagination } from "antd";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import dayjs from "dayjs";
import showModal from "../../components/message/Modal";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { PaginationProps } from "antd";
import {
  FilterCheck,
  Filter_group,
  ParamsProps,
  ResDeliverytInfo,
  SortInfo,
} from "../../utils/type";
import { deleteDelivery, fetchDeliveryUserList } from "../../api/delivery";
import FilterModal from "../../components/pop/FilterModal";
import {
  filterNonEmptyValues,
  handleErrorResponse,
  processParams,
} from "../../utils/common";
import { AxiosError } from "axios";

export default function MyDelivery() {
  // data
  const [checkedList, setCheckedList] = useState<boolean[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [deliveryList, setDeliveryList] = useState<ResDeliverytInfo[]>([]);
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortInfo, setSortInfo] = useState<SortInfo[]>([]);
  const [searchWord, setSearchWord] = useState("");
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const resetData = {
    product: [],
    channel: [],
    language: [],
    progress: [],
  };
  const [filterList, setFilterList] = useState<Filter_group>(resetData);
  const [filterCheckList, setFilterCheckList] =
    useState<FilterCheck>(resetData);
  const [searchforceUpdate, setForceUpdate] = useState(0);
  // mounted
  useEffect(() => {
    setForceUpdate((prevNumber) => prevNumber + 1);
    let parmas = {};
    getDeliveryList(parmas);
  }, []);
  useEffect(() => {
    setSortInfo([]);
    setFilterList(resetData);
    setFilterCheckList(resetData);
  }, [searchforceUpdate]);
  useEffect(() => {
    getFilter();
  }, [filterCheckList]);
  // method
  const getIconForCustomerName = (orderType: string) => {
    const customerNameSortInfo = sortInfo.find(
      (item) => item.orderType === orderType
    );

    if (customerNameSortInfo) {
      return customerNameSortInfo.sortOrder;
    }
    return "desc";
  };
  const generateOrderString = (orderList: SortInfo[]) => {
    const result = orderList
      .map((order) => {
        const sortOrderSymbol = order.sortOrder === "desc" ? "-" : "";
        return sortOrderSymbol + order.orderType;
      })
      .join(",");

    return result;
  };
  const toggleSortOrder = (currentSortOrder: "asc" | "desc"): "asc" | "desc" =>
    currentSortOrder === "asc" ? "desc" : "asc";
  const handleOrderType = async (orderType: string) => {
    const targetSortInfo = sortInfo.find(
      (info) => info.orderType === orderType
    );

    const newSortInfo = targetSortInfo
      ? sortInfo.map((info) =>
          info.orderType === orderType
            ? { orderType, sortOrder: toggleSortOrder(info.sortOrder) }
            : info
        )
      : [{ orderType, sortOrder: "asc" as "asc" | "desc" }];

    setSortInfo(newSortInfo);
    let params = {
      page,
      searchWord,
      orderType: generateOrderString(newSortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getDeliveryList(params);
  };
  const getDeliveryList = async (paramsData: ParamsProps) => {
    const params = processParams(paramsData);
    try {
      let res = await fetchDeliveryUserList(params);
      if (res.data.status === 200) {
        setDeliveryList(res.data.data);
        setCheckedList(Array(res.data.data.length).fill(false));
        setPage(res.data.page);
        setTotal(res.data.total);
        setFilterList(res.data.filter_group);
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };
  const onCheckBoxChange = (e: CheckboxChangeEvent, idx: number) => {
    const updatedCheckedList = [...checkedList];
    updatedCheckedList[idx] = e.target.checked;
    setCheckedList(updatedCheckedList);
    const allChecked = updatedCheckedList.every((item) => item);
    setCheckAll(allChecked);
  };
  const onCheckBoxAllChange = (e: CheckboxChangeEvent) => {
    const updatedCheckedList = checkedList.map(() => e.target.checked);
    setCheckAll(e.target.checked);
    setCheckedList(updatedCheckedList);
  };
  const handleSearch = async () => {
    setForceUpdate((prevNumber) => prevNumber + 1);
    let params = {
      page: 1,
      searchWord,
    };
    await getDeliveryList(params);
  };
  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setForceUpdate((prevNumber) => prevNumber + 1);
      let params = {
        page: 1,
        searchWord,
      };
      await getDeliveryList(params);
    }
  };
  const handlePagination: PaginationProps["onChange"] = async (
    pageNum,
    pageSize
  ) => {
    const params = {
      page: pageNum,
      searchWord,
      orderType: generateOrderString(sortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getDeliveryList(params);
  };
  const deleteDeliverys = async () => {
    if (isButtonDisabled) {
      return;
    }
    if (!checkedList.some((item) => item)) {
      return showModal({
        type: "error",
        messages: ["하나 이상 선택해주세요."],
      });
    }
    try {
      setIsButtonDisabled(true);
      let isOk = false;
      await new Promise<void>((resolve) => {
        showModal({
          type: "alert",
          messages: ["선택한 배송을 정말 삭제하시겠습니까?"],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        setIsButtonDisabled(false);
        return;
      }
      let indexs = getTrueIndices(checkedList);
      const ids: number[] = indexs.map(
        (index) => deliveryList[index]?.id as number
      );
      const filters = filterNonEmptyValues(filterCheckList);
      let deleteParams: {
        ids?: number[];
        filters?: FilterCheck;
        select_all?: boolean;
      } = {};
      if (isAllSelected) {
        // 선택된 필터가 있을 경우에는 필터를 적용하고, 없을 경우에는 전체 선택만 적용
        deleteParams.select_all = isAllSelected;
        if (Object.keys(filters).length > 0) {
          deleteParams.filters = filters;
        }
      } else {
        deleteParams.ids = ids;
      }
      let res = await deleteDelivery(deleteParams);
      if (res.data.status !== 200) {
        return showModal({
          type: "error",
          messages: ["다시 한번 확인해주세요."],
        });
      } else {
        let params = {
          page,
          searchWord,
        };
        await getDeliveryList(params);
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    } finally {
      setIsButtonDisabled(false);
      setCheckAll(false);
    }
  };
  const getTrueIndices = (list: boolean[]) => {
    const indices: number[] = [];
    list.forEach((value, index) => {
      if (value) {
        indices.push(index);
      }
    });
    return indices;
  };
  const handleApply = (list: string[], type: string) => {
    setFilterCheckList((prevUserData) => ({
      ...prevUserData,
      [type]: list,
    }));
  };
  const getFilter = async () => {
    let params = {
      page: 1,
      searchWord: searchWord,
      orderType: generateOrderString(sortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getDeliveryList(params);
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>제품 배송 관리 &gt; 나의 배송 이력 관리</h1>
          <UserDropdownMenu />
        </article>
        <article className="manage">
          <div className="searchBox">
            <div>
              <button>
                <img
                  src="/images/icon/search.png"
                  alt="검색 이모티콘"
                  onClick={handleSearch}
                />
              </button>
              <input
                type="text"
                placeholder="검색어를 입력해주세요."
                name="searchWord"
                onChange={(e) => setSearchWord(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <button
              className="pointOut"
              onClick={deleteDeliverys}
              disabled={isButtonDisabled}
            >
              선택 삭제
            </button>
          </div>
          {deliveryList?.length > 0 && (
            <div className="totalCount">전체 행 개수: {total}개 </div>
          )}
          {checkAll && (
            <div className="allSelectedBox">
              {isAllSelected ? (
                <>
                  전체 항목 {total}개가 모두 선택되었습니다.
                  <button
                    className="primary"
                    onClick={() => setIsAllSelected(false)}
                  >
                    이 페이지에 있는 항목 {checkedList.length}개만 선택
                  </button>
                </>
              ) : (
                <>
                  이 페이지에 있는 항목 {checkedList.length}개가 모두
                  선택되었습니다.
                  <button
                    className="primary"
                    onClick={() => {
                      setIsAllSelected(true);
                    }}
                  >
                    전체 항목 {total}개 모두 선택
                  </button>
                </>
              )}
            </div>
          )}
          <div className="tableBox">
            <table>
              <colgroup>
                <col style={{ width: "4%" }} />
                <col style={{ width: "6%" }} />
                <col style={{ width: "7%" }} />
                <col style={{ width: "19%" }} />
                <col style={{ width: "17%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "13%" }} />
                <col style={{ width: "14%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th className="check">
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckBoxAllChange}
                      checked={checkAll}
                    ></Checkbox>
                  </th>
                  <th>번호</th>
                  <th>하위 번호</th>
                  <th>
                    <div className="flexB filtering">
                      제품명
                      <div className="flex">
                        <button onClick={(e) => handleOrderType("product")}>
                          {getIconForCustomerName("product") === "asc" ? (
                            <img src="/images/icon/up.png" alt="이모티콘" />
                          ) : (
                            <img src="/images/icon/down.png" alt="이모티콘" />
                          )}
                        </button>
                        <FilterModal
                          handleApply={(data) => handleApply(data, "product")}
                          list={filterList.product ?? []}
                          changeFirst={searchforceUpdate}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="flexB filtering">크리에이터ID</div>
                  </th>
                  <th>
                    <div className="flexB filtering">
                      채널
                      <div className="flex">
                        <button onClick={(e) => handleOrderType("channel")}>
                          {getIconForCustomerName("channel") === "asc" ? (
                            <img src="/images/icon/up.png" alt="이모티콘" />
                          ) : (
                            <img src="/images/icon/down.png" alt="이모티콘" />
                          )}
                        </button>
                        <FilterModal
                          handleApply={(data) => handleApply(data, "channel")}
                          list={filterList.channel ?? []}
                          changeFirst={searchforceUpdate}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="flexB filtering">
                      언어
                      <div className="flex">
                        <button onClick={(e) => handleOrderType("language")}>
                          {getIconForCustomerName("language") === "asc" ? (
                            <img src="/images/icon/up.png" alt="이모티콘" />
                          ) : (
                            <img src="/images/icon/down.png" alt="이모티콘" />
                          )}
                        </button>
                        <FilterModal
                          handleApply={(data) => handleApply(data, "language")}
                          list={filterList.language ?? []}
                          changeFirst={searchforceUpdate}
                        />
                      </div>
                    </div>
                  </th>

                  <th>
                    <div className="flexB filtering">
                      배송 현황
                      <div className="flex">
                        <button onClick={(e) => handleOrderType("progress")}>
                          {getIconForCustomerName("progress") === "asc" ? (
                            <img src="/images/icon/up.png" alt="이모티콘" />
                          ) : (
                            <img src="/images/icon/down.png" alt="이모티콘" />
                          )}
                        </button>
                        <FilterModal
                          handleApply={(data) => handleApply(data, "progress")}
                          list={filterList.progress ?? []}
                          changeFirst={searchforceUpdate}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="flexB filtering">
                      배송 날짜
                      <div className="flex">
                        <button onClick={(e) => handleOrderType("createdAt")}>
                          {getIconForCustomerName("createdAt") === "asc" ? (
                            <img src="/images/icon/up.png" alt="이모티콘" />
                          ) : (
                            <img src="/images/icon/down.png" alt="이모티콘" />
                          )}
                        </button>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {deliveryList.map((data, index) => (
                  <tr key={index}>
                    <td className="check">
                      <Checkbox
                        checked={checkedList[index]}
                        onChange={(e) => onCheckBoxChange(e, index)}
                      />
                    </td>
                    <td>
                      <Link to={`/delivery/list/member/info?id=${data.id}`}>
                        {data.productId}
                      </Link>
                    </td>
                    <td>{data.subId}</td>
                    <td className="double">
                      <span>{data.product}</span>
                    </td>
                    <td>{data.creator?.creatorID ?? ""}</td>
                    <td>{data.creator?.channel ?? ""}</td>
                    <td>{data.language}</td>

                    <td>{data.progress}</td>
                    <td>
                      {data.createdAt
                        ? dayjs(data.createdAt).format("YYYY-MM-DD")
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <Pagination
              showSizeChanger={false}
              current={page}
              total={total}
              pageSize={pageSize}
              onChange={handlePagination}
            />
          </div>
        </article>
      </section>
    </>
  );
}
