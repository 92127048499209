import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

interface ChartComponentProps {
  chartOptions: ApexCharts.ApexOptions;
  chartSeries: ApexAxisChartSeries;
}

export default function ChartComponent({
  chartOptions,
  chartSeries,
}: ChartComponentProps) {
  return (
    <ReactApexChart
      options={chartOptions}
      series={chartSeries}
      type="line"
      height={550}
    />
  );
}
