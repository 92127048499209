import { AxiosError, AxiosResponse } from "axios";
import { FilterCheck, ParamsProps } from "./type";
import showModal from "../components/message/Modal";

export function emailValidate(email: string) {
  const exptext = /^[\w\.-]+@[\w\.-]+\.\w+/;
  if (exptext.test(email) === false) {
    return false;
  }
  return true;
}

export function comma(str: number | null) {
  const result = String(str);
  return result.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
}

export const checkPassword = (val: string): string | null => {
  let pw = val;
  let num = pw.search(/[0-9]/g);
  let eng = pw.search(/[a-z]/gi);
  let spe = pw.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

  if (pw.length < 8 || pw.length > 12) {
    return "비밀번호는 8자리에서 12자리 사이로 입력해주세요.";
  } else if (pw.search(/\s/) !== -1) {
    return "비밀번호는 공백 없이 입력해주세요.";
  } else if (num < 0 || eng < 0 || spe < 0) {
    return "영어, 숫자, 특수문자를 하나 이상 입력해주세요.";
  } else {
    return null;
  }
};
export const checkPassword2 = (val: string): string | null => {
  let pw = val;
  let num = pw.search(/[0-9]/g);
  let eng = pw.search(/[a-zA-Z]/g);

  if (pw.length < 8) {
    return "비밀번호는 8자리 이상으로 입력해주세요.";
  } else if (pw.search(/\s/) !== -1) {
    return "비밀번호는 공백 없이 입력해주세요.";
  } else if (num < 0 || eng < 0) {
    return "영어와 숫자를 모두 포함하여 입력해주세요.";
  } else {
    return null;
  }
};

export const convertToInteger = (number: number | undefined): number | null => {
  if (number !== undefined) {
    const dataCheck = number.toString();
    const modifiedData = dataCheck.replace(/^0+(?=[1-9])/, "");
    const result = parseInt(modifiedData);
    return isNaN(result) || result < 0 ? null : result;
  } else {
    return null;
  }
};

export const getEmptyProperty = (
  data: any,
  properties: string[]
): string | null => {
  for (const property of properties) {
    if (data[property] === "" || data[property] === null) {
      return property;
    }
  }
  return null;
};
export const areObjectsEqual = (
  obj1: any,
  obj2: any,
  excludedProperties: string[] = []
): boolean => {
  const keys1 = Object.keys(obj1).filter((key) =>
    excludedProperties.includes(key)
  );
  const keys2 = Object.keys(obj2).filter((key) =>
    excludedProperties.includes(key)
  );
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

export const roles = [
  {
    value: "admin",
    label: "관리자",
  },
  {
    value: "manager",
    label: "매니저",
  },
  {
    value: "supporter",
    label: "서포터",
  },
];
export const processParams = (paramsData: ParamsProps) => {
  const params: ParamsProps = {};
  for (const key in paramsData) {
    if (
      paramsData[key] !== null &&
      paramsData[key] !== "" &&
      paramsData[key] !== undefined
    ) {
      if (typeof paramsData[key] === "object") {
        if (Object.keys(paramsData[key]).length !== 0) {
          params[key] = JSON.stringify(paramsData[key]);
        }
      } else {
        params[key] = paramsData[key];
      }
    }
  }
  return params;
};
export const filterNonEmptyValues = (obj: FilterCheck): FilterCheck => {
  const filteredObj: FilterCheck = {};
  for (const key in obj) {
    if (obj[key].length > 0) {
      filteredObj[key] = obj[key];
    }
  }
  return filteredObj;
};

export const parseNumberOrString = (
  value: string | number
): number | string => {
  const parsedValue: number = parseFloat(String(value));
  return isNaN(parsedValue) ? value : parsedValue;
};
export const handleErrorResponse = (err: AxiosError) => {
  const { response } = err;
  const responseError = response?.data as { message: string };
  if (response && response?.status > 399 && response?.status < 500) {
    if (response?.status !== 401 && responseError?.message) {
      return showModal({
        type: "error",
        messages: [responseError.message],
      });
    }
  } else {
    return showModal({
      type: "error",
      messages: ["서버 오류 입니다. 서비스 관리자에게 문의해 주세요."],
    });
  }
};

// 백엔드에서 에러 여부와 상관 없이 응답 자체는 200으로 하고 있으므로, 에러 처리를 위한 별도의 응답 함수
export const handleAxiosResponse = (
  response: AxiosResponse,
  onSuccess: () => void
) => {
  const {
    data: { status, message },
  } = response;
  if (status === 200) {
    onSuccess();
  } else {
    return showModal({
      type: "error",
      messages: [message],
    });
  }
};
