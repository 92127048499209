import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Select } from "antd";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import { UserForAdmin, Metadatas } from "../../utils/type";
import showModal from "../../components/message/Modal";
import { createUser } from "../../api/index";
import { useMetadataStore, INITIAL_STATE } from "../../store/metadataStore";
import {
  checkPassword2,
  emailValidate,
  getEmptyProperty,
  handleErrorResponse,
  roles,
} from "../../utils/common";
import { AxiosError } from "axios";

const initialUser = {
  customer: null,
  username: "",
  accountId: "",
  email: "",
  password: "",
  team: "",
  phone: "",
  address: "",
  zipcode: "",
  note: "",
  brand: "",
  country: "",
  language: "",
  auth: "",
  url: "",
  confirmPassword: "",
};

export default function UserRegister() {
  // data
  const { language, brand, team, country, getMetadata } = useMetadataStore();
  const [user, setUser] = useState<UserForAdmin>(initialUser);
  const navigate = useNavigate();
  const [metadata, setMetadata] = useState<Metadatas>(INITIAL_STATE);
  const [localTeam, setLocalTeam] = useState<string[] | null>(null);
  const [localLanguage, setLocalLanguage] = useState<string[] | null>(null);
  const [localBrand, setLocalBrand] = useState<string[] | null>(null);
  const [localCountry, setLocalCountry] = useState<string[] | null>(null);
  const [errorProperties, setErrorProperties] = useState<string | null>(null);
  // mounted
  useEffect(() => {}, []);
  useEffect(() => {
    setMetadata(getMetadata());
  }, [team, language, brand, country]);

  // method
  const submit = async () => {
    let where = getEmptyProperty(user, [
      "accountId",
      "username",
      "password",
      "accountId",
      "confirmPassword",
      "team",
      "language",
      "brand",
      "auth",
    ]);
    setErrorProperties(where);
    if (
      user.accountId === "" ||
      user.username === "" ||
      user.password === "" ||
      user.accountId === "" ||
      user.confirmPassword === "" ||
      user.team === "" ||
      user.language === "" ||
      user.brand === "" ||
      user.auth === ""
    ) {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    if (user.accountId && !emailValidate(user.accountId)) {
      setErrorProperties("accountId");
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    if (user.password) {
      const check = checkPassword2(user.password);
      if (check !== null) {
        setErrorProperties("password");
        return showModal({
          type: "error",
          messages: [
            "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
            "필수 입력값을 올바르게 모두 입력해주세요.",
          ],
        });
      }
    }
    if (user.password !== user.confirmPassword) {
      setErrorProperties("confirmPassword");
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    try {
      let isOk = false;
      await new Promise<void>((resolve) => {
        showModal({
          type: "confirm",
          messages: ["유저를 생성하시겠습니까?"],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        return;
      }
      let data = user;
      delete data.confirmPassword;
      let res = await createUser(data);
      return navigate("/admin/user");
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (errorProperties === name) {
      setErrorProperties(null);
    }
    setUser((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };
  const onTeamChange = (value: string) => {
    if (errorProperties === "team") {
      setErrorProperties(null);
    }
    setUser((prevUserData) => ({
      ...prevUserData,
      team: value,
    }));
  };
  const onLanguageChange = (value: string) => {
    if (errorProperties === "language") {
      setErrorProperties(null);
    }
    setUser((prevUserData) => ({
      ...prevUserData,
      language: value,
    }));
  };
  const onBrandChange = (value: string) => {
    if (errorProperties === "brand") {
      setErrorProperties(null);
    }
    setUser((prevUserData) => ({
      ...prevUserData,
      brand: value,
    }));
  };
  const onAuthChange = (value: string) => {
    if (errorProperties === "auth") {
      setErrorProperties(null);
    }
    setUser((prevUserData) => ({
      ...prevUserData,
      auth: value,
    }));
  };
  const onCountryChange = (value: string) => {
    setUser((prevUserData) => ({
      ...prevUserData,
      country: value,
    }));
  };
  const transformArray = (
    data: string[]
  ): { value: string; label: string }[] => {
    return data.map((item) => ({ value: item, label: item }));
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>유저 관리 &gt; 생성</h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <article className="register">
          <h3 className="contentTitle">기본 정보</h3>
          <div className="registerBox">
            <div className="flex">
              <div>
                <p>
                  유저 ID <span className="red">*</span>
                  <span className="condition"> (이메일)</span>
                </p>
                <input
                  type="text"
                  className={`basic ${
                    errorProperties === "accountId" ? "error" : ""
                  }`}
                  name="accountId"
                  value={user.accountId ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>
                  담당자 <span className="red">*</span>
                </p>
                <input
                  type="text"
                  className={`${errorProperties === "username" ? "error" : ""}`}
                  name="username"
                  value={user.username ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  비밀번호 <span className="red">*</span>
                  <span className="condition"> (영문, 숫자 조합 8자 이상)</span>
                </p>
                <input
                  type="password"
                  className={`basic ${
                    errorProperties === "password" ? "error" : ""
                  }`}
                  name="password"
                  value={user.password ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>
                  비밀번호 확인 <span className="red">*</span>
                </p>

                <input
                  type="password"
                  className={`basic ${
                    errorProperties === "confirmPassword" ? "error" : ""
                  }`}
                  name="confirmPassword"
                  value={user.confirmPassword ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  팀 <span className="red">*</span>
                </p>
                <Select
                  placeholder="팀"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "team" ? "error" : ""}`}
                  onChange={onTeamChange}
                  options={transformArray(metadata.team)}
                />
              </div>
              <div>
                <p>
                  언어 <span className="red">*</span>
                </p>
                <Select
                  placeholder="언어"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "language" ? "error" : ""}`}
                  onChange={onLanguageChange}
                  options={transformArray(metadata.language)}
                />
              </div>
              <div>
                <p>
                  브랜드 <span className="red">*</span>
                </p>
                <Select
                  placeholder="브랜드"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "brand" ? "error" : ""}`}
                  onChange={onBrandChange}
                  options={transformArray(metadata.brand)}
                />
              </div>
              <div>
                <p>
                  권한 <span className="red">*</span>
                </p>
                <Select
                  placeholder="권한"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "auth" ? "error" : ""}`}
                  onChange={onAuthChange}
                  options={roles}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>담당자 개인 이메일 주소</p>
                <input
                  type="text"
                  className="basic"
                  name="email"
                  value={user.email ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>담당자 전화번호</p>
                <input
                  type="text"
                  name="phone"
                  value={user.phone ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>담당자 주소</p>
                <input
                  type="text"
                  className="large"
                  name="address"
                  value={user.address ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>Zip Code</p>
                <input
                  type="text"
                  name="zipcode"
                  value={user.zipcode ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>담당자 국가</p>
                <Select
                  placeholder="국가"
                  style={{
                    width: 196,
                  }}
                  onChange={onCountryChange}
                  options={transformArray(metadata.country)}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>비고</p>
                <input
                  type="text"
                  className="note"
                  name="note"
                  value={user.note ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="buttonWrap">
            <button className="point" onClick={submit}>
              저장
            </button>
            <Link to="/admin/user" className="back">
              취소
            </Link>
          </div>
        </article>
      </section>
    </>
  );
}
