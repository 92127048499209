import { headers } from "../config/env";
import Axios from "../config/axios";
import {
  BulkDeleteProps,
  ExcelCreatorProps,
  ParamsProps,
  UserForUser,
  UserForUserWithUserId,
  updateCreatorsWithUserId,
} from "../utils/type";
import { useCustomerStore } from "../store/customerStore";
import { createSearchParams } from "../utils/requestUtils";

export async function fetchMyCreatorList(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/creator/list/user`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchAllCreatorList(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/creator/list/all`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchAllCreatorListForAdmin(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/creator/list/user/update/list`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchCreatorListExcel(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  const searchParams = createSearchParams(params);
  return await Axios.get(`/creator/list/excel`, {
    headers: headers(localStorage.getItem("accesstoken")!),
    responseType: "blob",
    params: searchParams,
  });
}
export async function fetchCreatorDetail(id: string) {
  return await Axios.get(`/creator/${id}`, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function deleteCreators({
  ids,
  select_all,
  filters,
}: BulkDeleteProps) {
  return await Axios.put(
    `/creator/delete`,
    { customer_id: useCustomerStore.getState().id!, ids, select_all, filters },
    {
      headers: headers(localStorage.getItem("accesstoken")!),
    }
  );
}

export async function createCreator(data: UserForUser) {
  data.customer = useCustomerStore.getState().id!;
  return await Axios.post("/creator", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

export async function createBulkCreator(data: ExcelCreatorProps[]) {
  const bulk = {
    data,
    customer: useCustomerStore.getState().id!,
  };
  return await Axios.post("/creator/bulk", bulk, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

export async function updateCreator(data: UserForUserWithUserId) {
  return await Axios.put("/creator", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function updateCreatorBulk(data: updateCreatorsWithUserId) {
  return await Axios.put("/creator/user/bulk/update", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
// // // 컨택 이력
export async function fetchContactCreator(id: string, params: ParamsProps) {
  return await Axios.get(`/contact/creator/${id}`, {
    params: params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
// // // 제품배송 이력
export async function fetchDeliveryCreator(id: string, params: ParamsProps) {
  return await Axios.get(`/delivery/creator/${id}`, {
    params: params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
// // // 콘텐츠 이력
export async function fetchContetsCreator(id: string, params: ParamsProps) {
  return await Axios.get(`/contents/creator/${id}`, {
    params: params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function downloadBulkCreatorUploadTemplate() {
  return await Axios.get(`/creator/bulk/template`, {
    headers: headers(localStorage.getItem("accesstoken")!),
    responseType: "blob",
  });
}
