import React, { useState, useEffect } from "react";
import Chart from "./RootChart";
import {
  ChartComponentProps,
  ChartSeriesDataItem,
  ChartXaxisDataItem,
} from "../../utils/type";

export default function ChartComponent({ xaxis, series }: ChartComponentProps) {
  // data
  const [chartOptions, setChartOptions] = useState<ApexCharts.ApexOptions>({
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    colors: [
      "#e60049",
      "#0bb4ff",
      "#50e991",
      "#e6d800",
      "#9b19f5",
      "#ffa200",
      "#dc0ab4",
      "#b2d4ff",
      "#00bfa0",
      "#fd7f6f",
      "#A4629F",
      "#39799F",
      "#EEB540",
      "#83B660",
      "#52AFAE",
      "#E38938",
      "#6F5B9B",
      "#319167",
      "#CE6154",
    ],
    markers: {
      size: 4,
    },
    stroke: {
      width: 3,
    },
    legend: {
      show: true,
      position: "bottom",
      showForSingleSeries: true,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toLocaleString();
        },
      },
    },
    xaxis: {
      tickAmount: 10,
      categories: [],
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value.toLocaleString();
        },
      },
    },
  });
  const [chartSeries, setChartSeries] = useState([
    {
      name: "",
      data: [0],
    },
  ]);

  // mounted
  useEffect(() => {
    if (series?.length > 0) {
      setChartSeries(series);
    }
  }, [series]);
  useEffect(() => {
    if (xaxis?.length > 0) {
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          categories: xaxis,
        },
      }));
    }
  }, [xaxis]);
  // method
  return (
    <div className="chart">
      <Chart chartOptions={chartOptions} chartSeries={chartSeries} />
    </div>
  );
}
