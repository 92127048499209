import React from "react";

interface MessageProps {
  messages: string[];
}

const ConfirmationMessage = ({ messages }: MessageProps) => (
  <div className="flex">
    <img src="/images/icon/check.png" alt="이모티콘" />
    <div>
      {messages.map((message, index) => (
        <p key={index}>{message}</p>
      ))}
    </div>
  </div>
);

export default ConfirmationMessage;
