export function createSearchParams<T extends object>(
  params: T
): URLSearchParams {
  const searchParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    const value = params[key as keyof T];

    if (value === null || value === undefined) {
      return;
    }

    if (typeof value === "object") {
      const jsonString = JSON.stringify(value);
      searchParams.append(key, jsonString);
    } else {
      searchParams.append(key, value.toString());
    }
  });

  return searchParams;
}
