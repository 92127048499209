import React, { useEffect, useState } from "react";
import { Select, Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import {
  ParamsProps,
  ResDashboardPerformance,
  SortInfo,
} from "../../utils/type";
import FilterModal from "../pop/FilterModal";
import showModal from "../message/Modal";
import { fetchDashboardPerformance } from "../../api/dashborad";
import dayjs, { Dayjs } from "dayjs";
import { handleErrorResponse, processParams } from "../../utils/common";
import { AxiosError } from "axios";
export default function ResultState() {
  // data
  const periodOptions = [
    {
      label: "오늘",
      value: "today",
    },
    {
      label: "어제",
      value: "yesterday",
    },
    {
      label: "1주일",
      value: "week",
    },
    {
      label: "1개월",
      value: "month",
    },
    {
      label: "전체",
      value: "all",
    },
  ];
  const typeOption = [
    {
      value: "username",
      label: "담당자",
    },
    {
      value: "team",
      label: "팀",
    },
    {
      value: "channel",
      label: "채널",
    },
    {
      value: "language",
      label: "언어",
    },
    {
      value: "brand",
      label: "브랜드",
    },
    {
      value: "country",
      label: "배송 국가",
    },
  ];
  const [type, setType] = useState("username");
  const [period, setPeriod] = useState("all");
  const [sortInfo, setSortInfo] = useState<SortInfo[]>([]);
  const [filterList, setFilterList] = useState(["a", "b"]);
  const [filterCheckList, setFilterCheckList] = useState<string[]>([]);
  const [list, setList] = useState<ResDashboardPerformance[]>([]);
  const [sum, setSum] = useState<ResDashboardPerformance>();
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  // mounted
  useEffect(() => {
    let params = {
      type: "username",
    };
    getDashboardPerformance(params);
  }, []);
  // method
  const handleSelectChange = async (value: string, type: string) => {
    setType(value);
    let params = {
      type: value,
      startDate:
        startDate == null ? null : dayjs(startDate).format("YYYY-MM-DD"),
      endDate: startDate == null ? null : dayjs(endDate).format("YYYY-MM-DD"),
    };
    await getDashboardPerformance(params);
  };
  const handlePeriodChange = async (e: RadioChangeEvent) => {
    const value = e.target.value;
    setPeriod(e.target.value);
    const date: string[] = setDate(value);
    let params = {
      type: type,
      startDate: date[0],
      endDate: date[0],
    };
    await getDashboardPerformance(params);
  };
  const setDate = (value: string) => {
    switch (value) {
      case "today":
        setStartDate(dayjs());
        setEndDate(dayjs());
        return [
          dayjs(dayjs()).format("YYYY-MM-DD"),
          dayjs(dayjs()).format("YYYY-MM-DD"),
        ];
      case "yesterday":
        setStartDate(dayjs().subtract(1, "day"));
        setEndDate(dayjs());
        return [
          dayjs(dayjs().subtract(1, "day")).format("YYYY-MM-DD"),
          dayjs(dayjs()).format("YYYY-MM-DD"),
        ];
      case "week":
        setStartDate(dayjs().subtract(7, "day"));
        setEndDate(dayjs());
        return [
          dayjs(dayjs().subtract(7, "day")).format("YYYY-MM-DD"),
          dayjs(dayjs()).format("YYYY-MM-DD"),
        ];
      case "month":
        setStartDate(dayjs().subtract(1, "month"));
        setEndDate(dayjs());
        return [
          dayjs(dayjs().subtract(1, "month")).format("YYYY-MM-DD"),
          dayjs(dayjs()).format("YYYY-MM-DD"),
        ];
      case "all":
        setStartDate(null);
        setEndDate(null);
        return [];
      default:
        setStartDate(null);
        setEndDate(null);
        return [];
    }
  };
  const getIconForCustomerName = (orderType: string) => {
    const customerNameSortInfo = sortInfo.find(
      (item) => item.orderType === orderType
    );

    if (customerNameSortInfo) {
      return customerNameSortInfo.sortOrder;
    }
    return "desc";
  };
  const generateOrderString = (orderList: SortInfo[]) => {
    const result = orderList
      .map((order) => {
        const sortOrderSymbol = order.sortOrder === "desc" ? "-" : "";
        return sortOrderSymbol + order.orderType;
      })
      .join(",");

    return result;
  };
  const toggleSortOrder = (currentSortOrder: "asc" | "desc"): "asc" | "desc" =>
    currentSortOrder === "asc" ? "desc" : "asc";
  const handleOrderType = async (orderType: string) => {
    const targetSortInfo = sortInfo.find(
      (info) => info.orderType === orderType
    );

    const newSortInfo = targetSortInfo
      ? sortInfo.map((info) =>
          info.orderType === orderType
            ? { orderType, sortOrder: toggleSortOrder(info.sortOrder) }
            : info
        )
      : [{ orderType, sortOrder: "asc" as "asc" | "desc" }];
    setSortInfo(newSortInfo);
    let params = {
      type: type,
      startDate:
        startDate == null ? null : dayjs(startDate).format("YYYY-MM-DD"),
      endDate: startDate == null ? null : dayjs(endDate).format("YYYY-MM-DD"),
      orderType: generateOrderString(newSortInfo),
    };
    await getDashboardPerformance(params);
  };
  const handleApply = (list: string[], type: string) => {
    setFilterCheckList(list);
  };

  const getDashboardPerformance = async (paramsData: ParamsProps) => {
    const params = processParams(paramsData);
    try {
      const res = await fetchDashboardPerformance(params);

      if (res.data.status === 200) {
        setList(res.data.data);
        setSum(res.data.sum);
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };
  return (
    <article className="resultState">
      <div className="box">
        <p className="tit">항목별 성과 현황</p>
        <div className="sorting flex">
          <div className="flex">
            <p>항목 기준:</p>
            <Select
              style={{
                width: 124,
              }}
              value={type}
              onChange={(value) => handleSelectChange(value, "username")}
              options={typeOption}
            />
          </div>
          <div className="flex">
            <p>기간:</p>
            <Radio.Group
              options={periodOptions}
              onChange={handlePeriodChange}
              value={period}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
        </div>
        <div className="tableBox">
          <table>
            <colgroup></colgroup>
            <thead>
              <tr>
                <th>
                  <div className="flexB filtering">
                    {typeOption.find((item) => item.value === type)?.label}
                  </div>
                </th>
                <th>서치</th>
                <th>컨택</th>
                <th>제품 배송</th>
                <th>콘텐츠</th>
                <th className="bold">합계</th>
              </tr>
            </thead>
            <tbody>
              {list.map((data, index) => (
                <tr key={index}>
                  <td>{data[type]}</td>
                  <td>{data.creator ?? "-"}</td>
                  <td>{data.contact ?? "-"}</td>
                  <td>{data.delivery ?? "-"}</td>
                  <td>{data.contents ?? "-"}</td>
                  <td className="bold">
                    {(data.creator ?? 0) +
                      (data.contact ?? 0) +
                      (data.delivery ?? 0) +
                      (data.contents ?? 0)}
                  </td>
                </tr>
              ))}
              <tr className="bold">
                <td>합계</td>
                <td>{sum?.creator ?? "-"}</td>
                <td>{sum?.contact ?? "-"}</td>
                <td>{sum?.delivery ?? "-"}</td>
                <td>{sum?.contents ?? "-"}</td>
                <td className="bold">
                  {sum &&
                    (sum?.creator ?? 0) +
                      (sum?.contact ?? 0) +
                      (sum?.delivery ?? 0) +
                      (sum?.contents ?? 0)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
  );
}
