import React, { useState } from "react";
import { Modal } from "antd";
import * as XLSX from "xlsx";
import { ExcelCreatorProps, ExcelItemProps } from "../../utils/type";
import { parseNumberOrString } from "../../utils/common";
import showModal from "../message/Modal";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  updateData: (data: ExcelItemProps[]) => void;
}

export default function ItemModal({ isOpen, closeModal, updateData }: Props) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const isExcelFile = (file: File) => {
    const fileExts = [".xlsx", ".xls"];
    const fileName = file.name;
    const fileExt = fileName.slice(fileName.lastIndexOf(".")).toLowerCase();
    return fileExts.includes(fileExt);
  };
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (!file) return;
    if (isExcelFile(file[0]) && file[0].size !== 0) {
      const reader = new FileReader();
      reader.readAsBinaryString(file[0]);
      reader.onload = (e) => {
        if (e.target?.result) {
          const result = e.target.result;
          const workbook = XLSX.read(result, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, {
            raw: false,
            defval: null,
          });
          let data: ExcelItemProps[] = [];
          jsonData.forEach((item: any, i: number) => {
            let dump: ExcelItemProps = {
              id: item["제품 번호"],
              name: item["제품명"],
              brand: item["제품 브랜드"],
            };
            data.push(dump);
          });
          submit(data);
        }
      };
      e.target.value = "";
    } else {
      return showModal({
        type: "error",
        messages: ["파일 양식을 확인해주세요. '.xlsx', '.xls' 만 가능합니다."],
      });
    }
  };
  const submit = async (data: ExcelItemProps[]) => {
    if (isButtonDisabled) {
      return;
    }
    const invalidItem = data.find((item: ExcelItemProps) => {
      return (
        item.id === undefined ||
        item.name === undefined ||
        item.brand === undefined
      );
    });
    if (invalidItem) {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    updateData(data);
  };
  return isOpen ? (
    <section className="modal">
      <div className="box">
        <ul>
          <li>
            <h2>Step1. 엑셀 양식 다운로드</h2>
            <p>
              아래 &#91;엑셀 양식 다운로드&#93; 버튼을 클릭하여 양식을 다운 받아
              주세요.
            </p>
            <a
              href="https://evernbucket.s3.ap-northeast-2.amazonaws.com/%E1%84%8C%E1%85%A6%E1%84%91%E1%85%AE%E1%86%B7_CRYSBE.xlsx"
              download
              className="pointOut"
            >
              엑셀 양식 다운로드
            </a>
          </li>
          <li>
            <h2>Step2. 엑셀 양식 작성</h2>
            <p>
              엑셀 양식에 내용을 작성해주세요. 작성시 아래 유의사항을 반드시
              지켜주세요.
            </p>
            <ul>
              <li>모든 입력 필드는 반드시 모두 입력해주세요</li>
              <li>이미 등록되어 있는 제품번호는 등록이 불가합니다.</li>
              <li>
                위 사항들을 반영하지 않는 엑셀 파일들은 업로드가 불가능합니다.
              </li>
            </ul>
          </li>
          <li>
            <h2>Step3. 엑셀 업로드</h2>
            <p>
              아래 &#91;업로드&#93; 버튼을 클릭하여 크리에이터 다수 등록을
              완료해주세요.
            </p>
            <label htmlFor="ex_file">
              <button className="point">업로드</button>
              <input type="file" id="ex_file" onChange={handleFile} />
            </label>
            <button className="back" onClick={closeModal}>
              취소
            </button>
          </li>
        </ul>
      </div>
    </section>
  ) : null;
}
