import React, { useState } from "react";
import { Modal } from "antd";
import showModal from "../message/Modal";
import { changePassword, createAuthByEmail, verifyAuth } from "../../api";
import { checkPassword2, handleErrorResponse } from "../../utils/common";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
const VerificationConfirmation = () => (
  <div className="flex">
    <div>
      <p>인증번호를 이메일로 발송하였습니다.</p>
    </div>
  </div>
);
const ErrorMessage = () => (
  <div className="flex">
    <img src="/images/icon/error.png" alt="이모티콘" />
    <div>
      <p>잘못된 인증번호입니다. 다시 입력해주세요.</p>
    </div>
  </div>
);

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

export default function FindPasswordModal({ isOpen, closeModal }: Props) {
  // data
  const navigate = useNavigate();
  const [accountId, setAccountId] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
  const [verifyNumber, setVerifyNumber] = useState<string | null>(null);
  const [step, setStep] = useState(1);
  const [errorProperties, setErrorProperties] = useState<string | null>(null);
  const [errorProperties2, setErrorProperties2] = useState<string | null>(null);
  // mounted
  // mehtod
  const submit = () => {
    showModal({
      type: "normal",
      messages: ["인증번호를 이메일로 발송하였습니다."],
    });
  };
  const dataReset = () => {
    setAccountId(null);
    setPassword(null);
    setConfirmPassword(null);
    setVerifyNumber(null);
  };
  const handleCancel = () => {
    closeModal();
    dataReset();
    setStep(1);
  };
  const handleNext = () => {
    setStep(2);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "accountId") {
      setAccountId(value);
    } else if (name === "verifyNumber") {
      setVerifyNumber(value);
    } else if (name === "password") {
      setPassword(value);
      const result = checkPassword2(value);
      if (value && result === null) {
        setErrorProperties("password");
      } else {
        setErrorProperties(null);
      }
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };
  const sendAuthNumber = async () => {
    if (!accountId || accountId === "") {
      return showModal({
        type: "error",
        messages: ["다시 한번 확인해주세요."],
      });
    }
    const data = {
      accountId: accountId,
    };
    try {
      const res = await createAuthByEmail(data);
      if (res.data.status === 200) {
        showModal({
          type: "normal",
          messages: ["인증번호를 이메일로 발송하였습니다."],
        });
      } else {
        showModal({
          type: "error",
          messages: [res.data.message],
        });
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };
  const verifyAuthNumber = async () => {
    if (
      !accountId ||
      accountId === "" ||
      !verifyNumber ||
      verifyNumber === ""
    ) {
      return showModal({
        type: "error",
        messages: ["다시 한번 확인해주세요."],
      });
    }
    const data = {
      key: verifyNumber,
    };
    try {
      const res = await verifyAuth(data);
      if (res.data.status === 200) {
        handleNext();
      } else {
        setVerifyNumber(null);
        return showModal({
          type: "error",
          messages: ["잘못된 인증번호입니다. 다시 입력해주세요."],
        });
      }
    } catch (err) {
      setVerifyNumber(null);
      return showModal({
        type: "error",
        messages: ["잘못된 인증번호입니다. 다시 입력해주세요."],
      });
    }
  };
  const handleSubmit = async () => {
    if (
      !accountId ||
      accountId === "" ||
      !verifyNumber ||
      verifyNumber === "" ||
      !password ||
      password === "" ||
      !confirmPassword ||
      confirmPassword === "" ||
      password !== confirmPassword
    ) {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    const check = checkPassword2(password);
    if (check !== null) {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    const data = {
      accountId: accountId,
      key: verifyNumber,
      password: password,
    };
    try {
      const res = await changePassword(data);
      if (res.data.status === 200) {
        handleCancel();
        navigate("/password/change/done");
      } else {
        return showModal({
          type: "error",
          messages: [
            "비밀번호 변경하기 오류입니다.",
            "다시 한번 확인해주세요.",
          ],
        });
      }
    } catch (err) {
      return showModal({
        type: "error",
        messages: ["비밀번호 변경하기 오류입니다.", "다시 한번 확인해주세요."],
      });
    }
  };
  return isOpen ? (
    <section className="modal">
      <article className="modalBox">
        {step === 1 && (
          <div>
            <div className="modal_header">
              <h2>비밀번호 찾기</h2>
            </div>
            <div className="modal_body">
              <div className="inputBox">
                <p>
                  계정(이메일) <span className="red">*</span>
                </p>
                <input
                  type="text"
                  name="accountId"
                  placeholder="abc@crysbe.com"
                  value={accountId ?? ""}
                  onChange={onChange}
                />
                <button onClick={sendAuthNumber}>인증 번호 발송</button>
              </div>
              <div className="inputBox">
                <p>인증번호 입력</p>
                <input
                  type="text"
                  name="verifyNumber"
                  value={verifyNumber ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="buttonWrap">
              <button className="point" onClick={verifyAuthNumber}>
                확인
              </button>
              <button className="gray" onClick={closeModal}>
                취소
              </button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div>
            <div className="modal_header">
              <h2>비밀번호 변경하기</h2>
              <p>최초 로그인시 비밀번호를 변경해주세요(필수).</p>
            </div>
            <div className="modal_body">
              <div className="inputBox">
                <p>
                  비밀번호 <span className="red">*</span>
                  <span className="condition"> (영문, 숫자 조합 8자 이상)</span>
                </p>
                <div className="flex">
                  <input
                    className={
                      password &&
                      password.length > 0 &&
                      errorProperties !== "password"
                        ? "error"
                        : ""
                    }
                    type="password"
                    name="password"
                    value={password ?? ""}
                    onChange={onChange}
                  />
                  {password &&
                    password.length > 0 &&
                    errorProperties === "password" && (
                      <span className="safe flex">
                        <img src="/images/icon/check.png" alt="이모티콘" />
                        안전한 비밀번호 입니다.
                      </span>
                    )}
                </div>
                <p></p>
              </div>
              <div className="inputBox">
                <p>
                  비밀번호 확인 <span className="red">*</span>
                </p>
                <input
                  type="password"
                  className={
                    confirmPassword &&
                    confirmPassword.length > 0 &&
                    password !== confirmPassword
                      ? "error"
                      : ""
                  }
                  name="confirmPassword"
                  value={confirmPassword ?? ""}
                  onChange={onChange}
                />
                {confirmPassword && password !== confirmPassword && (
                  <p className="error message">
                    입력하신 비밀번호와 일치하지 않습니다.
                  </p>
                )}
              </div>
            </div>
            <div className="buttonWrap">
              <button className="point" onClick={handleSubmit}>
                저장
              </button>
              <button className="gray" onClick={handleCancel}>
                취소
              </button>
            </div>
          </div>
        )}
      </article>
    </section>
  ) : null;
}
