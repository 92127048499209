import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Pagination, Tabs, Select } from "antd";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import { UserForAdmin, Metadatas } from "../../utils/type";
import { useMetadataStore, INITIAL_STATE } from "../../store/metadataStore";
import showModal from "../../components/message/Modal";
import { fetchProfile, fetchUserDetail, updateUser } from "../../api/index";
import SearchHistory from "../../components/user/SearchHistory";
import ContactHistory from "../../components/user/ContactHistory";
import ContentsHistory from "../../components/user/ContentsHistory";
import DeliveryHistory from "../../components/user/DeliveryHistory";
import LogHistory from "../../components/user/LogHistory";
import { useUserStore } from "../../store/userStore";
import {
  getEmptyProperty,
  handleErrorResponse,
  roles,
} from "../../utils/common";
import { AxiosError } from "axios";

const initialUser = {
  customer: null,
  username: "",
  accountId: "",
  email: "",
  password: "",
  team: "",
  phone: "",
  address: "",
  zipcode: "",
  note: "",
  brand: "",
  country: "",
  language: "",
  auth: "",
  url: "",
  confirmPassword: "",
  id: null,
};

const { TabPane } = Tabs;
export default function UserManage() {
  // data
  const navigate = useNavigate();
  const { id, uuid, setUser } = useUserStore();
  const [title, setTitle] = useState("기본 정보");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("id");
  const { language, brand, team, country, getMetadata } = useMetadataStore();
  const [user, setUserData] = useState<UserForAdmin>(initialUser);
  const [metadata, setMetadata] = useState<Metadatas>(INITIAL_STATE);
  const [errorProperties, setErrorProperties] = useState<string | null>(null);
  const [forceUpdate, setForceUpdate] = useState<number>(0);
  // mounted
  useEffect(() => {
    getUserDetail();
  }, []);
  useEffect(() => {
    setMetadata(getMetadata());
  }, [team, language, brand, country]);
  // method
  const onChange = (key: string) => {
    setForceUpdate((prevNumber) => prevNumber + 1);
    if (key === "1") {
      setTitle("기본 정보");
    } else if (key === "2") {
      setTitle("서치 이력");
    } else if (key === "3") {
      setTitle("컨택 이력");
    } else if (key === "4") {
      setTitle("제품 배송 이력");
    } else if (key === "5") {
      setTitle("콘텐츠 이력");
    } else if (key === "6") {
      setTitle("활동 로그");
    } else {
      setTitle("");
    }
  };
  const getUserDetail = async () => {
    if (userId !== null) {
      try {
        const res = await fetchUserDetail(userId);
        if (res.data.status === 200) {
          setUserData(res.data.data);
        }
      } catch (err) {
        handleErrorResponse(err as AxiosError);
      }
    }
  };

  const onUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (errorProperties === name) {
      setErrorProperties(null);
    }
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };
  const onTeamChange = (value: string) => {
    if (errorProperties === "team") {
      setErrorProperties(null);
    }
    setUserData((prevUserData) => ({
      ...prevUserData,
      team: value,
    }));
  };
  const onLanguageChange = (value: string) => {
    if (errorProperties === "language") {
      setErrorProperties(null);
    }
    setUserData((prevUserData) => ({
      ...prevUserData,
      language: value,
    }));
  };
  const onBrandChange = (value: string) => {
    if (errorProperties === "brand") {
      setErrorProperties(null);
    }
    setUserData((prevUserData) => ({
      ...prevUserData,
      brand: value,
    }));
  };
  const onAuthChange = (value: string) => {
    if (errorProperties === "auth") {
      setErrorProperties(null);
    }
    setUserData((prevUserData) => ({
      ...prevUserData,
      auth: value,
    }));
  };
  const onCountryChange = (value: string) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      country: value,
    }));
  };
  function transformArray(data: string[]): { value: string; label: string }[] {
    return data.map((item) => ({ value: item, label: item }));
  }
  const submit = async () => {
    let where = getEmptyProperty(user, [
      "accountId",
      "username",
      "accountId",
      "team",
      "language",
      "brand",
      "auth",
    ]);
    setErrorProperties(where);
    if (
      user.accountId === "" ||
      user.username === "" ||
      user.accountId === "" ||
      user.team === "" ||
      user.language === "" ||
      user.brand === "" ||
      user.auth === ""
    ) {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    try {
      let isOk = false;
      await new Promise<void>((resolve) => {
        showModal({
          type: "confirm",
          messages: ["유저를 수정하시겠습니까?"],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        return;
      }
      let data = user;
      let res = await updateUser(data);
      if (res.data.status === 200) {
        if (userId === uuid) {
          const res2 = await fetchProfile();
          setUser({ is_login: true, ...res2.data.data });
          if (res2.data.data.auth !== "admin") {
            return navigate("/dashboard");
          }
        }
        return navigate("/admin/user");
      } else {
        if (res.data.status === 403) {
          return showModal({
            type: "error",
            messages: ["하나 이상의 관리자가 필요합니다."],
          });
        } else {
          return showModal({
            type: "error",
            messages: [res.data.message],
          });
        }
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>유저 관리 &gt; 조회/수정 &gt; {title}</h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <Tabs defaultActiveKey="1" onChange={onChange} className="tabBoard">
          <TabPane tab="기본 정보" key="1">
            <article className="register">
              <div className="registerBox">
                <div className="flex">
                  <div>
                    <p>
                      유저 ID <span className="red">*</span>
                    </p>
                    <input
                      type="text"
                      className={`basic ${
                        errorProperties === "accountId" ? "error" : ""
                      }`}
                      name="accountId"
                      value={user.accountId ?? ""}
                      onChange={onUserChange}
                      disabled
                    />
                  </div>
                  <div>
                    <p>
                      담당자 <span className="red">*</span>
                    </p>
                    <input
                      type="text"
                      name="username"
                      className={`${
                        errorProperties === "username" ? "error" : ""
                      }`}
                      value={user.username ?? ""}
                      onChange={onUserChange}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p>
                      팀 <span className="red">*</span>
                    </p>
                    <Select
                      placeholder="팀"
                      style={{
                        width: 196,
                      }}
                      className={`${errorProperties === "team" ? "error" : ""}`}
                      value={user.team}
                      onChange={onTeamChange}
                      options={transformArray(metadata.team)}
                    />
                  </div>
                  <div>
                    <p>
                      언어 <span className="red">*</span>
                    </p>
                    <Select
                      placeholder="언어"
                      style={{
                        width: 196,
                      }}
                      className={`${
                        errorProperties === "language" ? "error" : ""
                      }`}
                      value={user.language}
                      onChange={onLanguageChange}
                      options={transformArray(metadata.language)}
                    />
                  </div>
                  <div>
                    <p>
                      브랜드 <span className="red">*</span>
                    </p>
                    <Select
                      placeholder="브랜드"
                      style={{
                        width: 196,
                      }}
                      className={`${
                        errorProperties === "brand" ? "error" : ""
                      }`}
                      value={user.brand}
                      onChange={onBrandChange}
                      options={transformArray(metadata.brand)}
                    />
                  </div>
                  <div>
                    <p>
                      권한 <span className="red">*</span>
                    </p>
                    <Select
                      placeholder="권한"
                      style={{
                        width: 196,
                      }}
                      className={`${errorProperties === "auth" ? "error" : ""}`}
                      value={user.auth}
                      onChange={onAuthChange}
                      options={roles}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p>담당자 개인 이메일 주소</p>
                    <input
                      type="text"
                      className="basic"
                      name="email"
                      value={user.email ?? ""}
                      onChange={onUserChange}
                    />
                  </div>
                  <div>
                    <p>담당자 전화번호</p>
                    <input
                      type="text"
                      name="phone"
                      value={user.phone ?? ""}
                      onChange={onUserChange}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p>담당자 주소</p>
                    <input
                      type="text"
                      className="large"
                      name="address"
                      value={user.address ?? ""}
                      onChange={onUserChange}
                    />
                  </div>
                  <div>
                    <p>Zip Code</p>
                    <input
                      type="text"
                      name="zipcode"
                      value={user.zipcode ?? ""}
                      onChange={onUserChange}
                    />
                  </div>
                  <div>
                    <p>담당자 국가</p>
                    <Select
                      placeholder="국가"
                      style={{
                        width: 196,
                      }}
                      value={user.country}
                      onChange={onCountryChange}
                      options={transformArray(metadata.country)}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p>비고</p>
                    <input
                      type="text"
                      className="note"
                      name="note"
                      value={user.note ?? ""}
                      onChange={onUserChange}
                    />
                  </div>
                </div>
              </div>
              <div className="buttonWrap">
                <button className="point" onClick={submit}>
                  수정
                </button>
                <Link to="/admin/user" className="back">
                  뒤로
                </Link>
              </div>
            </article>
          </TabPane>
          <TabPane tab="서치 이력" key="2">
            <article className="manage">
              <SearchHistory id={`${user.id}`} forceUpdate={forceUpdate} />
            </article>
          </TabPane>
          <TabPane tab="컨택 이력" key="3">
            <article className="manage">
              <ContactHistory id={`${user.id}`} forceUpdate={forceUpdate} />
            </article>
          </TabPane>
          <TabPane tab="제품 배송 이력" key="4">
            <article className="manage">
              <DeliveryHistory id={`${user.id}`} forceUpdate={forceUpdate} />
            </article>
          </TabPane>
          <TabPane tab="콘텐츠 이력" key="5">
            <article className="manage">
              <ContentsHistory id={`${user.id}`} forceUpdate={forceUpdate} />
            </article>
          </TabPane>
          <TabPane tab="활동 로그" key="6">
            <article className="manage">
              <LogHistory id={`${user.id}`} forceUpdate={forceUpdate} />
            </article>
          </TabPane>
        </Tabs>
      </section>
    </>
  );
}
