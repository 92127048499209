import React, { useState } from "react";
import { Select, DatePicker, Radio } from "antd";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import Creator from "../../components/download/Creator";
import Contact from "../../components/download/Contact";
import Delivery from "../../components/download/Delivery";
import Contents from "../../components/download/Contents";
import User from "../../components/download/User";
import ActivityLog from "../../components/download/ActivityLog";
import locale from "antd/es/date-picker/locale/ko_KR";
import type { RadioChangeEvent } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import dayjs, { Dayjs } from "dayjs";
import { CsvHeaderProps, FilterCheck, ParamsProps } from "../../utils/type";
import { fetchCreatorListExcel } from "../../api/creator";
import CsvDownloader from "../../components/csv/CsvDownloader";
import {
  contactCsvHeaders,
  contentsCsvHeaders,
  creatorCsvHeaders,
  deliveryCsvHeaders,
  logCsvHeaders,
  userCsvHeaders,
} from "../../utils/csvHeaders";
import { fetchContactListExcel } from "../../api/contact";
import { fetchDeliveryListExcel } from "../../api/delivery";
import { fetchContentsListExcel } from "../../api/contents";
import { fetchUserListExcel } from "../../api";
import { fetchLogListExcel } from "../../api/dashborad";
import { roles } from "../../utils/common";
import handleDownload from "../../utils/useDownload";
const { RangePicker } = DatePicker;

export default function Download() {
  // data
  const periodOptions = [
    {
      label: "오늘",
      value: "today",
    },
    {
      label: "어제",
      value: "yesterday",
    },
    {
      label: "1주일",
      value: "week",
    },
    {
      label: "1개월",
      value: "month",
    },
    {
      label: "전체",
      value: "all",
    },
  ];
  const selectOptions = [
    {
      value: "creator",
      label: "크리에이터",
    },
    {
      value: "contact",
      label: "컨택",
    },
    {
      value: "delivery",
      label: "배송",
    },
    {
      value: "contents",
      label: "콘텐츠",
    },
    {
      value: "user",
      label: "유저",
    },
    {
      value: "log",
      label: "활동로그",
    },
  ];
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState<FilterCheck>({});
  const [period, setPeriod] = useState<string | null>("all");
  const [selectedValue, setSelectedValue] = useState("creator");
  const [category, setCategory] = useState({
    value: "creator",
    label: "크리에이터",
  });
  const [title, setTitle] = useState("크리에이터");
  const [isSearch, setIsSearch] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [isDownLoad, setIsDownLoad] = useState(false);
  const [csvHeaders, setCsvHeaders] =
    useState<{ label: string; key: string }[]>(creatorCsvHeaders);
  const [csvDatas, setCsvDatas] = useState<ParamsProps[] | null>();

  // mounted
  // method
  const transformData = (
    csvHeaders: CsvHeaderProps[],
    csvData: ParamsProps[]
  ) => {
    const transformedData = csvData.map((data, index) => {
      const transformedItem: ParamsProps = { id: index + 1 };
      csvHeaders.forEach((header) => {
        if (header.key) {
          if (header.key === "createdAt") {
            const value = !data.createdAt
              ? ""
              : dayjs(data.createdAt).format("YYYY-MM-DD");
            transformedItem["createdAt"] = value;
          } else if (header.key === "username") {
            const value = data.user?.username ?? "";
            transformedItem["username"] = value;
          } else {
            const value = data.hasOwnProperty(header.key)
              ? data[header.key]
              : "";
            transformedItem[header.key] = value;
          }
        }
      });
      return transformedItem;
    });
    return transformedData;
  };
  const transformDataWithContact = (
    csvHeaders: CsvHeaderProps[],
    csvData: ParamsProps[]
  ) => {
    const transformedData = csvData.map((data, index) => {
      const transformedItem: ParamsProps = { id: index + 1 };
      csvHeaders.forEach((header) => {
        if (header.key) {
          if (header.key === "createdAt") {
            const value = !data.createdAt
              ? ""
              : dayjs(data.createdAt).format("YYYY-MM-DD");
            transformedItem["createdAt"] = value;
          } else if (header.key === "creatorID") {
            const value = data.creator?.creatorID ?? "";
            transformedItem["creatorID"] = value;
          } else if (header.key === "channel") {
            const value = data.creator?.channel ?? "";
            transformedItem["channel"] = value;
          } else if (header.key === "is_accept") {
            const value = data.is_accept;
            transformedItem["is_accept"] = value;
          } else if (header.key === "username") {
            const value = data.user?.username ?? "";
            transformedItem["username"] = value;
          } else {
            const value = data.hasOwnProperty(header.key)
              ? data[header.key]
              : "";
            transformedItem[header.key] = value;
          }
        }
      });
      return transformedItem;
    });
    return transformedData;
  };
  const transformDataWithDelivery = (
    csvHeaders: CsvHeaderProps[],
    csvData: ParamsProps[]
  ) => {
    const transformedData = csvData.map((data, index) => {
      const transformedItem: ParamsProps = { productId: index + 1 };
      csvHeaders.forEach((header) => {
        if (header.key) {
          if (header.key === "createdAt") {
            const value = !data.createdAt
              ? ""
              : dayjs(data.createdAt).format("YYYY-MM-DD");
            transformedItem["createdAt"] = value;
          } else if (header.key === "creatorID") {
            const value = data.creator?.creatorID ?? "";
            transformedItem["creatorID"] = value;
          } else if (header.key === "channel") {
            const value = data.creator?.channel ?? "";
            transformedItem["channel"] = value;
          } else if (header.key === "username") {
            const value = data.user?.username ?? "";
            transformedItem["username"] = value;
          } else {
            const value = data.hasOwnProperty(header.key)
              ? data[header.key]
              : "";
            transformedItem[header.key] = value;
          }
        }
      });
      return transformedItem;
    });
    return transformedData;
  };
  const transformDataWithContents = (
    csvHeaders: CsvHeaderProps[],
    csvData: ParamsProps[]
  ) => {
    const transformedData = csvData.map((data, index) => {
      const transformedItem: ParamsProps = { contentsId: index + 1 };
      csvHeaders.forEach((header) => {
        if (header.key) {
          if (header.key === "createdAt") {
            const value = !data.createdAt
              ? ""
              : dayjs(data.createdAt).format("YYYY-MM-DD");
            transformedItem["createdAt"] = value;
          } else if (header.key === "creatorID") {
            const value = data.creator?.creatorID ?? "";
            transformedItem["creatorID"] = value;
          } else if (header.key === "username") {
            const value = data.user?.username ?? "";
            transformedItem["username"] = value;
          } else if (header.key === "contents_date") {
            const value = !data.contents_date
              ? ""
              : dayjs(data.contents_date).format("YYYY-MM-DD");
            transformedItem["contents_date"] = value;
          } else if (header.key === "ugc_agree_date") {
            const value = !data.ugc_agree_date
              ? ""
              : dayjs(data.ugc_agree_date).format("YYYY-MM-DD");
            transformedItem["ugc_agree_date"] = value;
          } else if (header.key === "ugc_agree") {
            const value = data.ugc_agree === true ? "YES" : "NO";
            transformedItem["ugc_agree"] = value;
          } else {
            const value = data.hasOwnProperty(header.key)
              ? data[header.key]
              : "";
            transformedItem[header.key] = value;
          }
        }
      });
      return transformedItem;
    });
    return transformedData;
  };
  const transformDataWithUser = (
    csvHeaders: CsvHeaderProps[],
    csvData: ParamsProps[]
  ) => {
    const transformedData = csvData.map((data, index) => {
      const transformedItem: ParamsProps = { id: index + 1 };
      csvHeaders.forEach((header) => {
        if (header.key) {
          if (header.key === "createdAt") {
            const value = !data.createdAt
              ? ""
              : dayjs(data.createdAt).format("YYYY-MM-DD");
            transformedItem["createdAt"] = value;
          } else if (header.key === "auth") {
            const value = roles.find((role) => role.value === data.auth)?.label;
            transformedItem["auth"] = value;
          } else {
            const value = data.hasOwnProperty(header.key)
              ? data[header.key]
              : "";
            transformedItem[header.key] = value;
          }
        }
      });
      return transformedItem;
    });
    return transformedData;
  };
  const transformDataWithLog = (
    csvHeaders: CsvHeaderProps[],
    csvData: ParamsProps[]
  ) => {
    const transformedData = csvData.map((data, index) => {
      const transformedItem: ParamsProps = { id: index + 1 };
      csvHeaders.forEach((header) => {
        if (header.key) {
          if (header.key === "createdAt") {
            const value = !data.createdAt
              ? ""
              : dayjs(data.createdAt).format("YYYY-MM-DD hh:mm:ss");
            transformedItem["createdAt"] = value;
          } else if (header.key === "accountId") {
            const value = data.user?.accountId ?? "";
            transformedItem["accountId"] = value;
          } else if (header.key === "username") {
            const value = data.user?.username ?? "";
            transformedItem["username"] = value;
          } else {
            const value = data.hasOwnProperty(header.key)
              ? data[header.key]
              : "";
            transformedItem[header.key] = value;
          }
        }
      });
      return transformedItem;
    });
    return transformedData;
  };

  const generateTableContent = (value: string) => {
    switch (value) {
      case "creator":
        return (
          <Creator
            search={isSearch}
            startDate={startDate}
            endDate={endDate}
            setFilters={setFilters}
          />
        );
      case "contact":
        return (
          <Contact
            search={isSearch}
            startDate={startDate}
            endDate={endDate}
            setFilters={setFilters}
          />
        );
      case "delivery":
        return (
          <Delivery
            search={isSearch}
            startDate={startDate}
            endDate={endDate}
            setFilters={setFilters}
          />
        );
      case "contents":
        return (
          <Contents
            search={isSearch}
            startDate={startDate}
            endDate={endDate}
            setFilters={setFilters}
          />
        );
      case "user":
        return (
          <User
            search={isSearch}
            startDate={startDate}
            endDate={endDate}
            setFilters={setFilters}
          />
        );
      case "log":
        return (
          <ActivityLog
            search={isSearch}
            startDate={startDate}
            endDate={endDate}
            setFilters={setFilters}
          />
        );
      default:
        return <div>{value}</div>;
    }
  };
  const handleSelectChange = (value: {
    value: string;
    label: React.ReactNode;
  }) => {
    setCategory({
      value: `${value.value}`,
      label: `${value.label}`,
    });
    setTitle(`${value.label}`);
    setSelectedValue(`${value.value}`);
    setIsSearch(null);
    settingCsvDatas(`${value.value}`);
  };
  const getExcelDatas = async (type: string) => {
    let params = {
      startDate:
        startDate == null ? null : dayjs(startDate).format("YYYY-MM-DD"),
      endDate: endDate == null ? null : dayjs(endDate).format("YYYY-MM-DD"),
      filters: filters,
    };

    switch (type) {
      case "creator":
        handleDownload(fetchCreatorListExcel, params);
        return;
      case "contact":
        handleDownload(fetchContactListExcel, params);
        return;
      case "delivery":
        handleDownload(fetchDeliveryListExcel, params);
        return;
      case "contents":
        handleDownload(fetchContentsListExcel, params);
        return;
      case "user":
        handleDownload(fetchUserListExcel, params);
        return;
      case "log":
        handleDownload(fetchLogListExcel, params);
        return;
      default:
        handleDownload(fetchCreatorListExcel, params);
        return;
    }
  };
  const settingCsvDatas = (type: string) => {
    switch (type) {
      case "creator":
        setCsvHeaders(creatorCsvHeaders);
        return;
      case "contact":
        setCsvHeaders(contactCsvHeaders);
        return;
      case "delivery":
        setCsvHeaders(deliveryCsvHeaders);
        return;
      case "contents":
        setCsvHeaders(contentsCsvHeaders);
        return;
      case "user":
        setCsvHeaders(userCsvHeaders);
        return;
      case "log":
        setCsvHeaders(logCsvHeaders);
        return;
      default:
        setCsvHeaders(creatorCsvHeaders);
        return;
    }
  };
  const search = () => {
    setIsSearch((prev) => {
      if (prev || prev === 0) {
        return prev + 1;
      } else {
        return 0;
      }
    });
  };
  const handlePeriodChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    setPeriod(e.target.value);
    setStartDate(dayjs());
    setEndDate(dayjs());
    switch (value) {
      case "today":
        setStartDate(dayjs());
        setEndDate(dayjs());
        return;
      case "yesterday":
        setEndDate(dayjs());
        setStartDate(dayjs().subtract(1, "day"));
        return;
      case "week":
        setEndDate(dayjs());
        setStartDate(dayjs().subtract(7, "day"));
        return;
      case "month":
        setEndDate(dayjs());
        setStartDate(dayjs().subtract(1, "month"));
        return;
      case "all":
        setStartDate(null);
        setEndDate(null);
        return;
      default:
        setStartDate(dayjs());
        setEndDate(dayjs());
        return;
    }
  };
  const onRangeChange: RangePickerProps["onChange"] = (dates, dateStrings) => {
    if (dates) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };
  const excelDownLoad = async () => {
    await getExcelDatas(selectedValue);
  };
  const handleCloseCsv = () => {
    setIsDownLoad(false);
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>
            다운로드센터 &gt; {title} {title === "활동로그" ? null : "리스트"}
          </h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>

        <article className="log">
          <div className="sorting">
            <div className="column">
              <p>데이터 종류를 선택해주세요:</p>
              <Select
                labelInValue
                style={{
                  width: 196,
                }}
                value={category}
                onChange={handleSelectChange}
                options={selectOptions}
              />
            </div>
            <div className="flex mb26">
              <p className="fix">기간:</p>
              <Radio.Group
                options={periodOptions}
                onChange={handlePeriodChange}
                value={period}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            <div className="flex">
              <p className="fix">날짜 구간:</p>
              <RangePicker
                locale={locale}
                onChange={onRangeChange}
                value={[startDate, endDate]}
              />
            </div>
            <div className="buttons">
              {total > 0 && (
                <div className="totalCount">전체 행 개수: {total}개 </div>
              )}
              <button className="point" onClick={search}>
                조회
              </button>
              <button className="pointOut" onClick={excelDownLoad}>
                엑셀 내려받기
              </button>
            </div>
          </div>
          {generateTableContent(selectedValue)}
        </article>
      </section>
      <CsvDownloader
        propsData={{ data: csvDatas ?? [], headers: csvHeaders }}
        isDownLoad={isDownLoad}
        closeDownLoad={handleCloseCsv}
      />
    </>
  );
}
