import React, { useState } from "react";
import { login, fetchProfile } from "../api/index";
import showModal from "../components/message/Modal";
import { useUserStore } from "../store/userStore";
import { useCustomerStore } from "../store/customerStore";
import { fetchCustomerDetail } from "../api/customer";
import FindPasswordModal from "../components/pop/FindPasswordModal";
export default function Login() {
  // data
  const { setUser } = useUserStore();
  const { setCustomer } = useCustomerStore();
  const [accountId, setAccountId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  // method
  const handleFindPasswordModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const dataReset = () => {
    setAccountId("");
    setPassword("");
  };
  const onEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountId(event.currentTarget.value);
  };
  const onPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      await submit();
    }
  };
  const submit = async () => {
    const account = accountId.trim();
    const pass = password.trim();
    if (!account || account === "") {
      dataReset();
      return showModal({
        type: "error",
        messages: [
          "계정 또는 비밀번호를 잘못 입력했습니다.",
          "입력하신 내용을 다시 확인해주세요.",
        ],
      });
    }
    if (!pass || pass === "") {
      dataReset();
      return showModal({
        type: "error",
        messages: [
          "계정 또는 비밀번호를 잘못 입력했습니다.",
          "입력하신 내용을 다시 확인해주세요.",
        ],
      });
    }
    let data = {
      accountId: account,
      password: pass,
    };
    try {
      const res = await login(data);
      if (res.status !== 200) {
        return showModal({
          type: "error",
          messages: [
            "계정 또는 비밀번호를 잘못 입력했습니다.",
            "입력하신 내용을 다시 확인해주세요.",
          ],
        });
      } else {
        if (res.data.token?.access) {
          localStorage.setItem("accesstoken", res.data.token.access);
          const res2 = await fetchProfile();
          if (res2.data.status !== 200) {
            return showModal({
              type: "error",
              messages: [
                "계정 또는 비밀번호를 잘못 입력했습니다.",
                "입력하신 내용을 다시 확인해주세요.",
              ],
            });
          } else {
            if (!res2.data.data.is_superuser && res2.data.data.customer) {
              let res3 = await fetchCustomerDetail(res2.data.data.customer);
              setCustomer(res3.data.data);
            }
            setUser({ is_login: true, ...res2.data.data });
          }
        }
      }
    } catch (err) {
      return showModal({
        type: "error",
        messages: [
          "계정 또는 비밀번호를 잘못 입력했습니다.",
          "입력하신 내용을 다시 확인해주세요.",
        ],
      });
    }
  };

  return (
    <>
      <div className="loginBox">
        <section>
          <div className="logo">
            <img src="/images/logo.png" alt="로고" />
          </div>

          <article className="form">
            <h2>로그인</h2>
            <div>
              <p>계정</p>
              <input
                type="text"
                value={accountId}
                onChange={onEmailHandler}
                placeholder="이메일 계정을 입력하세요."
                onKeyDown={handleKeyDown}
              />
            </div>
            <div>
              <p>비밀번호</p>
              <div className="password">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={onPasswordHandler}
                  onKeyDown={handleKeyDown}
                />
                <button
                  className="visibleBtn"
                  onClick={togglePasswordVisibility}
                >
                  <span className="material-icons">
                    {showPassword ? "visibility_off" : "visibility"}
                  </span>
                </button>
              </div>
            </div>
            <button className="findBtn" onClick={handleFindPasswordModal}>
              비밀번호 찾기
            </button>
            <button className="point large" onClick={submit}>
              로그인
            </button>
          </article>
        </section>
      </div>
      <FindPasswordModal
        isOpen={isModalOpen}
        closeModal={handleFindPasswordModal}
      />
    </>
  );
}
