import React, { useState, useEffect } from "react";
import type { PaginationProps } from "antd";
import { Pagination } from "antd";
import dayjs from "dayjs";
import {
  DownloadProps,
  FilterCheck,
  Filter_group,
  ParamsProps,
  SortInfo,
  UserForAdmin,
} from "../../utils/type";
import { fetchUserListWithcustomer } from "../../api/index";
import {
  filterNonEmptyValues,
  handleErrorResponse,
  processParams,
  roles,
} from "../../utils/common";
import FilterModal from "../pop/FilterModal";
import { AxiosError } from "axios";

export default function User({
  search,
  startDate,
  endDate,
  setFilters,
}: DownloadProps) {
  // data
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortInfo, setSortInfo] = useState<SortInfo[]>([]);
  const [list, setList] = useState<UserForAdmin[]>([]);
  const [filterList, setFilterList] = useState<Filter_group>({
    channel: [],
    language: [],
    brand: [],
    country: [],
    method: [],
  });
  const [filterCheckList, setFilterCheckList] = useState<FilterCheck>({
    channel: [],
    language: [],
    brand: [],
    country: [],
    method: [],
  });
  // mounted
  useEffect(() => {
    setFilterCheckList({ type: [] });
    let parmas = {
      startDate:
        startDate == null ? null : dayjs(startDate).format("YYYY-MM-DD"),
      endDate: endDate == null ? null : dayjs(endDate).format("YYYY-MM-DD"),
    };
    if (search || search === 0) {
      getUserList(parmas);
    }
  }, [search]);
  useEffect(() => {
    for (const key in filterCheckList) {
      if (filterCheckList[key].length > 0) {
        getFilter();
        return;
      }
    }
  }, [filterCheckList]);
  // method
  const getUserList = async (paramsData: ParamsProps) => {
    const params = processParams(paramsData);
    try {
      let res = await fetchUserListWithcustomer(params);
      if (res.data.status === 200) {
        setList(res.data.data);
        setPage(res.data.page);
        setTotal(res.data.total);
        setFilterList(res.data.filter_group);
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    }
  };
  const handlePagination: PaginationProps["onChange"] = async (
    pageNum,
    pageSize
  ) => {
    const params = {
      page: pageNum,
      startDate:
        startDate == null ? null : dayjs(startDate).format("YYYY-MM-DD"),
      endDate: endDate == null ? null : dayjs(endDate).format("YYYY-MM-DD"),
      orderType: generateOrderString(sortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getUserList(params);
  };
  const getIconForCustomerName = (orderType: string) => {
    const customerNameSortInfo = sortInfo.find(
      (item) => item.orderType === orderType
    );

    if (customerNameSortInfo) {
      return customerNameSortInfo.sortOrder;
    }
    return "desc";
  };
  const generateOrderString = (orderList: SortInfo[]) => {
    const result = orderList
      .map((order) => {
        const sortOrderSymbol = order.sortOrder === "desc" ? "-" : "";
        return sortOrderSymbol + order.orderType;
      })
      .join(",");

    return result;
  };
  const toggleSortOrder = (currentSortOrder: "asc" | "desc"): "asc" | "desc" =>
    currentSortOrder === "asc" ? "desc" : "asc";
  const handleOrderType = async (orderType: string) => {
    const targetSortInfo = sortInfo.find(
      (info) => info.orderType === orderType
    );

    const newSortInfo = targetSortInfo
      ? sortInfo.map((info) =>
          info.orderType === orderType
            ? { orderType, sortOrder: toggleSortOrder(info.sortOrder) }
            : info
        )
      : [{ orderType, sortOrder: "asc" as "asc" | "desc" }];

    setSortInfo(newSortInfo);
    let params = {
      page,
      startDate:
        startDate == null ? null : dayjs(startDate).format("YYYY-MM-DD"),
      endDate: endDate == null ? null : dayjs(endDate).format("YYYY-MM-DD"),
      orderType: generateOrderString(newSortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getUserList(params);
  };
  const handleApply = (list: string[], type: string) => {
    setFilterCheckList((prevUserData) => ({
      ...prevUserData,
      [type]: list,
    }));
  };
  const getFilter = async () => {
    let params = {
      page: 1,
      startDate:
        startDate == null ? null : dayjs(startDate).format("YYYY-MM-DD"),
      endDate: endDate == null ? null : dayjs(endDate).format("YYYY-MM-DD"),
      orderType: generateOrderString(sortInfo),
      filters: filterNonEmptyValues(filterCheckList),
    };
    await getUserList(params);
  };

  useEffect(() => {
    const filters = filterNonEmptyValues(filterCheckList);
    setFilters?.(filters);
  }, [filterCheckList, setFilters]);
  return !search && search !== 0 ? (
    <></>
  ) : (
    <>
      {list?.length > 0 && (
        <div className="totalCount">전체 행 개수: {total}개 </div>
      )}
      <div className="tableBox">
        <table>
          <colgroup>
            <col style={{ width: "7%" }} />
            <col style={{ width: "18%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "8%" }} />
            <col style={{ width: "9%" }} />
            <col style={{ width: "9%" }} />
            <col style={{ width: "13%" }} />
            <col style={{ width: "13%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>
                <div className="flexB filtering">유저ID</div>
              </th>
              <th>
                <div className="flexB filtering">담당자</div>
              </th>
              <th>
                <div className="flexB filtering">
                  팀
                  <div className="flex">
                    <button onClick={(e) => handleOrderType("team")}>
                      {getIconForCustomerName("team") === "asc" ? (
                        <img src="/images/icon/up.png" alt="이모티콘" />
                      ) : (
                        <img src="/images/icon/down.png" alt="이모티콘" />
                      )}
                    </button>
                    <FilterModal
                      handleApply={(data) => handleApply(data, "team")}
                      list={filterList.team ?? []}
                    />
                  </div>
                </div>
              </th>
              <th>
                <div className="flexB filtering">
                  언어
                  <div className="flex">
                    <button onClick={(e) => handleOrderType("language")}>
                      {getIconForCustomerName("language") === "asc" ? (
                        <img src="/images/icon/up.png" alt="이모티콘" />
                      ) : (
                        <img src="/images/icon/down.png" alt="이모티콘" />
                      )}
                    </button>
                    <FilterModal
                      handleApply={(data) => handleApply(data, "language")}
                      list={filterList.language ?? []}
                    />
                  </div>
                </div>
              </th>
              <th>
                <div className="flexB filtering">
                  브랜드
                  <div className="flex">
                    <button onClick={(e) => handleOrderType("brand")}>
                      {getIconForCustomerName("brand") === "asc" ? (
                        <img src="/images/icon/up.png" alt="이모티콘" />
                      ) : (
                        <img src="/images/icon/down.png" alt="이모티콘" />
                      )}
                    </button>
                    <FilterModal
                      handleApply={(data) => handleApply(data, "brand")}
                      list={filterList.brand ?? []}
                    />
                  </div>
                </div>
              </th>
              <th>
                <div className="flexB filtering">
                  생성 날짜
                  <div className="flex">
                    <button onClick={(e) => handleOrderType("createdAt")}>
                      {getIconForCustomerName("createdAt") === "asc" ? (
                        <img src="/images/icon/up.png" alt="이모티콘" />
                      ) : (
                        <img src="/images/icon/down.png" alt="이모티콘" />
                      )}
                    </button>
                  </div>
                </div>
              </th>

              <th>
                <div className="flexB filtering">
                  권한
                  <div className="flex">
                    <button onClick={(e) => handleOrderType("auth")}>
                      {getIconForCustomerName("auth") === "asc" ? (
                        <img src="/images/icon/up.png" alt="이모티콘" />
                      ) : (
                        <img src="/images/icon/down.png" alt="이모티콘" />
                      )}
                    </button>
                    <FilterModal
                      handleApply={(data) => handleApply(data, "auth")}
                      list={filterList.auth ?? []}
                    />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {list.map((data, index) => (
              <tr key={data.id}>
                <td>{data.id}</td>
                <td>{data.accountId}</td>
                <td>{data.username}</td>
                <td>{data.team}</td>
                <td>{data.language}</td>
                <td>{data.brand}</td>
                <td>
                  {data.createdAt
                    ? dayjs(data.createdAt).format("YYYY-MM-DD")
                    : ""}
                </td>
                <td>{roles.find((role) => role.value === data.auth)?.label}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <Pagination
          showSizeChanger={false}
          current={page}
          total={total}
          pageSize={pageSize}
          onChange={handlePagination}
        />
      </div>
    </>
  );
}
