export const headersNoAuth: Record<string, string> = {
  "Content-Type": "application/json",
};
export const headers = (authorization: string) => {
  return {
    "Content-Type": "application/json",
    authorization: `Bearer ${authorization}`,
  };
};
export const headersWithFile = (authorization: string) => {
  return {
    "Content-Type": "multpart/form-data",
    authorization: `Bearer ${authorization}`,
  };
};
