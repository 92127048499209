import React, { useState } from "react";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import { useNavigate } from "react-router-dom";
import {
  createBulkCreator,
  downloadBulkCreatorUploadTemplate,
} from "../../api/creator";
import showModal from "../../components/message/Modal";
import * as XLSX from "xlsx";
import { ExcelCreatorProps } from "../../utils/type";
import { parseNumberOrString } from "../../utils/common";

export default function BulkRegister() {
  // data
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  // method
  const isExcelFile = (file: File) => {
    const fileExts = [".xlsx", ".xls"];
    const fileName = file.name;
    const fileExt = fileName.slice(fileName.lastIndexOf(".")).toLowerCase();
    return fileExts.includes(fileExt);
  };
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (!file) return;
    if (isExcelFile(file[0]) && file[0].size !== 0) {
      const reader = new FileReader();
      reader.readAsBinaryString(file[0]);
      reader.onload = (e) => {
        if (e.target?.result) {
          const result = e.target.result;
          const workbook = XLSX.read(result, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, {
            raw: false,
            defval: null,
          });
          let data: ExcelCreatorProps[] = [];
          jsonData.forEach((item: any, i: number) => {
            let dump: ExcelCreatorProps = {
              creatorID: item["크리에이터 ID"],
              channel: item["채널"],
              language: item["언어"],
              type: item["구분"],
              category: item["카테고리"],
              url: item["URL"],
              followerNo: item["팔로워 수 (숫자 입력)"],
              email: item["크리에이터 이메일 주소"],
              phone: item["크리에이터 전화번호"],
              address: item["제품 배송 주소"],
              zipcode: item["Zip Code"],
              country: item["배송 국가"],
              note: item["비고"],
            };
            data.push(dump);
          });
          submit(data);
        }
      };
      e.target.value = "";
    } else {
      return showModal({
        type: "error",
        messages: ["파일 양식을 확인해주세요. '.xlsx', '.xls' 만 가능합니다."],
      });
    }
  };

  const downloadTemplate = async () => {
    try {
      const res = await downloadBulkCreatorUploadTemplate();

      // Blob 데이터를 기반으로 파일 다운로드
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const contentDisposition = res.headers["content-disposition"];
      let fileName = "크리에이터 등록 양식"; // 기본 파일 이름
      if (contentDisposition) {
        const matches = contentDisposition.match(/filename\*=UTF-8''(.+)/);
        if (matches && matches[1]) {
          fileName = decodeURIComponent(matches[1]);
        }
      }
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // 파일명 설정
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("File download error:", error);
    }
  };

  const submit = async (data: ExcelCreatorProps[]) => {
    if (isButtonDisabled) {
      return;
    }
    const invalidItem = data.find((item: ExcelCreatorProps) => {
      return (
        item.creatorID === undefined ||
        item.channel === undefined ||
        item.type === undefined ||
        item.language === undefined ||
        item.url === undefined ||
        item.followerNo === undefined
      );
    });
    if (invalidItem) {
      return showModal({
        type: "bulk",
        bulk: 1,
        messages: [],
      });
    }
    const invalidItem2 = data.find((item: ExcelCreatorProps) => {
      return (
        item.followerNo !== null &&
        typeof parseNumberOrString(item.followerNo) !== "number"
      );
    });
    if (invalidItem2) {
      return showModal({
        type: "bulk",
        bulk: 2,
        messages: [],
      });
    }
    // return;
    try {
      setIsButtonDisabled(true);
      let isOk = false;
      await new Promise<void>((resolve) => {
        showModal({
          type: "confirm",
          messages: ["크리에이터 다수 등록을 하시겠습니까?"],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        return;
      }
      let res = await createBulkCreator(data);
      if (res.data.status === 200) {
        return navigate("/creator/list/member");
      } else if (res.data.status === 409) {
        if (Array.isArray(res.data.data) && res.data.data.length > 0) {
          const errorCreators = res.data.data.map(
            (item: { creatorID: string }, index: number) => {
              return `${item.creatorID}`;
            }
          );
          return showModal({
            type: "bulk",
            bulk: 3,
            messages: errorCreators,
          });
        } else {
          return showModal({
            type: "error",
            messages: ["업로드 중 오류가 발생하였습니다. 다시 시도해 주세요."],
          });
        }
      } else {
        return showModal({
          type: "error",
          messages: ["업로드 중 오류가 발생하였습니다. 다시 시도해 주세요."],
        });
      }
    } catch (err) {
      return showModal({
        type: "error",
        messages: ["업로드 중 오류가 발생하였습니다. 다시 시도해 주세요."],
      });
    } finally {
      setIsButtonDisabled(false);
    }
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>크리에이터 관리 &gt; 크리에이터 다수 등록</h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <article className="register center">
          <div className="box">
            <ul>
              <li>
                <h2>Step1. 엑셀 양식 다운로드</h2>
                <p>
                  아래 &#91;엑셀 양식 다운로드&#93; 버튼을 클릭하여 양식을 다운
                  받아 주세요.
                </p>
                <button onClick={downloadTemplate} className="pointOut">
                  엑셀 양식 다운로드
                </button>
              </li>
              <li>
                <h2>Step2. 엑셀 양식 작성</h2>
                <p>
                  엑셀 양식에 내용을 작성해주세요. 작성시 아래 유의사항을 반드시
                  지켜주세요.
                </p>
                <ul>
                  <li>필수 입력 필드는 반드시 모두 입력해주세요.</li>
                  <li>
                    각 컬럼에 맞는 데이터 형태를 지켜주세요. 예를 들어, 숫자가
                    들어가는 컬럼에 문자를 입력하시면 업로드시 오류가
                    발생합니다.
                  </li>
                  <li>이미 등록되어 있는 크리에이터는 등록이 불가합니다.</li>
                  <li>
                    드롭다운 형태로 되어 있는 입력값은 반드시 드롭다운에 있는
                    값만 입력 가능합니다.
                  </li>
                  <li>
                    위 사항들을 반영하지 않는 엑셀 파일들은 업로드가
                    불가능합니다.
                  </li>
                </ul>
              </li>
              <li>
                <h2>Step3. 엑셀 업로드</h2>
                <p>
                  아래 &#91;업로드&#93; 버튼을 클릭하여 크리에이터 다수 등록을
                  완료해주세요.
                </p>
                <label htmlFor="ex_file">
                  <button className="point">업로드</button>
                  <input type="file" id="ex_file" onChange={handleFile} />
                </label>
              </li>
            </ul>
          </div>
        </article>
      </section>
    </>
  );
}
