import React, { useState } from "react";
import { Outlet } from "react-router-dom";

const AdminLayout = () => {
  return (
    <div className="contWrap admin">
      <Outlet />
    </div>
  );
};

export default AdminLayout;
