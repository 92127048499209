import { Navigate } from "react-router-dom";
import { useUserStore } from "../store/userStore";

type Props = {
  children?: React.ReactNode;
};

export const ProtectedRoute = ({ children }: Props) => {
  const { is_login } = useUserStore();
  if (!is_login) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

export const ProtectedRouteWithAdmin = ({ children }: Props) => {
  const { is_login, is_superuser } = useUserStore();
  if (!is_login) {
    return <Navigate to="/" />;
  } else if (is_login && !is_superuser) {
    return <Navigate to="/dashboard" />;
  }
  return <>{children}</>;
};

export const ProtectedRouteWithLogin = ({ children }: Props) => {
  const { is_login, is_superuser } = useUserStore();
  if (is_login && is_superuser) {
    return <Navigate to="/admin" />;
  } else if (is_login && !is_superuser) {
    return <Navigate to="/dashboard" />;
  }
  return <>{children}</>;
};
