import { headers } from "../config/env";
import Axios from "../config/axios";
import { BulkDeleteProps, ContactInfo, ParamsProps } from "../utils/type";
import { useCustomerStore } from "../store/customerStore";
import { createSearchParams } from "../utils/requestUtils";

export async function fetchContactList(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/contact/list/user`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchContactListAll(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/contact/list/all`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchContactListExcel(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  const searchParams = createSearchParams(params);
  return await Axios.get(`/contact/list/excel`, {
    params: searchParams,
    headers: headers(localStorage.getItem("accesstoken")!),
    responseType: "blob",
  });
}

export async function fetchContactDetail(id: string) {
  return await Axios.get(`/contact/${id}`, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

export async function deleteContacts({
  ids,
  select_all,
  filters,
}: BulkDeleteProps) {
  return await Axios.put(
    `/contact/delete`,
    { customer_id: useCustomerStore.getState().id!, ids, select_all, filters },
    {
      headers: headers(localStorage.getItem("accesstoken")!),
    }
  );
}

export async function createContact(data: ContactInfo) {
  data.customer = useCustomerStore.getState().id!;
  return await Axios.post("/contact", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}

export async function updateContact(data: ContactInfo) {
  return await Axios.put("/contact", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
