import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Select, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import type { DatePickerProps } from "antd";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import { INITIAL_STATE, useMetadataStore } from "../../store/metadataStore";
import { useUserStore } from "../../store/userStore";
import showModal from "../../components/message/Modal";
import { ContentsInfo, MetaAddbject, UserForUser } from "../../utils/type";
import { fetchCreatorDetail } from "../../api/creator";
import dayjs, { Dayjs } from "dayjs";
import { createContents } from "../../api/contents";
import {
  convertToInteger,
  getEmptyProperty,
  handleErrorResponse,
} from "../../utils/common";
import { AxiosError } from "axios";

const initialCreator = {
  url: "",
  phone: "",
  note: "",
  customer: null,
  creatorID: "",
  email: "",
  address: "",
  zipcode: "",
  country: "",
  language: "",
  channel: "",
  followerNo: null,
  category: "",
  type: "",
  id: null,
  uuid: null,
};
const initialContents = {
  customer: null,
  creator: null,
  user: null,
  contents_date: null,
  language: "",
  brand: "",
  product: "",
  hits: null, // 숫자
  comments: null, // 숫자
  likes: null, // 숫자
  saves: null, // 숫자
  url: "",
  recommend: "",
  ugc_agree: false,
  ugc_agree_date: null,
  note: "",
};

export default function MyContentsRegister2() {
  // data
  const { language, brand, product, supporter_recommand, getMetadata } =
    useMetadataStore();
  const [metadata, setMetadata] = useState<MetaAddbject>(INITIAL_STATE);
  const { username, id } = useUserStore();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const params_id = searchParams.get("id");
  const [creator, setCreator] = useState<UserForUser>(initialCreator);
  const [contentsInfo, setContentsInfo] =
    useState<ContentsInfo>(initialContents);
  const [productOptions, setProductOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [isAddDelivery, setIsAddDelivery] = useState(false);
  const [contentsDate, setContentsDate] = useState<Dayjs | null>(null);
  const [ugcDate, setUgcDate] = useState<Dayjs | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errorProperties, setErrorProperties] = useState<string | null>(null);

  // mounted
  useEffect(() => {
    getCreatorDetail();
  }, []);
  useEffect(() => {
    setMetadata(getMetadata());
  }, [language, brand, supporter_recommand, product]);
  // method
  const getCreatorDetail = async () => {
    if (params_id !== null) {
      try {
        const res = await fetchCreatorDetail(params_id);
        if (res.data.status === 200) {
          setCreator(res.data.data);
          setContentsInfo((prevUserData) => ({
            ...prevUserData,
            creator: res.data.data.id,
            user: id,
          }));
        }
      } catch (err) {
        handleErrorResponse(err as AxiosError);
      }
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (errorProperties === name) {
      setErrorProperties(null);
    }
    if (
      name === "comments" ||
      name === "hits" ||
      name === "likes" ||
      name === "saves"
    ) {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        [name]: convertToInteger(Number(value)),
      }));
    } else {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };
  const handleSelectChange = (value: string, type: string) => {
    if (errorProperties === type) {
      setErrorProperties(null);
    }
    if (type === "brand") {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        brand: value,
        product: "",
      }));
      const productList = product
        .filter((item) => item.brand === value)
        .reduce<
          {
            id: string;
            name: string;
            brand: string;
          }[]
        >((acc, item) => {
          if (!acc.some((product) => product.name === item.name)) {
            acc.push(item);
          }
          return acc;
        }, [])
        .map((item) => ({ value: item.name, label: item.name }));
      setProductOptions(productList);
    } else if (type === "language") {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        language: value,
      }));
    } else if (type === "recommend") {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        recommend: value,
      }));
    } else if (type === "product") {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        product: value,
      }));
    } else if (type === "ugc_agree") {
      setContentsInfo((prevUserData) => ({
        ...prevUserData,
        ugc_agree: value === "false" ? false : true,
      }));
    }
  };
  const onContentsDateChange: DatePickerProps["onChange"] = (
    date: Dayjs | null,
    dateString
  ) => {
    if (errorProperties === "contentsDate") {
      setErrorProperties(null);
    }
    setContentsDate(date);
  };
  const onUgcDateChange: DatePickerProps["onChange"] = (
    date: Dayjs | null,
    dateString
  ) => {
    setUgcDate(date);
  };
  const submit = async (value: string) => {
    if (isButtonDisabled) {
      return;
    }
    let where = getEmptyProperty(contentsInfo, [
      "language",
      "contentsDate",
      "brand",
      "product",
      "url",
    ]);
    setErrorProperties(where);
    if (contentsDate === null) {
      setErrorProperties("contentsDate");
    }
    if (
      contentsInfo.language === "" ||
      contentsInfo.brand === "" ||
      contentsInfo.url === "" ||
      contentsInfo.product === "" ||
      contentsDate === null
    ) {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    let isOk = false;
    if (value !== "save") {
      await new Promise<void>((resolve) => {
        showModal({
          type: "confirm",
          messages: [
            "입력하신 제품을 먼저 저장하신 후에 제품 추가가 가능합니다.",
            "입력하신 콘텐츠를 저장하시겠습니까?",
          ],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
    } else {
      await new Promise<void>((resolve) => {
        showModal({
          type: "confirm",
          messages: ["콘텐츠를 등록하시겠습니까?"],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
    }
    if (!isOk) {
      setIsButtonDisabled(false);
      return;
    }
    const data = {
      ...contentsInfo,
      contents_date: dayjs(contentsDate).format("YYYY-MM-DD"),
      ugc_agree_date:
        ugcDate === null ? null : dayjs(ugcDate).format("YYYY-MM-DD"),
    };
    try {
      setIsButtonDisabled(true);
      let res = await createContents(data);
      if (res.data.status === 200) {
        if (value !== "save") {
          if (res.data.data?.contentsId) {
            setIsAddDelivery(true);
            setContentsInfo((prevUserData) => ({
              ...prevUserData,
              contentsId: res.data.data.contentsId,
              product: "",
              brand: "",
            }));
          }
        } else {
          return navigate("/contents/list/member");
        }
      }
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    } finally {
      setIsButtonDisabled(false);
    }
  };
  const transformArray = (
    data: string[]
  ): { value: string; label: string }[] => {
    return data.map((item) => ({ value: item, label: item }));
  };
  const handleCancelButton = () => {
    if (isAddDelivery) {
      return navigate("/contents/list/member");
    } else {
      return navigate("/contents/member/creator/list");
    }
  };

  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>
            콘텐츠 관리 &gt; 나의 크리에이터 콘텐츠 등록 &gt; 콘텐츠 정보 입력
          </h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <article className="register">
          <h3 className="contentTitle">콘텐츠 정보</h3>
          <div className="registerBox">
            <div className="flex">
              <div>
                <p>크리에이터 ID</p>
                <input
                  type="text"
                  disabled
                  className="basic"
                  value={creator.creatorID ?? ""}
                />
              </div>
              <div>
                <p>담당자</p>
                <input type="text" disabled value={username ?? ""} />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>채널</p>
                <input
                  type="text"
                  disabled
                  className="basic"
                  value={creator?.channel ?? ""}
                />
              </div>
              <div>
                <p>언어</p>
                <input type="text" disabled value={language ?? ""} />
              </div>
              <div>
                <p>구분</p>
                <input type="text" disabled value={creator?.type ?? ""} />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>URL</p>
                <input
                  type="text"
                  disabled
                  className="basic"
                  value={creator?.url ?? ""}
                />
              </div>
              <div>
                <p>팔로워수</p>
                <input type="text" disabled value={creator?.followerNo ?? ""} />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  언어 <span className="red">*</span>
                </p>
                <Select
                  disabled={isAddDelivery}
                  placeholder="언어"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "language" ? "error" : ""}`}
                  onChange={(value) => handleSelectChange(value, "language")}
                  options={transformArray(metadata.language)}
                />
              </div>
              <div>
                <p>
                  콘텐츠 업로드 날짜 <span className="red">*</span>
                </p>

                <DatePicker
                  placeholder="날짜를 선택하세요."
                  style={{
                    width: 196,
                  }}
                  className={`${
                    errorProperties === "contentsDate" ? "error" : ""
                  }`}
                  disabled={isAddDelivery}
                  value={contentsDate}
                  locale={locale}
                  onChange={onContentsDateChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  브랜드 <span className="red">*</span>
                </p>
                <Select
                  placeholder="브랜드"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "brand" ? "error" : ""}`}
                  value={contentsInfo.brand === "" ? null : contentsInfo.brand}
                  onChange={(value) => handleSelectChange(value, "brand")}
                  options={transformArray(metadata.brand)}
                />
              </div>
              <div>
                <p>
                  제품 <span className="red">*</span>
                </p>
                <Select
                  placeholder="제품"
                  style={{
                    width: 628,
                  }}
                  className={`${errorProperties === "product" ? "error" : ""}`}
                  value={
                    contentsInfo.product === "" ? null : contentsInfo.product
                  }
                  onChange={(value) => handleSelectChange(value, "product")}
                  options={productOptions}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>조회수</p>
                <input
                  type="number"
                  name="hits"
                  disabled={isAddDelivery}
                  value={contentsInfo.hits ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>댓글</p>
                <input
                  type="number"
                  name="comments"
                  disabled={isAddDelivery}
                  value={contentsInfo.comments ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>좋아요</p>
                <input
                  type="number"
                  name="likes"
                  disabled={isAddDelivery}
                  value={contentsInfo.likes ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>저장</p>
                <input
                  type="number"
                  name="saves"
                  disabled={isAddDelivery}
                  value={contentsInfo.saves ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  URL <span className="red">*</span>
                </p>
                <input
                  type="text"
                  name="url"
                  className={`basic ${
                    errorProperties === "url" ? "error" : ""
                  }`}
                  disabled={isAddDelivery}
                  value={contentsInfo.url ?? ""}
                  onChange={onChange}
                  placeholder="http://example.com"
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>서포터추천</p>
                <Select
                  placeholder="서포터추천"
                  style={{
                    width: 196,
                  }}
                  disabled={isAddDelivery}
                  onChange={(value) => handleSelectChange(value, "recommend")}
                  options={transformArray(metadata.supporter_recommand)}
                />
              </div>
              <div>
                <p>UGC 약관 동의</p>
                <Select
                  placeholder="UGC 약관 동의"
                  style={{
                    width: 196,
                  }}
                  disabled={isAddDelivery}
                  onChange={(value) => handleSelectChange(value, "ugc_agree")}
                  options={[
                    {
                      value: "true",
                      label: "Yes",
                    },
                    {
                      value: "false",
                      label: "No",
                    },
                  ]}
                />
              </div>
              <div>
                <p>UGC 약관 안내일</p>
                <DatePicker
                  placeholder="날짜를 선택하세요."
                  style={{
                    width: 196,
                  }}
                  locale={locale}
                  value={ugcDate}
                  disabled={isAddDelivery}
                  onChange={onUgcDateChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>비고</p>
                <input
                  type="text"
                  className="note"
                  name="note"
                  disabled={isAddDelivery}
                  value={contentsInfo.note ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="buttonWrap">
            <button
              className="point"
              onClick={(e) => submit("add")}
              disabled={isButtonDisabled}
            >
              제품 추가
            </button>
            <button
              className="point"
              onClick={(e) => submit("save")}
              disabled={isButtonDisabled}
            >
              저장
            </button>
            <button className="back" onClick={handleCancelButton}>
              취소
            </button>
          </div>
        </article>
      </section>
    </>
  );
}
