import React from "react";
import { Outlet } from "react-router-dom";

type Props = {
  children?: React.ReactNode;
};

const AccountLayout = ({ children }: Props) => {
  return (
    <div className="home">
      <Outlet />
      <p className="copy">Copyright © crysbe. All Rights Reserved.</p>
    </div>
  );
};

export default AccountLayout;
