import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Pagination, Tabs, Select } from "antd";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import { Metadatas, UserForAdmin, UserForUser } from "../../utils/type";
import { useMetadataStore, INITIAL_STATE } from "../../store/metadataStore";
import { fetchCreatorDetail, updateCreator } from "../../api/creator";
import showModal from "../../components/message/Modal";
import ContactHistory from "../../components/user/ContactHistory";
import DeliveryHistory from "../../components/user/DeliveryHistory";
import { fetchUserListAll } from "../../api";
import { useUserStore } from "../../store/userStore";
import ContentsHistory from "../../components/user/ContentsHistory";
import {
  convertToInteger,
  getEmptyProperty,
  handleErrorResponse,
} from "../../utils/common";
import { AxiosError } from "axios";

const { TabPane } = Tabs;
const initialCreator = {
  url: "",
  phone: "",
  note: "",
  customer: null,
  creatorID: "",
  email: "",
  address: "",
  zipcode: "",
  country: "",
  language: "",
  channel: "",
  followerNo: null,
  category: "",
  id: null,
  uuid: null,
};
export default function AllCreatorsManage() {
  // data
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const paramsId = searchParams.get("id");
  const { language, marketing, creator_type, country, category, getMetadata } =
    useMetadataStore();
  const { auth, id } = useUserStore();
  const [title, setTitle] = useState("기본 정보");
  const [creator, setCreator] = useState<UserForUser>(initialCreator);
  const [metadata, setMetadata] = useState<Metadatas>(INITIAL_STATE);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errorProperties, setErrorProperties] = useState<string | null>(null);
  const [userlist, setUserlist] = useState<{ value: string; label: string }[]>(
    []
  );
  const [forceUpdate, setForceUpdate] = useState<number>(0);
  // mounted
  useEffect(() => {
    getCreatorDetail();
    getUserListAll();
  }, []);
  useEffect(() => {
    setMetadata(getMetadata());
  }, [language, marketing, creator_type, country, category]);
  // method
  const getUserListAll = async () => {
    const params = {};
    const res = await fetchUserListAll(params);
    const users = res.data.data.map((item: UserForAdmin) => {
      return { label: item.username, value: item.id };
    });
    setUserlist(users);
  };
  const getCreatorDetail = async () => {
    if (paramsId !== null) {
      try {
        const res = await fetchCreatorDetail(paramsId);
        if (res.data.status === 200) {
          setCreator(res.data.data);
        }
      } catch (err) {
        handleErrorResponse(err as AxiosError);
      }
    }
  };
  const onTapChange = (key: string) => {
    setForceUpdate((prevNumber) => prevNumber + 1);
    if (key === "1") {
      setTitle("기본 정보");
    } else if (key === "2") {
      setTitle("컨택 이력");
    } else if (key === "3") {
      setTitle("제품 배송 이력");
    } else if (key === "4") {
      setTitle("콘텐츠 이력");
    } else {
      setTitle("");
    }
  };
  const transformArray = (
    data: string[]
  ): { value: string; label: string }[] => {
    return data.map((item) => ({ value: item, label: item }));
  };
  const transformArrayWithid = (
    data: { value: string; label: string }[]
  ): { value: string; label: string }[] => {
    return data.map((item) => ({ value: item.value, label: item.label }));
  };
  const handleSelectChange = (value: string, type: string) => {
    if (errorProperties === type) {
      setErrorProperties(null);
    }
    if (type === "channel") {
      setCreator((prevUserData) => ({
        ...prevUserData,
        channel: value,
      }));
    } else if (type === "username") {
      const foundElement = userlist.find((element) => element.value === value);
      if (foundElement !== null && foundElement !== undefined) {
        setCreator((prevUserData) => ({
          ...prevUserData,
          user: {
            username: foundElement?.label.toString(),
            id: Number(foundElement?.value),
          },
        }));
      }
    } else if (type === "language") {
      setCreator((prevUserData) => ({
        ...prevUserData,
        language: value,
      }));
    } else if (type === "type") {
      setCreator((prevUserData) => ({
        ...prevUserData,
        type: value,
      }));
    } else if (type === "category") {
      setCreator((prevUserData) => ({
        ...prevUserData,
        category: value,
      }));
    } else if (type === "country") {
      setCreator((prevUserData) => ({
        ...prevUserData,
        country: value,
      }));
    }
  };
  const submit = async () => {
    if (auth === "supporter" && creator.user?.id !== id) {
      return showModal({
        type: "error",
        messages: ["다른 유저가 등록한 정보는 수정할 수 없습니다."],
      });
    }
    if (isButtonDisabled) {
      return;
    }
    let where = getEmptyProperty(creator, [
      "creatorID",
      "channel",
      "language",
      "type",
      "url",
      "followerNo",
    ]);
    setErrorProperties(where);
    if (
      creator.creatorID === "" ||
      creator.channel === "" ||
      creator.language === "" ||
      creator.type === "" ||
      creator.url === "" ||
      creator.followerNo === null ||
      creator.followerNo === undefined
    ) {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    try {
      setIsButtonDisabled(true);
      let isOk = false;
      await new Promise<void>((resolve) => {
        showModal({
          type: "confirm",
          messages: ["크리에이터를 수정하시겠습니까?"],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        return;
      }
      let data = { ...creator, user: creator.user?.id };
      let res = await updateCreator(data);
      return navigate("/creator/list/member");
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    } finally {
      setIsButtonDisabled(false);
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (errorProperties === name) {
      setErrorProperties(null);
    }
    if (name === "followerNo") {
      setCreator((prevUserData) => ({
        ...prevUserData,
        followerNo: convertToInteger(Number(value)),
      }));
    } else {
      setCreator((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>크리에이터 관리 &gt; 전체 크리에이터 조회 &gt; {title}</h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <Tabs defaultActiveKey="1" onChange={onTapChange} className="tabBoard">
          <TabPane tab="기본 정보" key="1">
            <article className="register">
              <div className="registerBox">
                <div className="flex">
                  <div>
                    <p>
                      크리에이터 ID <span className="red">*</span>
                    </p>
                    <input
                      type="text"
                      className="basic"
                      name="creatorID"
                      value={creator.creatorID ?? ""}
                      onChange={onChange}
                    />
                  </div>
                  <div>
                    <p>담당자</p>
                    {auth === "supporter" ? (
                      <input
                        type="text"
                        disabled
                        name="username"
                        value={creator.user?.username ?? ""}
                      />
                    ) : (
                      <Select
                        placeholder="담당자"
                        style={{
                          width: 196,
                        }}
                        value={creator.user?.username ?? ""}
                        onChange={(value) =>
                          handleSelectChange(value, "username")
                        }
                        options={transformArrayWithid(userlist)}
                      />
                    )}
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p>
                      채널 <span className="red">*</span>
                    </p>
                    <Select
                      placeholder="채널"
                      style={{
                        width: 196,
                      }}
                      value={creator.channel ?? ""}
                      onChange={(value) => handleSelectChange(value, "channel")}
                      options={transformArray(metadata.marketing)}
                    />
                  </div>
                  <div>
                    <p>
                      언어 <span className="red">*</span>
                    </p>
                    <Select
                      placeholder="언어"
                      style={{
                        width: 196,
                      }}
                      value={creator.language ?? ""}
                      onChange={(value) =>
                        handleSelectChange(value, "language")
                      }
                      options={transformArray(metadata.language)}
                    />
                  </div>
                  <div>
                    <p>
                      구분 <span className="red">*</span>
                    </p>
                    <Select
                      placeholder="구분"
                      style={{
                        width: 196,
                      }}
                      className={`${errorProperties === "type" ? "error" : ""}`}
                      value={creator.type ?? ""}
                      onChange={(value) => handleSelectChange(value, "type")}
                      options={transformArray(metadata.creator_type)}
                    />
                  </div>
                  <div>
                    <p>카테고리</p>
                    <Select
                      placeholder="채널"
                      style={{
                        width: 196,
                      }}
                      value={creator.category ?? ""}
                      onChange={(value) =>
                        handleSelectChange(value, "category")
                      }
                      options={transformArray(metadata.category)}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p>
                      URL <span className="red">*</span>
                    </p>
                    <input
                      type="text"
                      className="basic"
                      name="url"
                      value={creator.url ?? ""}
                      onChange={onChange}
                      placeholder="http://example.com"
                    />
                  </div>
                  <div>
                    <p>
                      팔로워 수 <span className="red">*</span>
                    </p>
                    <input
                      type="number"
                      name="followerNo"
                      value={creator.followerNo ?? ""}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p>크리에이터 이메일 주소</p>
                    <input
                      type="text"
                      className="basic"
                      name="email"
                      value={creator.email ?? ""}
                      onChange={onChange}
                    />
                  </div>
                  <div>
                    <p>크리에이터 전화번호</p>
                    <input
                      type="text"
                      name="phone"
                      value={creator.phone ?? ""}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p>제품 배송 주소</p>
                    <input
                      type="text"
                      className="large"
                      name="address"
                      value={creator.address ?? ""}
                      onChange={onChange}
                    />
                  </div>
                  <div>
                    <p>Zip Code</p>
                    <input
                      type="text"
                      name="zipcode"
                      value={creator.zipcode ?? ""}
                      onChange={onChange}
                    />
                  </div>
                  <div>
                    <p>배송 국가</p>
                    <Select
                      placeholder="국가"
                      style={{
                        width: 196,
                      }}
                      value={creator.country ?? ""}
                      onChange={(value) => handleSelectChange(value, "country")}
                      options={transformArray(metadata.country)}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p>비고</p>
                    <input
                      type="text"
                      className="note"
                      name="note"
                      value={creator.note ?? ""}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="buttonWrap">
                <button
                  className="point"
                  onClick={submit}
                  disabled={isButtonDisabled}
                >
                  수정
                </button>
                <Link to="/creator/list/global" className="back">
                  뒤로
                </Link>
              </div>
            </article>
          </TabPane>
          <TabPane tab="컨택 이력" key="2">
            <article className="manage">
              <ContactHistory
                id={`${creator.id}`}
                creatorProps={`yes`}
                forceUpdate={forceUpdate}
              />
            </article>
          </TabPane>
          <TabPane tab="제품 배송 이력" key="3">
            <article className="manage">
              <DeliveryHistory
                id={`${creator.id}`}
                creatorProps={`yes`}
                forceUpdate={forceUpdate}
              />
            </article>
          </TabPane>
          <TabPane tab="콘텐츠 이력" key="4">
            <article className="manage">
              <ContentsHistory
                id={`${creator.id}`}
                creatorProps={`yes`}
                forceUpdate={forceUpdate}
              />
            </article>
          </TabPane>
        </Tabs>
      </section>
    </>
  );
}
