import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import { handleErrorResponse } from "../../utils/common";
import { AxiosError } from "axios";
import { fetchUserListAll } from "../../api";
import { BulkUpdate, UserForAdmin } from "../../utils/type";
import { Select } from "antd";
import showModal from "../../components/message/Modal";
import { updateCreatorBulk } from "../../api/creator";
import { useCustomerStore } from "../../store/customerStore";

export default function ManagerChange() {
  // data
  const location = useLocation();
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [userlist, setUserlist] = useState<{ value: string; label: string }[]>(
    []
  );
  const [user, setUser] = useState("");
  const [locationParams, setLocationParams] = useState<BulkUpdate | null>();
  useEffect(() => {
    if (
      location.state &&
      (location.state.ids ||
        location.state.filters ||
        location.state.select_all)
    ) {
      const updateParams = location.state;
      setLocationParams(updateParams);
      getUserListAll();
    } else {
      handleBackButton();
    }
  }, []);
  // method
  const submit = async () => {
    if (user === "") {
      return showModal({
        type: "error",
        messages: ["담당자를 선택해주세요."],
      });
    }
    try {
      setIsButtonDisabled(true);
      if (locationParams) {
        let data = {
          ...locationParams,
          customer_id: useCustomerStore.getState().id!,
          user_id: user,
        };
        let res = await updateCreatorBulk(data);
      }
      return navigate("/admin/creator/list");
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    } finally {
      setIsButtonDisabled(false);
    }
  };
  const handleBackButton = () => {
    navigate(-1);
  };
  const getUserListAll = async () => {
    const params = {};
    const res = await fetchUserListAll(params);
    const users = res.data.data.map((item: UserForAdmin) => {
      return {
        label: `${item.username} (${item.accountId})`,
        value: item.id,
      };
    });
    setUserlist(users);
  };
  const transformArrayWithid = (
    data: { value: string; label: string }[]
  ): { value: string; label: string }[] => {
    return data.map((item) => ({ value: item.value, label: item.label }));
  };
  const handleSelectChange = (value: string) => {
    setUser(value);
  };

  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>크리에이터 담당자 변경</h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>

        <article className="register">
          <div className="sorting">
            <div className="column">
              <p>새로운 담당자를 선택해주세요</p>
              <Select
                placeholder="담당자"
                style={{
                  width: 450,
                }}
                onChange={(value) => handleSelectChange(value)}
                options={transformArrayWithid(userlist)}
              />
            </div>
          </div>
          <div className="buttonWrap">
            {
              <>
                <button
                  className="point"
                  onClick={submit}
                  disabled={isButtonDisabled}
                >
                  저장
                </button>
                <button className="back" onClick={handleBackButton}>
                  취소
                </button>
              </>
            }
          </div>
        </article>
      </section>
    </>
  );
}
