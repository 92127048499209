import React from "react";

interface MessageProps {
  messages: string[];
}

const DefaultMessage = ({ messages }: MessageProps) => (
  <div className="flex">
    <div>
      {messages.map((message, index) => (
        <p key={index}>{message}</p>
      ))}
    </div>
  </div>
);

export default DefaultMessage;
