import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
import UserDropdownMenu from "../../components/UserDropdownMenu";
import { Metadatas, UserForUser } from "../../utils/type";
import { useMetadataStore, INITIAL_STATE } from "../../store/metadataStore";
import { useUserStore } from "../../store/userStore";
import showModal from "../../components/message/Modal";
import { createCreator } from "../../api/creator";
import {
  convertToInteger,
  getEmptyProperty,
  handleAxiosResponse,
  handleErrorResponse,
} from "../../utils/common";
import { AxiosError } from "axios";

const initialCreator = {
  url: "",
  phone: "",
  note: "",
  customer: null,
  creatorID: "",
  email: "",
  address: "",
  zipcode: "",
  country: "",
  language: "",
  channel: "",
  followerNo: null,
  category: "",
  type: "",
};

export default function CreatorRegister() {
  // data
  const navigate = useNavigate();
  const { language, marketing, creator_type, country, category, getMetadata } =
    useMetadataStore();
  const { username } = useUserStore();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [creator, setCreator] = useState<UserForUser>(initialCreator);
  const [metadata, setMetadata] = useState<Metadatas>(INITIAL_STATE);
  const [errorProperties, setErrorProperties] = useState<string | null>(null);
  // mounted
  useEffect(() => {}, []);
  useEffect(() => {
    setMetadata(getMetadata());
  }, [language, marketing, creator_type, country, category]);

  // method
  const handleSelectChange = (value: string, type: string) => {
    if (errorProperties === type) {
      setErrorProperties(null);
    }
    if (type === "channel") {
      setCreator((prevUserData) => ({
        ...prevUserData,
        channel: value,
      }));
    } else if (type === "language") {
      setCreator((prevUserData) => ({
        ...prevUserData,
        language: value,
      }));
    } else if (type === "type") {
      setCreator((prevUserData) => ({
        ...prevUserData,
        type: value,
      }));
    } else if (type === "category") {
      setCreator((prevUserData) => ({
        ...prevUserData,
        category: value,
      }));
    } else if (type === "country") {
      setCreator((prevUserData) => ({
        ...prevUserData,
        country: value,
      }));
    }
  };
  const submit = async () => {
    if (isButtonDisabled) {
      return;
    }
    let where = getEmptyProperty(creator, [
      "creatorID",
      "channel",
      "language",
      "type",
      "url",
      "followerNo",
    ]);
    setErrorProperties(where);
    if (
      creator.creatorID === "" ||
      creator.channel === "" ||
      creator.language === "" ||
      creator.type === "" ||
      creator.url === "" ||
      creator.followerNo === null ||
      creator.followerNo === undefined
    ) {
      return showModal({
        type: "error",
        messages: [
          "필수 입력값이 입력되지 않았거나, 잘못된 형식입니다.",
          "필수 입력값을 올바르게 모두 입력해주세요.",
        ],
      });
    }
    try {
      setIsButtonDisabled(true);
      let isOk = false;
      await new Promise<void>((resolve) => {
        showModal({
          type: "confirm",
          messages: ["크리에이터를 등록하시겠습니까?"],
          confirmOptions: {
            onOk() {
              isOk = true;
              resolve();
            },
            onCancel() {
              resolve();
            },
          },
        });
      });
      if (!isOk) {
        return;
      }
      let data = creator;
      let res = await createCreator(data);

      handleAxiosResponse(res, () => navigate("/creator/list/member"));
    } catch (err) {
      handleErrorResponse(err as AxiosError);
    } finally {
      setIsButtonDisabled(false);
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (errorProperties === name) {
      setErrorProperties(null);
    }
    if (name === "followerNo") {
      setCreator((prevUserData) => ({
        ...prevUserData,
        followerNo: convertToInteger(Number(value)),
      }));
    } else {
      setCreator((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };
  const transformArray = (
    data: string[]
  ): { value: string; label: string }[] => {
    return data.map((item) => ({ value: item, label: item }));
  };
  return (
    <>
      <section className="contents">
        <article className="title flexB">
          <h1>크리에이터 관리 &gt; 크리에이터 등록</h1>
          <div className="utilityBtns">
            <UserDropdownMenu />
          </div>
        </article>
        <article className="register">
          <h3 className="contentTitle">기본 정보</h3>
          <div className="registerBox">
            <div className="flex">
              <div>
                <p>
                  크리에이터 ID <span className="red">*</span>
                </p>
                <input
                  type="text"
                  className={`basic ${
                    errorProperties === "creatorID" ? "error" : ""
                  }`}
                  name="creatorID"
                  value={creator.creatorID ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>담당자</p>
                <input type="text" disabled value={username ?? ""} />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  채널 <span className="red">*</span>
                </p>
                <Select
                  placeholder="채널"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "channel" ? "error" : ""}`}
                  onChange={(value) => handleSelectChange(value, "channel")}
                  options={transformArray(metadata.marketing)}
                />
              </div>
              <div>
                <p>
                  언어 <span className="red">*</span>
                </p>
                <Select
                  placeholder="언어"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "language" ? "error" : ""}`}
                  onChange={(value) => handleSelectChange(value, "language")}
                  options={transformArray(metadata.language)}
                />
              </div>
              <div>
                <p>
                  구분 <span className="red">*</span>
                </p>
                <Select
                  placeholder="구분"
                  style={{
                    width: 196,
                  }}
                  className={`${errorProperties === "type" ? "error" : ""}`}
                  onChange={(value) => handleSelectChange(value, "type")}
                  options={transformArray(metadata.creator_type)}
                />
              </div>
              <div>
                <p>카테고리</p>
                <Select
                  placeholder="카테고리"
                  style={{
                    width: 196,
                  }}
                  onChange={(value) => handleSelectChange(value, "category")}
                  options={transformArray(metadata.category)}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  URL <span className="red">*</span>
                </p>
                <input
                  type="text"
                  className={`basic ${
                    errorProperties === "url" ? "error" : ""
                  }`}
                  name="url"
                  value={creator.url ?? ""}
                  placeholder="http://example.com"
                  onChange={onChange}
                />
              </div>
              <div>
                <p>
                  팔로워 수 <span className="red">*</span>
                </p>
                <input
                  type="number"
                  name="followerNo"
                  className={`${
                    errorProperties === "followerNo" ? "error" : ""
                  }`}
                  value={creator.followerNo ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>크리에이터 이메일 주소</p>
                <input
                  type="text"
                  className="basic"
                  name="email"
                  value={creator.email ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>크리에이터 전화번호</p>
                <input
                  type="text"
                  name="phone"
                  value={creator.phone ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>제품 배송 주소</p>
                <input
                  type="text"
                  className="large"
                  name="address"
                  value={creator.address ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>Zip Code</p>
                <input
                  type="text"
                  name="zipcode"
                  value={creator.zipcode ?? ""}
                  onChange={onChange}
                />
              </div>
              <div>
                <p>배송 국가</p>
                <Select
                  placeholder="국가"
                  style={{
                    width: 196,
                  }}
                  onChange={(value) => handleSelectChange(value, "country")}
                  options={transformArray(metadata.country)}
                />
              </div>
            </div>
            <div className="flex">
              <div>
                <p>비고</p>
                <input
                  type="text"
                  className="note"
                  name="note"
                  value={creator.note ?? ""}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="buttonWrap">
            <button
              className="point"
              onClick={submit}
              disabled={isButtonDisabled}
            >
              저장
            </button>
          </div>
        </article>
      </section>
    </>
  );
}
