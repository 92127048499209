import { headers } from "../config/env";
import Axios from "../config/axios";
import { BulkDeleteProps, NoticeInfo, ParamsProps } from "../utils/type";
import { useCustomerStore } from "../store/customerStore";

export async function createNotice(data: NoticeInfo) {
  data.customer = useCustomerStore.getState().id!;
  return await Axios.post("/notice", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function fetchNoticeList(params: ParamsProps) {
  params.customer = useCustomerStore.getState().id!;
  return await Axios.get(`/notice/list`, {
    params,
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function deleteNotices({
  ids,
  select_all,
  filters,
}: BulkDeleteProps) {
  return await Axios.put(
    `/notice/delete`,
    { customer_id: useCustomerStore.getState().id!, ids, select_all, filters },
    {
      headers: headers(localStorage.getItem("accesstoken")!),
    }
  );
}
export async function fetchNoticeDetail(id: string) {
  return await Axios.get(`/notice/${id}`, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
export async function updateNotice(data: NoticeInfo) {
  return await Axios.put("/notice", data, {
    headers: headers(localStorage.getItem("accesstoken")!),
  });
}
