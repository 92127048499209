import React, { useState, useEffect } from "react";
import { Checkbox, Select } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import showModal from "../message/Modal";
import { useMetadataStore } from "../../store/metadataStore";
import { ContactListItem } from "../../utils/type";
interface Props {
  title: string | null;
  propsData: ContactListItem[] | null;
  changeData: (datas: ContactListItem[]) => void;
  isUpdate?: boolean;
  changeStatus: (status: boolean) => void;
}
export default function ContactDivision({
  title,
  propsData,
  changeData,
  isUpdate,
  changeStatus,
}: Props) {
  // data
  const { setDummyDatas } = useMetadataStore();
  const [checkedList, setCheckedList] = useState<boolean[]>([false, false]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [checkAll, setCheckAll] = useState(false);
  const [list, setList] = useState([
    {
      type: "",
      is_counting: true,
    },
  ]);
  // mounted
  useEffect(() => {
    if (propsData) {
      setList(propsData);
      setCheckedList(Array(propsData.length).fill(false));
      setDummyDatas([]);
    }
  }, [propsData]);
  useEffect(() => {
    changeData(list);
  }, [list]);
  // method
  const onCheckBoxChange = (e: CheckboxChangeEvent, idx: number) => {
    const updatedCheckedList = [...checkedList];
    updatedCheckedList[idx] = e.target.checked;
    setCheckedList(updatedCheckedList);
    const allChecked = updatedCheckedList.every((item) => item);
    setCheckAll(allChecked);
  };
  const onCheckBoxAllChange = (e: CheckboxChangeEvent) => {
    const updatedCheckedList = checkedList.map(() => e.target.checked);
    setCheckAll(e.target.checked);
    setCheckedList(updatedCheckedList);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    setList((prevUserData) => {
      const newList: ContactListItem[] = [...prevUserData];
      newList[index]["type"] = value;
      return newList;
    });
  };
  const onChange2 = (value: string, index: number) => {
    setList((prevUserData) => {
      const newList: ContactListItem[] = [...prevUserData];
      newList[index]["is_counting"] = value === "true" ? true : false;
      return newList;
    });
  };
  const handleDelete = () => {
    if (!checkedList.some((item) => item)) {
      return showModal({
        type: "error",
        messages: ["하나 이상 선택해주세요."],
      });
    }
    let indexs = getTrueIndices(checkedList);
    setList((prevUserData) => {
      const newArray = [...prevUserData].filter(
        (item, index) => !indexs.includes(index)
      );
      return newArray;
    });
    setCheckedList((prevUserData) => {
      const newArray = [...prevUserData].filter(
        (item, index) => !indexs.includes(index)
      );
      return newArray;
    });
  };
  const getTrueIndices = (list: boolean[]) => {
    const indices: number[] = [];
    list.forEach((value, index) => {
      if (value) {
        indices.push(index);
      }
    });
    return indices;
  };
  const addRow = () => {
    setList((prevUserData) => [
      ...prevUserData,
      {
        type: "",
        is_counting: true,
      },
    ]);
    setCheckedList((prevCheckData) => [...prevCheckData, false]);
  };
  const handleUpdate = () => {
    changeStatus(!isUpdate);
  };
  return (
    <>
      <div className="flexB edit">
        <div className="flex">
          {isUpdate ? (
            <>
              <button className="pointOut" onClick={handleDelete}>
                선택 삭제
              </button>
              <button className="add" onClick={addRow}>
                <img src="/images/icon/add.png" alt="이모티콘" />
                <p>행 추가</p>
              </button>
            </>
          ) : (
            <button className="point" onClick={handleUpdate}>
              수 정
            </button>
          )}
        </div>
      </div>
      <div className={isUpdate ? "tableBox small update" : "tableBox small"}>
        <table>
          <thead>
            <tr>
              {isUpdate && (
                <th>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckBoxAllChange}
                    checked={checkAll}
                  ></Checkbox>
                </th>
              )}
              <th>컨택 구분</th>
              <th>카운팅 여부(Y/N)</th>
            </tr>
          </thead>
          <tbody>
            {list.map((data, index) => (
              <tr key={index}>
                {isUpdate && (
                  <td className="check">
                    <Checkbox
                      checked={checkedList[index]}
                      onChange={(e) => onCheckBoxChange(e, index)}
                    />
                  </td>
                )}
                <td>
                  <input
                    type="text"
                    name="type"
                    className={data.type === "" ? "error" : ""}
                    value={data.type}
                    onChange={(e) => onChange(e, index)}
                    disabled={!isUpdate}
                  />
                </td>
                <td>
                  <Select
                    disabled={!isUpdate}
                    onChange={(e) => onChange2(e, index)}
                    value={`${data.is_counting}`}
                    options={[
                      {
                        value: "true",
                        label: "Yes",
                      },
                      {
                        value: "false",
                        label: "No",
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
