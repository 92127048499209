import React, { useState, useEffect } from "react";
import { Checkbox, Dropdown, Space, Button, Menu } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
interface Props {
  handleApply: (list: string[]) => void;
  list: string[];
  filterReset?: boolean;
}

export default function ChartFilterModal({
  handleApply,
  list,
  filterReset,
}: Props) {
  // data
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [menuList, setMenuList] = useState<string[]>([]);
  const [isFirst, setIsFirst] = useState<boolean>(false);
  // mounted
  useEffect(() => {
    setMenuList(list);
    if (filterReset === true) {
      handleSelectAll();
      setSelectedItems(list);
    }
  }, [list]);
  // method
  const handleMenuClick: MenuClickEventHandler = (e) => {
    // 아이템이 클릭되었을 때 드롭다운이 닫히지 않도록 함
    e.domEvent.stopPropagation();
  };
  const handleSelectAll = () => {
    setSelectedItems(selectedItems.length === menuList.length ? [] : menuList);
  };
  const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedItems(checkedValues as string[]);
  };
  const handleApplyClick = () => {
    setVisible(false);
    handleApply(selectedItems);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="selectAll">
        <Checkbox
          onChange={handleSelectAll}
          indeterminate={false}
          checked={selectedItems.length === menuList.length}
        >
          전체 선택
        </Checkbox>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="selectItems" className="dropdown">
        <Checkbox.Group
          onChange={handleCheckboxChange}
          value={selectedItems}
          className="multiChk"
        >
          {menuList.map((data, index) => (
            <Checkbox key={index} value={data}>
              {data}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Menu.Item>
      <Menu.Item>
        <Button type="primary" onClick={handleApplyClick}>
          적용하기
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      onVisibleChange={(flag) => setVisible(flag)}
      visible={visible}
      overlayClassName="filterOverlay"
    >
      <a
        className="ant-dropdown-link filterBtn"
        onClick={(e) => e.preventDefault()}
      >
        필터
        <span className="ant-select-arrow" unselectable="on" aria-hidden="true">
          <span
            role="img"
            aria-label="down"
            className="anticon anticon-down ant-select-suffix"
          >
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="down"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
            </svg>
          </span>
        </span>
      </a>
    </Dropdown>
  );
}
