import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { Customer, CustomerStore } from "../utils/type";

const INITIAL_STATE: Customer = {
  customerName: "",
  id: null,
  address: "",
  business_type: "",
  business_number: "",
  logo: null,
};

export const useCustomerStore = create<CustomerStore>()(
  devtools(
    persist(
      (set, get) => ({
        // data
        customerName: INITIAL_STATE.customerName,
        id: INITIAL_STATE.id,
        address: INITIAL_STATE.address,
        business_type: INITIAL_STATE.business_type,
        business_number: INITIAL_STATE.business_number,
        logo: INITIAL_STATE.logo,
        // method
        setCustomer(item: Customer) {
          set(() => item);
        },
        logoutCustomer() {
          set(() => INITIAL_STATE);
          localStorage.removeItem("customer");
        },
      }),
      { name: "customer", skipHydration: false }
    )
  )
);
